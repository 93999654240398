import { isMobile, isTablet } from "react-device-detect";
import Inquiry from "../../admin/pages/Inquiry";
import InvoiceAndDocs from "../../admin/pages/InvoiceAndDocs";
import MyOrders from "../../admin/pages/MyOrders";
import { Route } from "react-router-dom";
import appRoutes from "../../constants/appRoutes";
import Profile from "../../admin/pages/Profile";
import Orders from "../../admin/pages/Orders";
import Admin from "../../home/pages/Admin";
import ActiveInquiry from "../../admin/pages/ActiveInquiry";
import PartnerTab from "../../admin/pages/PartnerTab";
import AddPartner from "../../admin/pages/AddPartner";
import EditPartnerDetails from "../../admin/pages/EditPartnerDetails";
import InvoiceDetailsPdf from "../../admin/components/InvoiceDetailsPdf";
import ChangePassword from "../../admin/pages/ChangePassword";
import Customers from "../../admin/pages/Customers";
import Subscription from "../../admin/pages/Subscription";
export const publicRoutes = (
  <>
    <Route path="/" element={<Admin />} />
  </>
);

// will be later wrapped each routes with PrivateRoute
export const privateRoutes = (
  <>
    <Route path="/" element={<Admin />} />
    <Route path={appRoutes.customers.main} element={<Customers />} />
    <Route
      path={`${appRoutes.customers.main}/:clientId`}
      element={<Subscription />}
    />
    <Route path={appRoutes.partner.main} element={<PartnerTab />} />
    <Route
      path={appRoutes.orders.main}
      {...((isMobile || isTablet) && {
        element: <Orders />
      })}
    >
      <Route path={appRoutes.orders.myOrdersListView} element={<MyOrders />} />
      <Route
        path={appRoutes.orders.invoicesDocsListView}
        element={<InvoiceAndDocs />}
      />
    </Route>
    <Route path={appRoutes.inquiry.inquiryListView} element={<Inquiry />} />
    <Route
      path={appRoutes.inquiry.inquiryDetails}
      element={<ActiveInquiry />}
    />
    <Route path={appRoutes.partner.addPartner} element={<AddPartner />} />
    <Route
      path={appRoutes.partner.editPartnerDetails}
      element={<EditPartnerDetails />}
    />
    <Route path={appRoutes.pdf} element={<InvoiceDetailsPdf />} />

    {/* PROFILE COMPONENTS*/}
    <Route path={appRoutes.profile.main} element={<Profile />} />
    <Route
      path={appRoutes.profile.changePassword}
      element={<ChangePassword />}
    />
    <Route
      path={appRoutes.profile.helpAndSupport}
      element={<>Help & Support</>}
    />
    <Route path={appRoutes.profile.faqs} element={<>FAQ'S</>} />
    <Route path={appRoutes.profile.tnc} element={<>Terms & Conditions</>} />

    {/* <Route path="*" element={<Navigate to={appRoutes.home} replace />} /> */}
  </>
);
