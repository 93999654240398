import { Box } from "@mui/material";
import Footer from "../components/Footer";
import AdminSection from "../components/AdminSection";
import React from "react";

const Admin = () => {

  return (
    <Box marginTop={20} >
      <AdminSection/>
      <Footer />
    </Box>
  );
};

export default Admin;
