import { appConstants } from "../../constants";
import moment from "moment";

function currencyFormatter(x) {
  return "₹ " + x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "";
}

function currencyOnlyFormatter(x) {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "";
}

function generatePassword() {
  const length = 20;
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
  let password = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    password += charset.charAt(Math.floor(Math.random() * n));
  }
  return password;
}

function stringCapitalization(string) {
  return string?.charAt(0).toUpperCase() + string?.slice(1) || string;
}

function dateTimeFormatter(date, format = appConstants.dateFormat) {
  return (date && moment(date).format(format)) || "";
}

export {
  currencyFormatter,
  currencyOnlyFormatter,
  generatePassword,
  dateTimeFormatter,
  stringCapitalization
};
