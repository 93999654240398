import { queryKeys } from "../../constants/queryKeys";
import { getCustomers } from "../../admin/action";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useFetchCustomers = () => {
  const customers = useInfiniteQuery(
    [queryKeys.queryFetchCustomers],
    ({ pageParam = 1 }) => getCustomers(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage?.clients?.length === lastPage?.pagination.limit
            ? allPages.length + 1
            : undefined;
        return nextPage;
      },
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
    }
  );
  return customers;
};
