import { updateCustomerStatus } from "../../admin/action";
import { useMutation } from "@tanstack/react-query";

export const useUpdateCustomerStatus = () => {
  const customerStatusUpdate = useMutation(({ orderId, formData }) =>
    updateCustomerStatus(orderId, formData)
  );

  return customerStatusUpdate;
};
