import { Box, Button, Typography, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

const PartnerInfoDialog = ({
  hideDialog,
  ...partner
}) => {

  const partner_info = [
    {
      label: "Name : ",
      info: `${partner.name}`,
    },
    {
      label: "Address : ",
      info: `${partner.address.area}`,
    },
    {
      label: "Active Since : ",
      info: `${moment(partner.listedAt).format('DD-MM-YYYY')}`,
    },
    {
      label: "Commission Onboarded : ",
      info: `${partner.commission.percent} %`,
    },
    {
      label: "BDA Onboarded : ",
      info: `${partner.bdm.name}   +91 ${partner.bdm.mobile}`,
    },
    {
      label: "Partner POC : ",
      info: `${partner.poc.name}   +91 ${partner.poc.mobile}`,
    },
    {
      label: "Owner/ Authority : ",
      info: `${partner.kdm.name}   +91 ${partner.kdm.mobile}`,
    },
  ];

  return (
    <Box
      padding={1}
      display={"flex"}
      flexDirection={"column"}
      gap={"12px"}
      textAlign={"center"}
      alignItems={"center"}
    >
      <Grid container justifyContent={"center"}>
        <Grid item>
          <Button
            variant="outlined"
            sx={{ width: "270px", height: "40px" }}
            onClick={hideDialog}
          >
            {partner.code}
          </Button>
        </Grid>
        <Grid item>
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.text.secondary,
            }}
            onClick={hideDialog}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>

      {partner_info.map((item, index) => (
        <Grid container key={index} flexWrap={"nowrap"}>
          <Grid item sx={{ width: "174px" }} textAlign={"left"}>
            <Typography
              fontSize={12}
              lineHeight={"24px"}
              color={"text.secondary"}
            >
              {item.label}
            </Typography>
          </Grid>
          <Grid item sx={{ width: "254px" }} textAlign={"left"}>
            <Typography
              fontSize={12}
              lineHeight={"24px"}
              color={"text.secondary"}
            >
              {item.info}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};

export default PartnerInfoDialog;
