import { updateTransportVehicle } from "../../admin/action";
import { useMutation } from "@tanstack/react-query";

export const useUpdateVehicleDataMutateQuery = () => {
  const mutateTransportVehicle = useMutation(
    ({ partnerId, vehicleId, formData }) =>
      updateTransportVehicle(partnerId, vehicleId, formData)
  );

  return mutateTransportVehicle;
};
