import { addTransaction } from "../../admin/action";
import { useMutation } from "@tanstack/react-query";

export const useAddTransactionMutateQuery = () => {
  const addTransactionMutate = useMutation(({ settlementId, formData }) =>
    addTransaction(settlementId, formData)
  );

  return addTransactionMutate;
};
