import {
  Box,
  Grid,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { quotation_labels } from "./constant";
import { Fragment, useCallback, useEffect } from "react";
import InquiryDetails from "../components/InquiryDetails";
import TextInput from "../../shared/formElements/TextInput";
import { useFieldArray, useForm } from "react-hook-form";
import { warehouse_labels } from "../../constants";
import Reqoute from "../components/Reqoute";
import { useFetchInquiryByRfqIdQueryHook } from "../../query-hooks/Inquiries/useFetchInquiryByRfqIdQueryHook";
import { useParams, useLocation } from "react-router-dom";
import moment from "moment";

// const revisedQuotationRowsTransport = [
//   {
//     name: "Loading",
//     label: "Loading",
//     rateUnit: "100 kg",
//   },
//   {
//     name: "Unloading",
//     label: "Unloading",
//     rateUnit: "100 kg",
//   },
// ];

// const revisedQuotationRowsWarehouse = [
//   {
//     name: "Forks",
//     label: "Equipments",
//     rateUnit: "100 kg",
//   },
//   {
//     name: "Unloading",
//     label: "Labor Cost",
//     rateUnit: "100 kg",
//   },
// ];

const ActiveInquiry = () => {
  const { inquiryId } = useParams();
  const location = useLocation();
  const { control } = useForm();

  const { fields, append } = useFieldArray({
    control,
    name: "quotes",
  });

  const { data, isFetched, refetch } =
    useFetchInquiryByRfqIdQueryHook(inquiryId);

  const checkPartner = location.pathname.includes("warehouse");

  const partnerRfq = checkPartner
    ? { ...data?.inquiry?.warehouseRfq, ...data?.inquiry?.searchForm }
    : { ...data?.inquiry?.transportRfq, ...data?.inquiry?.searchForm };

  const partnerQuote = checkPartner
    ? data?.inquiry?.warehouseRfq.partners?.find((item) => item.partner)
    : data?.inquiry?.transportRfq.partners?.find((item) => item.partner);

  // const rfqRows = checkPartner
  //   ? revisedQuotationRowsWarehouse
  //   : revisedQuotationRowsTransport;

  const quote = partnerQuote?.quote;

  const appendQuoteForm = useCallback(
    (quote) => {
      append({
        label: checkPartner ? "Storage Cost" : "Transport Services",
        uom: quote.services[0].rateUnit,
        duration: checkPartner
          ? `${parseFloat(quote.services[0].duration).toFixed(2)} Months`
          : "-",
        quantity: `${parseFloat(quote.services[0].quantity).toFixed(
          2
        )} ${quote.services[0].rateUnit.toLowerCase()}`,
        quantityValue: quote?.services[0]?.quantity,
        rate: quote?.services[0]?.rate,
        total: quote?.services[0]?.totalAmount,
      });

      // rfqRows.forEach((quotation) => {
        quote.additionalServices.forEach((item) => {
          // if (quotation.name === item.name) {
            append({
              label: item.displayName,
              uom: `Price / ${item.rateUnit}`,
              name: item.name,
              duration: `-`,
              quantity: `${item.quantity} Kg`,
              quantityValue: item.quantity,
              rate: item.rate,
              total: parseFloat(item?.totalAmount).toFixed(2),
            });
          // } 
        //   else {
        //     append({
        //       label: quotation.label,
        //       uom: `Price / ${quotation.rateUnit}`,
        //       name: quotation.label,
        //       quantity: `${0} Kg`,
        //       duration: `-`,
        //       // quantityValue: "",
        //       rate: `${0}`,
        //       total: `${0}`,
        //     });
        //   }
        // });
      });
      if (quote.otherServices.length > 0) {
        append({
          label: quote.otherServices[0]?.displayName,
          uom: quote.otherServices[0]?.rateUnit,
          name: quote.otherServices[0]?.name,
          duration: `-`,
          quantity: quote.otherServices[0]?.quantity,
          quantityValue: quote.otherServices[0]?.quantity,
          rate: quote.otherServices[0]?.rate,
          total: quote.otherServices[0]?.totalAmount,
        });
      } else {
        append({
          label: "Other / Misc.",
          uom: "Lumpsum",
          name: `quote.${"Other"}.name`,
          duration: `-`,
          quantity: `-`,
          quantityValue: 0,
          rate: 0,
          total: 0,
        });
      }
    },
    [append, checkPartner]
  );

  useEffect(() => {
    if (isFetched) {
      if (quote) {
        appendQuoteForm(quote);
      }
    }
  }, [appendQuoteForm, isFetched, quote]);

  return !isFetched ? (
    <Backdrop
      sx={{ color: "#FFFF", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={!isFetched}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <Box
      sx={{
        padding: {
          xs: "24px 16px",
          sm: 0,
        },
      }}
      marginBottom={2}
    >
      <Box
        display={"flex"}
        alignItems={"flex-end"}
        justifyContent={"space-between"}
        sx={{
          marginBottom: {
            xs: 3,
            md: "15px",
          },
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: {
                xs: 14,
                md: 20,
              },
              fontWeight: 500,
              lineHeight: "24px",
              letterSpacing: "0.002em",
            }}
            gutterBottom
          >
            {`${warehouse_labels.inquiryIdTitleText} : ${data?.inquiry?.code}`}
          </Typography>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 400,
              lineHeight: "20px",
            }}
            color={"text.secondary"}
          >
            {`${warehouse_labels.inquiryDateTitleText} : ${moment(
              data?.inquiry?.createdAt
            ).format("DD MMMM YYYY")}`}
          </Typography>
        </Box>
      </Box>
      <Box>
        <InquiryDetails {...partnerRfq} checkPartner={checkPartner} />
      </Box>
      <Box container spacing={2} display={"flex"}>
        <Box sx={{ width: "586px" }}>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 600,
              lineHeight: "20px",
              letterSpacing: "0.005em",
              marginTop: "20px",
            }}
          >
            {warehouse_labels.reviseQuotationComparison}
          </Typography>
          <Grid container spacing={2} marginTop={15}>
            <Grid item xs={12} container spacing={2}>
              <Grid
                item
                xs={2}
                minWidth={140}
                sx={{
                  background: {
                    xs: "#F0F2F5",
                    md: "transparent",
                  },
                }}
              />
              {quotation_labels.map((item, index) => (
                <Fragment key={index}>
                  <Grid
                    item
                    xs={2}
                    display={"flex"}
                    alignItems={"flex-end"}
                    minWidth={140}
                  >
                    <Typography fontSize={14} fontWeight={500}>
                      {item}
                    </Typography>
                  </Grid>
                </Fragment>
              ))}
            </Grid>
            {fields.map((item, index) => (
              <Grid
                item
                xs={12}
                container
                spacing={2}
                key={index}
                flexWrap={"nowrap"}
              >
                <Grid
                  item
                  xs={2}
                  display={"flex"}
                  alignItems={"center"}
                  minWidth={140}
                  sx={{
                    background: {
                      xs: "#F0F2F5",
                      md: "transparent",
                    },
                  }}
                >
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    sx={{
                      paddingLeft: {
                        xs: "15px",
                        md: 0,
                      },
                    }}
                  >
                    {item.label}
                  </Typography>
                </Grid>
                <Grid item xs={2} minWidth={140}>
                  <TextInput
                    control={control}
                    name={`quotes.${index}.uom`}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={2} minWidth={140}>
                  <TextInput
                    control={control}
                    name={`quotes.${index}.duration`}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={2} minWidth={140}>
                  <TextInput
                    control={control}
                    name={`quotes.${index}.quantity`}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            ))}
            <Grid item>
              <Box
                display={"flex"}
                gap={5}
                sx={{
                  width: {
                    xs: "100%",
                    md: "auto",
                  },
                  flexDirection: "column",
                  marginTop: "10px",
                }}
              >
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  whiteSpace={"nowrap"}
                >
                  {"subTotal"}
                </Typography>
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  whiteSpace={"nowrap"}
                >
                  {"GST"}
                </Typography>
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  whiteSpace={"nowrap"}
                >
                  {"total"}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {partnerRfq.partners.map((item, index) => (
          <Box item sx={{ width: "320px" }} key={index} marginX={1}>
            <Reqoute {...item} isFetched={isFetched} refetch={refetch} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ActiveInquiry;
