import { getCreateSubUserFormData } from "../../admin/action";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../constants/queryKeys";

export const useFetchCreateSubUserFormData = (
  clientId,
  allowCreateFormData
) => {
  const createFormDataQuery = useQuery(
    [queryKeys.queryGetCreateSubUserFormData, clientId, allowCreateFormData],
    () => getCreateSubUserFormData(clientId),
    {
      refetchOnWindowFocus: false,
      enabled: allowCreateFormData && Boolean(clientId)
    }
  );
  return createFormDataQuery;
};
