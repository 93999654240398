/* eslint-disable no-empty-pattern */

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  styled
} from "@mui/material";
import { otherControlsHover, otherControlsResting } from "../theme/globalTheme";
import { useEffect, useState } from "react";

import { Controller } from "react-hook-form";

export const CustomIcon = styled("span")(({ theme }) => ({
  borderRadius: 4,
  width: 24,
  height: 24,
  backgroundColor: otherControlsResting,
  "input:hover ~ &": {
    backgroundColor: otherControlsHover
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "#EEF1F4"
  }
}));

export const CustomCheckedIcon = styled(CustomIcon)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  "&:before": {
    display: "block",
    width: 24,
    height: 24,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""'
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.primary.dark
  }
}));

const CheckBoxGroup = ({
  label,
  name,
  control,
  disabled = false,
  errors,
  options,
  setValue,
  onRemove,
  defaultValues = ""
}) => {
  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelect = (value) => {
    const isPresent = selectedItems.indexOf(value);
    if (isPresent !== -1) {
      const remaining = selectedItems.filter((item) => item !== value);
      setSelectedItems(remaining);
      if (onRemove) {
        onRemove(value, isPresent);
      }
    } else {
      setSelectedItems((prevItems) => [...prevItems, value]);
    }
  };

  useEffect(() => {
    setValue(name, selectedItems);
  }, [name, selectedItems, setValue]);

  useEffect(() => {
    if (defaultValues) {
      setSelectedItems(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl variant="standard" disabled={disabled}>
      {label && <FormLabel sx={{ mb: "13px" }}>{label}</FormLabel>}
      <FormGroup sx={{ flexDirection: "row", flexWrap: "nowrap" }}>
        {options?.map((item, index) => (
          <FormControlLabel
            key={item.displayName}
            control={
              <Controller
                name={name}
                control={control}
                defaultValue={defaultValues}
                render={({ field, formState }) => {
                  return (
                    <Checkbox
                      disableRipple
                      checked={selectedItems.includes(item.name)}
                      onChange={() => handleSelect(item.name)}
                      checkedIcon={<CustomCheckedIcon />}
                      icon={<CustomIcon />}
                      sx={{
                        "&:hover": { backgroundColor: "transparent" }
                      }}
                    />
                  );
                }}
              />
            }
            label={item.displayName}
          />
        ))}
      </FormGroup>
      {errors?.[name] && (
        <FormHelperText error>{errors[name].message}</FormHelperText>
      )}
    </FormControl>
  );
};

export default CheckBoxGroup;
