import { updateCommission } from "../../admin/action";
import { useMutation } from "@tanstack/react-query";

export const useUpdateCommissionMutateQuery = () => {
  const updateCommissionMutate = useMutation(({ settlementId, formData }) =>
    updateCommission(settlementId, formData)
  );

  return updateCommissionMutate;
};
