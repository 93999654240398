import { resetQuote } from "../../admin/action";
import { useMutation } from "@tanstack/react-query";

export const useResetQuoteMutateQuery = () => {
  const mutateResetQuote = useMutation(
    ({ inquiryId, partnerId }) =>
      resetQuote(inquiryId, partnerId)
  );

  return mutateResetQuote;
};
