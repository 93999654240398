export const StorageKeys = {
  // ACCESS_TOKEN: "accessToken",
  // ID_TOKEN: "idToken",
  LOCAL_CRUMBS: "local-crumbs",
  ACTIVE_TAB: "activeTab",
  ADD_PARTNER_FORM_DATA: "add-partner-form-data",
  serviceLocalStorage: "serviceLocalStorage",
  AdminUser: "AdminUser"
};

export const UserRoles = {
  admin: "Admin",
};

export const StorageServiceObject = {
  partner: false,
};
