import { getPartnerById } from "../../admin/action";
import { queryKeys } from "../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchPartnerByIdQuery = (partnerId) => {
  const partnerByIdQuery = useQuery(
    [queryKeys.queryFetchPartnerById, partnerId],
    () => getPartnerById(partnerId),
    {
      enabled: Boolean(partnerId),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return partnerByIdQuery;
};
