import { updateTransportPartner } from "../../admin/action";
import { useMutation } from "@tanstack/react-query";

export const useMutateTransportPartnerQuery = () => {
  const mutateTransportPartner = useMutation(({ partnerId, formData }) =>
    updateTransportPartner(partnerId, formData)
  );

  return mutateTransportPartner;
};
