import { getInquiries } from "../../admin/action";
import { queryKeys } from "../../constants/queryKeys";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useFetchInquiriesQueryHook = () => {
  const partnersInquiries = useInfiniteQuery(
    [queryKeys.queryFetchInquiries],
    ({ pageParam = 1 }) => getInquiries(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage?.inquiries.length === lastPage?.pagination.limit
            ? allPages.length + 1
            : undefined;
        return nextPage;
      }
    }
  );
  return partnersInquiries;
};
