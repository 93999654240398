import * as yup from "yup";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import { CalendarTodayOutlined, ExpandMore } from "@mui/icons-material";
import { useEffect, useMemo, useState } from "react";

import { AccordionWarehouseDetails } from "../components/AccordionWarehouseDetails";
import DateInput from "../../shared/formElements/DateInput";
import DropDown from "../../shared/formElements/DropDown";
import { EditPartnerFormKeys } from "../../constants/formKeys";
import { LoadingButton } from "@mui/lab";
import { Partner_labels } from "../../constants";
import { PercentOutlined } from "@mui/icons-material";
import TextInput from "../../shared/formElements/TextInput";
import { error_msg } from "../../constants";
import moment from "moment";
import { parserFunction } from "./AddPartner";
import { useFetchPartnerByIdQuery } from "../../query-hooks/Partners/useFetchPartnerByIdQuery";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useFetchAddPartnerFormDataQuery } from "../../query-hooks/Partners/useFetchAddPartnerFormDataQuery";
import { useUpdatePartnerQuery } from "../../query-hooks/Partners/useUpdatePartnerQuery";
import { yupResolver } from "@hookform/resolvers/yup";
import { AccordionTransportDetails } from "../components/AccordionTransportDetails";
import { enqueueSnackbar } from "notistack";

export const StyledAccordion = styled(Accordion)(() => ({
  "&:before": {
    display: "none",
  },
}));

const editFormSchema = yup.object({
  [EditPartnerFormKeys.partnerName]: yup.string().required(error_msg.required),
  [EditPartnerFormKeys.signupDate]: yup.string().required(error_msg.required),
  [EditPartnerFormKeys.partnerCity]: yup.string().required(error_msg.required),
  [EditPartnerFormKeys.partnerCluster]: yup
    .string()
    .required(error_msg.required),

  [EditPartnerFormKeys.partnerCommission]: yup
    .string()
    .required(error_msg.required)
    .matches(/^[0-9]\d*(\.\d+)?$/, error_msg.onlyDigits),
});

const EditPartnerDetails = () => {
  const [enableEdit, setEnableEdit] = useState(true);
  const { partnerId } = useParams();

  const { data: loadData } = useFetchAddPartnerFormDataQuery();
  const { data, isFetching, isFetched, refetch } =
    useFetchPartnerByIdQuery(partnerId);
  const { mutate, isLoading, isError, error } = useUpdatePartnerQuery();

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(editFormSchema),
  });

  const detailForm = useMemo(
    () => <AccordionWarehouseDetails {...data} refetch={refetch} />,
    [data, refetch]
  );

  const transportDetailForm = useMemo(
    () => <AccordionTransportDetails {...data} refetch={refetch} />,
    [data, refetch]
  );

  useEffect(() => {
    if (!isFetching) {
      reset({
        [EditPartnerFormKeys.partnerCode]: data?.partner?.code,
        [EditPartnerFormKeys.partnerName]: data?.partner?.name,
        [EditPartnerFormKeys.signupDate]: new Date(
          data?.partner?.signUpDate ?? "2023-10-04T00:00:00.000Z"
        ),
        [EditPartnerFormKeys.partnerCity]: data?.partner?.address?.city,
        [EditPartnerFormKeys.partnerCluster]: data?.partner?.address?.cluster,
        [EditPartnerFormKeys.partnerType]:
          data?.partner?.partnerType?.displayName,
        [EditPartnerFormKeys.partnerCommission]:
          data?.partner?.commission?.percent,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const handleEditClick = () => {
    setEnableEdit((prevState) => !prevState);
  };

  const editAllowed = data?.partner?.status?.name === "Inactive";

  const onSaveChangesClick = (submitData) => {
    mutate(
      {
        partnerId: data?.partner?._id,
        formData: {
          name: submitData.partnerName,
          signUpDate: moment(submitData.signupDate).format("YYYY-MM-DD"),
          commission: {
            percent: parseInt(submitData.partnerCommission, 10),
          },
          address: {
            city: submitData.partnerCity,
            cluster: submitData.partnerCluster,
          },
        },
      },
      {
        onSuccess: () => {
          enqueueSnackbar("The changes have been Updated for the Partner", {
            variant: "success",
          });
          refetch();
          setEnableEdit(true);
        },
      }
    );
  };

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(error?.response.data.message, { variant: "error" });
      reset();
    }
  }, [error?.response.data.message, isError, reset]);

  return isFetching ? (
    <Backdrop
      sx={{ color: "#FFFF", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isFetching}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <Container maxWidth="md">
      <Box display={"flex"} flexDirection={"column"} gap={8}>
        <StyledAccordion disableGutters elevation={0} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMore color="primary" fontSize="large" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Divider sx={{ width: "100%", borderWidth: 1 }} />
          </AccordionSummary>
          <AccordionDetails>
            <Box component={"form"} onSubmit={handleSubmit(onSaveChangesClick)}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography
                      sx={{
                        fontSize: 20,
                        fontWeight: 600,
                        lineHeight: "20px",
                        letterSpacing: "0.005em",
                      }}
                    >
                      {Partner_labels.partnerDetails}
                    </Typography>
                    <Box maxWidth={140}>
                      <TextInput
                        control={control}
                        name={EditPartnerFormKeys.partnerCode}
                        label={Partner_labels.partnerCode}
                        disabled={true}
                      />
                    </Box>
                    <Box display={"flex"} gap={4}>
                      <Button
                        variant="soft"
                        sx={{ width: "140px", height: "48px" }}
                        onClick={handleEditClick}
                        disabled={editAllowed}
                      >
                        {enableEdit
                          ? Partner_labels.editButton
                          : Partner_labels.cancelButton}
                      </Button>
                      <Button
                        variant="contained"
                        component={LoadingButton}
                        sx={{ width: "140px", height: "48px" }}
                        loading={isLoading}
                        onClick={handleSubmit(onSaveChangesClick)}
                        disabled={editAllowed}
                      >
                        {Partner_labels.saveChanges}
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={10} container spacing={"14px"}>
                  <Grid item xs={12} container spacing={"100px"}>
                    <Grid item xs={8}>
                      <TextInput
                        control={control}
                        name={EditPartnerFormKeys.partnerName}
                        label={Partner_labels.PartnerName}
                        disabled={enableEdit}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <DateInput
                        control={control}
                        name={EditPartnerFormKeys.signupDate}
                        endIcon={CalendarTodayOutlined}
                        placeholder={"Enter Date"}
                        label={Partner_labels.SignupDate}
                        disabled={enableEdit}
                        minDate={"1900-01-01"}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container spacing={"100px"}>
                    <Grid item xs={6}>
                      <DropDown
                        control={control}
                        name={EditPartnerFormKeys.partnerCity}
                        label={Partner_labels.PartnerCity}
                        isLoading={isFetching}
                        options={parserFunction(loadData?.cities)}
                        disabled={enableEdit}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextInput
                        control={control}
                        name={EditPartnerFormKeys.partnerCluster}
                        label={Partner_labels.PartnerCluster}
                        disabled={enableEdit}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container spacing={"100px"}>
                    <Grid item xs={6}>
                      <DropDown
                        control={control}
                        name={EditPartnerFormKeys.partnerType}
                        label={Partner_labels.PartnerType}
                        options={loadData?.partnerTypes}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextInput
                        control={control}
                        name={EditPartnerFormKeys.partnerCommission}
                        label={Partner_labels.PartnerCommission}
                        endIcon={PercentOutlined}
                        disabled={enableEdit}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </StyledAccordion>

        <StyledAccordion disableGutters elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMore color="primary" fontSize="large" />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Divider sx={{ width: "100%", borderWidth: 1 }} />
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              {data?.partner?.partnerType?.displayName === "Warehouse" &&
                isFetched &&
                detailForm}
              {data?.partner?.partnerType?.displayName === "Transport" &&
                isFetched &&
                transportDetailForm}
            </Box>
          </AccordionDetails>
        </StyledAccordion>
      </Box>
    </Container>
  );
};

export default EditPartnerDetails;
