import { ListItemButton, Typography } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";

const MobileNavLinkItem = ({ path, icon: Icon, name }) => {
  const location = useLocation();

  const handleClearLocalStorage = (path) => {
    /**
     * remove currentSelectedSubMenuLink from localStorage on click of nav items as an edge case for desktop view
     */
    localStorage.removeItem("currentSelectedSubMenuLink");
  };

  let activeSelectedLink;

  if (path === "/") {
    activeSelectedLink = path;
  } else {
    activeSelectedLink = `/${path}`;
  }

  return (
    <ListItemButton
      component={RouterLink}
      to={path}
      selected={location.pathname === activeSelectedLink}
      onClick={handleClearLocalStorage}
    >
      <Icon />
      <Typography>{name}</Typography>
    </ListItemButton>
  );
};

export default MobileNavLinkItem;
