import { Button, Grid, Typography } from "@mui/material";
import { Fragment, useCallback, useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import PartnerInfoDialog from "../../shared/UiElements/PartnerInfoDialog";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import SuccessfulDialog from "../../shared/UiElements/SuccessfulDialog";
import TextInput from "../../shared/formElements/TextInput";
import { inquiryAmountKeys } from "../../constants/formKeys";
import { partner_quotation_labels } from "../pages/constant";
import { useCustomDialog } from "../../shared/customDialog";
import { useParams } from "react-router-dom";
import { useResetQuoteMutateQuery } from "../../query-hooks/Inquiries/useResetQuoteMutateQuery";
import { warehouse_labels } from "../../constants";
import { enqueueSnackbar } from "notistack";

function Reqoute({ refetch, isFetched, ...item }) {
  const { inquiryId } = useParams();

  const { showDialog, hideDialog } = useCustomDialog();

  const { control, setValue } = useForm({});

  const { fields, append } = useFieldArray({
    control,
    name: "amount"
  });

  const quote = item?.quote;

  const appendQuoteForm = useCallback(
    (quote) => {
      append({
        rate: `₹ ${quote.services[0].rate}`,
        total: `₹ ${parseFloat(quote.services[0]?.totalAmount).toFixed(2)}`
      });
      quote.additionalServices.forEach((item) => {
        append({
          rate: `₹ ${item.rate}`,
          total: `₹ ${parseFloat(item?.totalAmount).toFixed(2)}`
        });
      });
      if (quote.otherServices.length > 0) {
        append({
          rate: `₹ ${quote.otherServices[0].rate}`,
          total: `₹ ${parseFloat(quote?.otherServices[0]?.totalAmount).toFixed(
            2
          )}`
        });
      } else {
        append({
          rate: `₹ ${0}`,
          total: `₹ ${0}`
        });
      }
    },
    [append]
  );

  const { mutate } = useResetQuoteMutateQuery();

  useEffect(() => {
    if (isFetched) {
      if (quote) {
        appendQuoteForm(quote);
      }
    }
  }, [appendQuoteForm, isFetched, quote]);

  useEffect(() => {
    if (isFetched) {
      setValue(
        inquiryAmountKeys.subtotal,
        `₹ ${parseFloat(quote?.subTotalAmount).toFixed(2)}`
      );
      setValue(
        inquiryAmountKeys.gst,
        `₹ ${parseFloat(quote?.taxes[0]?.totalAmount ?? 0).toFixed(2)}`
      );
      setValue(
        inquiryAmountKeys.total,
        `₹ ${parseFloat(quote?.totalAmount).toFixed(2)}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched]);

  const onResetClick = () => {
    mutate(
      { inquiryId: inquiryId, partnerId: item.partner._id },
      {
        onSuccess: () => {
          refetch();
          hideDialog();
          enqueueSnackbar("Reset Successfull", {
            variant: "success",
          });
        }
      }
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Grid
          item
          border={"1px solid #607088"}
          sx={{ width: "320px", height: "640px" }}
        >
          <Grid item marginTop={"40px"} paddingLeft={"20px"}>
            <Button
              variant="outlined"
              sx={{
                width: "270px",
                height: "40px",
                fontSize: 20,
                fontWeight: 700
              }}
              onClick={() => {
                showDialog({
                  component: (
                    <PartnerInfoDialog
                      hideDialog={hideDialog}
                      {...item.partner}
                    />
                  )
                });
              }}
            >
              {item.partner.code}
            </Button>
          </Grid>
          <Grid container marginTop={"20px"} justifyContent={"space-evenly"}>
            <Grid item>
              <StyledChip label={item.status.name} />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                sx={{
                  width: "120px",
                  height: "32px",
                  fontSize: 18,
                  fontWeight: 500
                }}
                disabled={
                  item.status.name === "Pending" ||
                  item.status.name === "Cancelled"
                }
                onClick={() => {
                  showDialog({
                    component: (
                      <SuccessfulDialog
                        subText={
                          "Do you want the partner to requote and submit again to customer ?"
                        }
                        btn1Text={warehouse_labels.yesConfirm}
                        btn2Text={warehouse_labels.goBack}
                        btn1Callback={onResetClick}
                        btn2Callback={hideDialog}
                      />
                    )
                  });
                }}
              >
                {warehouse_labels.requote}
              </Button>
            </Grid>
          </Grid>
          <Grid item marginTop={5.5} paddingLeft={"20px"}>
            <Grid item xs={12} container spacing={2} flexWrap={"nowrap"}>
              {partner_quotation_labels.map((item, index) => (
                <Fragment key={index}>
                  <Grid
                    item
                    xs={2}
                    display={"flex"}
                    alignItems={"flex-end"}
                    minWidth={140}
                    marginBottom={1.5}
                  >
                    <Typography fontSize={14} fontWeight={500}>
                      {item}
                    </Typography>
                  </Grid>
                </Fragment>
              ))}
            </Grid>
            {fields.map((item, index) => (
              <Grid
                item
                xs={12}
                container
                spacing={2}
                key={index}
                flexWrap={"nowrap"}
                marginBottom={2}
              >
                <Grid item xs={2} minWidth={140}>
                  <TextInput
                    control={control}
                    name={`amount.${index}.rate`}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={2} minWidth={140}>
                  <TextInput
                    control={control}
                    name={`amount.${index}.total`}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid
            item
            xs={12}
            container
            spacing={2}
            flexWrap={"nowrap"}
            marginBottom={2}
          >
            <Grid
              item
              xs={2}
              minWidth={120}
              sx={{
                background: {
                  xs: "#F0F2F5",
                  md: "transparent"
                }
              }}
            />
            <Grid item xs={2} minWidth={140} marginLeft={5}>
              <TextInput
                control={control}
                name={inquiryAmountKeys.subtotal}
                disabled={true}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            spacing={2}
            flexWrap={"nowrap"}
            marginBottom={2}
          >
            <Grid
              item
              xs={2}
              minWidth={120}
              sx={{
                background: {
                  xs: "#F0F2F5",
                  md: "transparent"
                }
              }}
            />
            <Grid item xs={2} minWidth={140} marginLeft={5}>
              <TextInput
                control={control}
                name={inquiryAmountKeys.gst}
                disabled={true}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            spacing={2}
            flexWrap={"nowrap"}
            marginBottom={2}
          >
            <Grid
              item
              xs={2}
              minWidth={120}
              sx={{
                background: {
                  xs: "#F0F2F5",
                  md: "transparent"
                }
              }}
            />
            <Grid item xs={2} minWidth={140} marginLeft={5}>
              <TextInput
                control={control}
                name={inquiryAmountKeys.total}
                disabled={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Reqoute;
