import { Box, Breadcrumbs, IconButton, Link, Typography } from "@mui/material";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

import { ArrowBack } from "@mui/icons-material";
import { AuthContext } from "../context/auth-context";
import appRoutes from "../../constants/appRoutes";
import { isMobileDevice } from "../../App";
import { useContext } from "react";

function LinkRouter(props) {
  return <Link {...props} component={RouterLink} />;
}

function Crumb({ title, to, last = false }) {
  if (last) {
    return (
      <Typography
        color="text.secondary"
        fontWeight={600}
        fontSize={16}
        letterSpacing="0.005em"
        textTransform={"capitalize"}
      >
        {title}
      </Typography>
    );
  }

  return (
    <LinkRouter
      underline="none"
      color="primary.main"
      to={to}
      key={to}
      fontWeight={600}
      fontSize={16}
      letterSpacing="0.005em"
      textTransform={"capitalize"}
    >
      {title}
    </LinkRouter>
  );
}

const BackNavigation = () => {
  const location = useLocation();
  const crumbContext = useContext(AuthContext);

  const navigate = useNavigate();

  const isExcludedPathsMobile = ![
    appRoutes.home,
    appRoutes.inquiry.inquiryListView,
    appRoutes.orders.main
  ].includes(location.pathname);

  const isExcludedPathsDesktop = ![
    appRoutes.home,
    appRoutes.customers.main,
    appRoutes.partner.main,
    appRoutes.inquiry.inquiryListView,
    appRoutes.orders.invoicesDocsListView,
    appRoutes.orders.retrieveListView,
    "/orders/my-orders",
    "/orders/invoices-docs",
    appRoutes.profile.main,
    appRoutes.profile.changePassword,
    appRoutes.profile.helpAndSupport,
    appRoutes.profile.faqs,
    appRoutes.profile.tnc
  ].includes(location.pathname);

  const isExcludedPaths = isMobileDevice
    ? isExcludedPathsMobile
    : isExcludedPathsDesktop;

  const generatedCrumbs = () => {
    const pathnames = location.pathname.split("/").filter((x) => x);
    const crumbList = pathnames.map((value, index) => {
      const to = `/${pathnames.slice(0, index + 1).join("/")}`;
      const title = crumbContext.crumbsStack.get(to);
      return { to, title };
    });

    return [...crumbList];
  };

  const breadcrumbs = generatedCrumbs();

  return (
    isExcludedPaths && (
      <Box
        display={"flex"}
        alignItems={"center"}
        sx={{
          background: "#FFFFFF",
          padding: {
            xs: "12px 16px",
            sm: "0"
          },
          boxShadow: {
            xs: "0px 8px 24px rgba(21, 21, 22, 0.1)",
            sm: "none"
          },
          marginBottom: {
            xs: 0,
            sm: 2
          }
        }}
      >
        {isExcludedPaths && (
          <IconButton
            size="small"
            onClick={() => navigate(-1)}
            sx={{ marginRight: 1, marginLeft: -1 }}
          >
            <ArrowBack fontSize="small" />
          </IconButton>
        )}
        <Breadcrumbs aria-label="breadcrumb" sx={{ flexGrow: 1 }}>
          {breadcrumbs.map(
            (crumb, idx) =>
              crumbContext.crumbsStack.get(crumb.to) && (
                <Crumb
                  {...crumb}
                  key={idx}
                  last={idx === breadcrumbs.length - 1}
                />
              )
          )}
        </Breadcrumbs>
      </Box>
    )
  );
};

export default BackNavigation;
