import { queryKeys } from "../../constants/queryKeys";
import { getSubscriptions } from "../../admin/action";
import { useQuery } from "@tanstack/react-query";

export const useFetchAllSubscriptions = (clientId) => {
  const Subscriptions = useQuery(
    [queryKeys.queryFetchSubscriptions, clientId],
    () => getSubscriptions(clientId),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return Subscriptions;
};
