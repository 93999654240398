export const appServiceKeys = {
  warehouse: "warehouse",
  transport: "transport",
};

export const SearchFormKeys = {
  IsTransportServiceNeeded: "isTransportServiceNeeded",
  PickupLocation: "pickupLocation",
  Destination: "destination",
  InboundDate: "inboundDate",
  OutboundDate: "outboundDate",
  FromDate: "From Date",
  ToDate: "To Date",
  MaterialDetails: "materialDetails",
  PackagingType: "packagingType",
  ItemDescription: "itemDescription",
  Dimension: "dimension",
  Units: "units",
  StorageType: "storageType",
  AdditionalFeature: "additionalFeature",
  ConfirmPickupLocation: "confirmPickupLocation",
  loginMobileNumber: "loginMobileNumber",
  otp: "otp",
  pallets: "pallets",
  totalWeight: "totalWeight",
  PickUpDate: "pickUpDate",
  ShippingDate: "shippingDate",
};

export const NewPartnerFormKeys = {
  partnerCode: "partnerCode",
  partnerName: "partnerName",
  signupDate: "signupDate",
  partnerCity: "partnerCity",
  partnerCluster: "partnerCluster",
  partnerType: "partnerType",
  partnerCommission: "partnerCommission",
  registerMobile: "registerMobile",
  registerEmail: "registerEmail",
  ownerKdmName: "ownerKdmName",
  ownerKdmContact: "ownerKdmContact",
  pocName: "pocName",
  pocContact: "pocContact",
  bdmOnboarded: "bdmOnboarded",
  bdmContact: "bdmContact",
  accountType: "accountType",
  bankName: "bankName",
  accountName: "accountName",
  accountNumber: "accountNumber",
  ifscCode: "ifscCode",
  gstinNo: "gstinNo",
  panNo: "panNo",
  splitEnabled: "splitEnabled",
  gstReg: "gstReg",
  specialNotes: "specialNotes",
};

export const EditPartnerFormKeys = {
  partnerCode: "partnerCode",
  partnerName: "partnerName",
  signupDate: "signupDate",
  partnerCity: "partnerCity",
  partnerCluster: "partnerCluster",
  partnerType: "partnerType",
  partnerCommission: "partnerCommission",
};

export const BookOrderFormKeys = {
  PickUpDate: "pickUpDate",
  PickUpTime: "pickUpTime",
  FirstName: "firstName",
  LastName: "lastName",
  FirmsName: "firmsName",
  FirmsEmail: "firmsEmail",
  GSTIN: "gstin",
  Contact: "contact",
  StreetAddress: "streetAddress",
  City: "city",
  Country: "country",
  ZipCode: "zipCode",
  AcceptTnC: "acceptTnC",
};

export const ListingsFormKeys = {
  SortBy: "sortBy",
};

export const rfqServiceKeys = {
  warehouse: "warehouse",
  transport: "transport",
  warehousePriceExpectation: "warehousePriceExpectation",
  transportPriceExpectation: "transportPriceExpectation",
};

export const cartServiceKeys = {
  warehouse: "warehouse",
  transport: "transport",
};

export const contactUsFormKeys = {
  name: "name",
  number: "number",
  email: "email",
  subject: "subject",
  description: "description",
};

export const TableFieldKeys = {
  search: "search",
};

export const CancelInquiryFormKeys = {
  reasonCancel: "reasonCancel",
};

export const OrderStatusKeys = {
  Completed: "Completed",
  ToBePickedUp: "ToBePickedUp",
  Cancelled: "Cancelled",
  VehicleAssigned: "VehicleAssigned",
  InTransit: "InTransit",
  ReceivedAtFacility: "ReceivedAtFacility",
  Stored: "Stored",
  PartiallyRetrieved: "PartiallyRetrieved",
};

export const PackingNotesFormKeys = {
  PackageName: "name",
  PackingMaterial: "packagingType",
  PackageSize: "packageDimension",
  ItemName: "name",
  Category: "materialSubCategory",
  Quantity: "quantity",
  Items: "items",
};

export const LedgerStatusKeys = {
  Partial: "Partial",
  InvoicePending: "Invoice pending",
  Paid: "Paid",
};

export const RetrieveStatusKeys = {
  GatePassRequested: "GatePassRequested",
  GatePassPrepared: "GatePassPrepared",
};

export const regretFormKeys = {
  regretReason: "regretReason",
  otherReason: "otherReason",
};

export const ProfileFormKeys = {
  FirstName: "firstName",
  LastName: "lastName",
  Phone: "phone",
  Email: "email",
  FirmsName: "firmsName",
  GSTIN: "gstin",
  NatureOfBusiness: "natureOfBusiness",
  Products: "products",
  Brands: "brands",
  Street: "street",
  City: "city",
  State: "state",
  Country: "country",
  ZipCode: "zipCode",
  OldPassword: "OldPassword",
  NewPassword: "NewPassword",
};

export const warehouseFormKeys = {
  warehouseName: "warehouseName",
  establishedYear: "establishedYear",
  warehouseAddress: "warehouseAddress",
  warehouseLocation: "warehouseLocation",
  warehouseCity: "warehouseCity",
  warehouseState: "warehouseState",
  warehouseZipCode: "warehouseZipCode",
  billingAddress: "billingAddress",
  gstInNumber: "gstInNumber",
  warehouseDescription: "warehouseDescription",
  totalStorageSpace: "totalStorageSpace",
  availableSpace: "availableSpace",
  storageTypeAllowed: "storageTypeAllowed",
  storageTypes: "storageTypes",
  materialDetails: "materialDetails",
  additionalServices: "additionalServices",
  additionalServicesFields: "additionalServicesFields",
  features: "features",
  heroImage: "heroImage",
  heroImageFile: "heroImageFile",
  useSameBillingAddress: "useSameBillingAddress",
  warehouseImages: "warehouseImages",
  lat: "lat",
  lng: "lng",
};

export const transportFormKeys = {
  transportName: "transportName",
  establishedYear: "establishedYear",
  transportAddress: "transportAddress",
  transportLocation: "transportLocation",
  transportCity: "transportCity",
  transportState: "transportState",
  transportZipCode: "transportZipCode",
  billingAddress: "billingAddress",
  gstInNumber: "gstInNumber",
  gstRegime: "gstRegime",
  transportDescription: "transportDescription",
  additionalServices: "additionalServices",
  useSameBillingAddress: "useSameBillingAddress",
  truckNumber: "truckNumber",
  truckMake: "truckMake",
  truckModel: "truckModel",
  pricePerKm: "pricePerKm",
  truckImage: "truckImage",
  additionalServicesFields: "additionalServicesFields",
  lat: "lat",
  lng: "lng",
  trucks: "trucks",
};

export const TruckFormKeys = {
  truckNumber: "truckNumber",
  truckMake: "truckMake",
  truckModel: "truckModel",
  pricePerKm: "pricePerKm",
  truckImage: "truckImage",
};

export const partnerPaymentFormKeys = {
  paymentAmount: "paymentAmount",
  transactionId: "transactionId",
  transactionDate: "transactionDate",
  commissionAmount: "commissionAmount"
};

export const changeStatusKeys = {
  status: "status"
}

export const inquiryAmountKeys = {
  subtotal: "subtotal",
  gst: "gst",
  total: "total"
}
