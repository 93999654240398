import { Box, Button, Grid, Typography, Divider } from "@mui/material";
import { PercentOutlined } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { CalendarTodayOutlined } from "@mui/icons-material";
import TextInput from "../../shared/formElements/TextInput";
import DropDown from "../../shared/formElements/DropDown";
import DateInput from "../../shared/formElements/DateInput";
import TextArea from "../../shared/formElements/TextArea";
import SuccessfulDialog from "../../shared/UiElements/SuccessfulDialog";
import { AddPartner_labels, Partner_labels } from "../../constants";
import { NewPartnerFormKeys } from "../../constants/formKeys";
import { useCustomDialog } from "../../shared/customDialog";
import { yupResolver } from "@hookform/resolvers/yup";
import { formSchema } from "./FormDataValidation";
import { useFetchAddPartnerFormDataQuery } from "../../query-hooks/Partners/useFetchAddPartnerFormDataQuery";
import { useAddPartnerMutateQuery } from "../../query-hooks/Partners/useAddPartnerMutateQuery";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";

export const parserFunction = (e) => {
  const transformedData = e?.map((item) => {
    return {
      name: item,
      displayName: item,
    };
  });
  return transformedData;
};

function AddPartner() {
  const { control, handleSubmit, watch, formState } = useForm({
    resolver: yupResolver(formSchema),
  });
  const navigate = useNavigate();
  const { showDialog, hideDialog } = useCustomDialog();
  const { data, isFetching } = useFetchAddPartnerFormDataQuery();
  const { mutate, isError, error } = useAddPartnerMutateQuery();
  const partnerTypeWatch = watch(NewPartnerFormKeys.partnerType);

  const onSubmit = (data) => {
    mutate(
      {
        partnerType: data.partnerType,
        name: data.partnerName,
        code: data.partnerCode,
        signUpDate: moment(data.signupDate).format("YYYY-MM-DD"),
        pan: data.panNo,
        gstNo: data.gstinNo,
        gstRegime: data.gstReg,
        note: data.specialNotes,
        commission: {
          percent: data.partnerCommission,
        },
        bankDetails: {
          accountType: data.accountType,
          bankName: data.bankName,
          beneficiaryName: data.accountName,
          accountNumber: data.accountNumber,
          ifsc: data.ifscCode,
        },
        address: {
          city: data.partnerCity,
          cluster: data.partnerCluster,
        },
        kdm: {
          name: data.ownerKdmName,
          mobile: data.ownerKdmContact,
        },
        poc: {
          name: data.pocName,
          mobile: data.pocContact,
        },
        bdm: {
          name: data.bdmOnboarded,
          mobile: data.bdmContact,
        },
        user: {
          email: data.registerEmail,
          mobile: data.registerMobile,
        },
      },
      {
        onSuccess: (res) => {
          enqueueSnackbar(
            "Email/ SMS notifications is Sent to the Partner along with login credentials.",
            { variant: "success" }
          );
          navigate("/partner");
        },
      }
    );
  };

  const handleConfirm = () => {
    handleSubmit(onSubmit)();
    hideDialog();
  };

  const SuccessAlert = ({ hideDialog, handleConfirm }) => {
    return (
      <SuccessfulDialog
        subText={
          "Do you want to create a new Partner into your database? This will send email/ SMS notifications to the Partner along with login credentials."
        }
        btn1Text={AddPartner_labels.yesConfirm}
        btn2Text={AddPartner_labels.goBack}
        btn1Callback={handleConfirm}
        btn2Callback={hideDialog}
      />
    );
  };

  useEffect(() => {
    if (isError)
      enqueueSnackbar(error?.response.data.message, { variant: "error" });
  }, [error?.response.data.message, isError]);

  return (
    <Box display="flex" justifyContent="center" width="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container justifyContent={"space-between"} marginBottom={5}>
          <Grid item>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                lineHeight: "20px",
                letterSpacing: "0.005em",
                marginTop: "30px",
              }}
            >
              {Partner_labels.CreateNewPartner}
            </Typography>
          </Grid>
          <Grid item xs={2} width={140}>
            <TextInput
              control={control}
              name={NewPartnerFormKeys.partnerCode}
              label={Partner_labels.partnerCode}
            />
          </Grid>
          <Grid item xs={2} marginTop={"20px"}>
            <Button
              variant="contained"
              sx={{ width: "140px", height: "48px" }}
              onClick={() =>
                // handleSubmit(onSubmit)
                {
                  showDialog({
                    component: (
                      <SuccessAlert
                        hideDialog={hideDialog}
                        handleConfirm={handleConfirm}
                      />
                    ),
                  });
                }
              }
              disabled={!formState.isValid}
            >
              {Partner_labels.createButton}
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"space-between"}
          sx={{ width: "700px" }}
          marginBottom={1.5}
        >
          <Grid item xs={2} minWidth={427}>
            <TextInput
              control={control}
              name={NewPartnerFormKeys.partnerName}
              label={Partner_labels.PartnerName}
            />
          </Grid>
          <Grid item xs={2} minWidth={190}>
            <DateInput
              control={control}
              name={NewPartnerFormKeys.signupDate}
              endIcon={CalendarTodayOutlined}
              placeholder={"Enter Date"}
              label={Partner_labels.SignupDate}
              minDate={"1900-01-01"}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"space-between"}
          sx={{ width: "700px" }}
          marginBottom={1.5}
        >
          <Grid item xs={2} minWidth={280}>
            <DropDown
              control={control}
              name={NewPartnerFormKeys.partnerCity}
              label={Partner_labels.PartnerCity}
              isLoading={isFetching}
              options={parserFunction(data?.cities)}
            />
          </Grid>
          <Grid item xs={2} minWidth={280}>
            <TextInput
              control={control}
              name={NewPartnerFormKeys.partnerCluster}
              label={Partner_labels.PartnerCluster}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"space-between"}
          xs={12}
          marginBottom={1.5}
        >
          <Grid item xs={2} minWidth={280}>
            <DropDown
              control={control}
              name={NewPartnerFormKeys.partnerType}
              label={Partner_labels.PartnerType}
              isLoading={isFetching}
              options={data?.partnerTypes}
            />
          </Grid>
          <Grid item xs={2} container></Grid>
          <Grid item xs={2} minWidth={145}>
            <TextInput
              control={control}
              name={NewPartnerFormKeys.partnerCommission}
              label={Partner_labels.PartnerCommission}
              endIcon={PercentOutlined}
            />
          </Grid>
          <Grid item xs={2} minWidth={100}>
            <DropDown
              control={control}
              name={NewPartnerFormKeys.gstReg}
              label={Partner_labels.gstReg}
              isLoading={isFetching}
              options={parserFunction(
                partnerTypeWatch === "Warehouse"
                  ? data?.gstRegimes?.Warehouse
                  : data?.gstRegimes?.Transport
              )}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"space-between"}
          xs={12}
          marginBottom={1.5}
        >
          <Grid item xs={2} minWidth={280}>
            <TextInput
              control={control}
              startIcon={<Box padding={"0 0 0 16px"}>+91</Box>}
              name={NewPartnerFormKeys.registerMobile}
              placeholder={Partner_labels.mobileNumber}
              label={Partner_labels.RegisterMobile}
            />
          </Grid>
          <Grid item xs={2} minWidth={280}>
            <TextInput
              control={control}
              name={NewPartnerFormKeys.registerEmail}
              label={Partner_labels.RegisterEmail}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"space-between"}
          xs={12}
          marginBottom={1.5}
        >
          <Grid item xs={2} minWidth={280}>
            <TextInput
              control={control}
              name={NewPartnerFormKeys.ownerKdmName}
              label={Partner_labels.OwnerKdmName}
            />
          </Grid>
          <Grid item xs={2} minWidth={280}>
            <TextInput
              control={control}
              startIcon={<Box padding={"0 0 0 16px"}>+91</Box>}
              name={NewPartnerFormKeys.ownerKdmContact}
              label={Partner_labels.OwnerKdmContact}
              placeholder={Partner_labels.mobileNumber}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"space-between"}
          xs={12}
          marginBottom={1.5}
        >
          <Grid item xs={2} minWidth={280}>
            <TextInput
              control={control}
              name={NewPartnerFormKeys.pocName}
              label={Partner_labels.PocName}
            />
          </Grid>
          <Grid item xs={2} minWidth={280}>
            <TextInput
              control={control}
              startIcon={<Box padding={"0 0 0 16px"}>+91</Box>}
              name={NewPartnerFormKeys.pocContact}
              label={Partner_labels.PocContact}
              placeholder={Partner_labels.mobileNumber}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"space-between"}
          xs={12}
          marginBottom={4}
        >
          <Grid item xs={2} minWidth={280}>
            <TextInput
              control={control}
              name={NewPartnerFormKeys.bdmOnboarded}
              label={Partner_labels.BdmOnboarded}
            />
          </Grid>
          <Grid item xs={2} minWidth={280}>
            <TextInput
              control={control}
              startIcon={<Box padding={"0 0 0 16px"}>+91</Box>}
              name={NewPartnerFormKeys.bdmContact}
              placeholder={Partner_labels.mobileNumber}
              label={Partner_labels.BdmContact}
            />
          </Grid>
        </Grid>
        <Divider
          sx={{
            borderColor: "#B7BEC7",
            display: {
              xs: "none",
              md: "block",
            },
          }}
        />
        <Grid container justifyContent={"space-between"} xs={12} marginY={2}>
          <Grid item xs={2} minWidth={280}>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 500,
                lineHeight: "20px",
                marginBottom: "8px",
              }}
            >
              {Partner_labels.BankAccountDetails}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"space-between"}
          xs={12}
          marginBottom={1.5}
        >
          <Grid item xs={2} minWidth={280}>
            <DropDown
              control={control}
              name={NewPartnerFormKeys.accountType}
              label={Partner_labels.AccountType}
              isLoading={isFetching}
              options={parserFunction(data?.bankAccountTypes)}
            />
          </Grid>
          <Grid item xs={2} minWidth={280}>
            <TextInput
              control={control}
              name={NewPartnerFormKeys.bankName}
              label={Partner_labels.BankName}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"space-between"}
          xs={12}
          marginBottom={1.5}
        >
          <Grid item xs={2} minWidth={280}>
            <TextInput
              control={control}
              name={NewPartnerFormKeys.accountName}
              label={Partner_labels.AccountName}
            />
          </Grid>
          <Grid item xs={2} minWidth={280}>
            <TextInput
              control={control}
              name={NewPartnerFormKeys.accountNumber}
              label={Partner_labels.AccountNumber}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"space-between"}
          xs={12}
          marginBottom={1.5}
        >
          <Grid item xs={2} minWidth={280}>
            <TextInput
              control={control}
              name={NewPartnerFormKeys.ifscCode}
              label={Partner_labels.IFSCCode}
            />
          </Grid>
          <Grid item xs={2} minWidth={280}>
            <TextInput
              control={control}
              name={NewPartnerFormKeys.gstinNo}
              label={Partner_labels.gstinNo}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"space-between"}
          xs={12}
          marginBottom={1.5}
        >
          <Grid item xs={2} minWidth={280}>
            <TextInput
              control={control}
              name={NewPartnerFormKeys.panNo}
              label={Partner_labels.panNo}
            />
          </Grid>
        </Grid>
        <Divider
          sx={{
            color: "#B7BEC7",
            display: {
              xs: "none",
              md: "block",
            },
          }}
        />
        <Grid
          container
          justifyContent={"space-between"}
          xs={12}
          marginTop={2}
          marginBottom={20}
        >
          <Grid item xs={2} minWidth={700} marginTop={2} marginBottom={20}>
            <TextArea
              control={control}
              name={NewPartnerFormKeys.specialNotes}
              placeholder={"ex: Partner wants to try for 2 months only. "}
              label={Partner_labels.SpecialNotes}
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default AddPartner;
