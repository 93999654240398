import { updateWarehouseFiles } from "../../admin/action";
import { useMutation } from "@tanstack/react-query";

export const useReplaceWarehouseFileQuery = () => {
  const updateWarehouseFilesMutate = useMutation(({ partnerId,fileId, formData }) =>
    updateWarehouseFiles(partnerId, fileId, formData)
  );

  return updateWarehouseFilesMutate;
};
