import * as yup from "yup";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import CustomTable from "../../shared/customTable";
import { ActionIcons, TitleText } from "./UserRolesTable";
import TextInput from "../../shared/formElements/TextInput";
import DateInput from "../../shared/formElements/DateInput";
import { useCustomDialog } from "../../shared/customDialog";
import { useFieldArray, useForm } from "react-hook-form";
import { AddOutlined } from "@mui/icons-material";
import { error_msg } from "../../constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFetchAllSubscriptions } from "../../query-hooks/Subscription/useFetchAllSubscriptions";
import { useCreateSubscription } from "../../query-hooks/Subscription/useCreateSubscription";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import SuccessfulDialog from "../../shared/UiElements/SuccessfulDialog";
import { useExpireSubscription } from "../../query-hooks/Subscription/useExpireSubscription";

const getColumns = (
  removeNewRow,
  onEditClick,
  onStatusClick,
  onAddClick,
  newRowAdded
) => {
  return [
    {
      Header: "Subscription ID",
      accessor: "code",
      width: 159,
      disableSortBy: true
    },
    {
      Header: "Start Date",
      accessor: "startDate",
      width: 143,
      disableSortBy: true
    },
    {
      Header: "End Date",
      accessor: "endDate",
      width: 143,
      disableSortBy: true
    },
    {
      Header: "Amount Paid",
      accessor: "amountPaid",
      width: 120,
      disableSortBy: true
    },
    {
      Header: "Reference No.",
      accessor: "referenceNo",
      width: 140,
      disableSortBy: true
    },
    {
      Header: "Date of Payment",
      accessor: "dateOfPayment",
      width: 153,
      disableSortBy: true
    },
    {
      Header: "Number of Users",
      accessor: "numberOfUsers",
      width: 154,
      disableSortBy: true
    },
    {
      Header: "Status",
      accessor: "status",
      width: 170,
      disableSortBy: true,
      Cell: ({ row, value }) => {
        return (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={0.4}
          >
            {value && (
              <StyledChip
                label={value}
                onClick={() => {
                  value === "Active" && onStatusClick(row.original);
                }}
              />
            )}
            <Box display={"flex"} gap={"3px"}>
              <ActionIcons
                type={row?.original?.type}
                status={value}
                isEditEnabled={row?.original.inputDisable}
                newIndex={newRowAdded}
                removeNewRow={() => removeNewRow(row?.original.index)}
                EditClick={() => onEditClick(row?.original.index)}
                AddClick={onAddClick}
              />
            </Box>
          </Box>
        );
      }
    }
  ];
};

const getRows = (rows = [], control, startDateWatch) => {
  return rows.map((item, index) => {
    return {
      index: index,
      subId: item._id,
      code: item.code,
      startDate: Boolean(item.startDate) ? (
        moment(item.startDate).format("DD-MM-YYYY")
      ) : (
        <DateInput
          control={control}
          name={`users.${index}.startDate`}
          disabled={item.inputDisable}
          minDate={"2000-01-01"}
          popperPlacement="top"
        />
      ),
      endDate: Boolean(item.endDate) ? (
        moment(item.endDate).format("DD-MM-YYYY")
      ) : (
        <DateInput
          control={control}
          name={`users.${index}.endDate`}
          disabled={item.inputDisable}
          minDate={
            new Date(new Date(startDateWatch).valueOf() + 1000 * 3600 * 24)
          }
          popperPlacement="top"
        />
      ),
      amountPaid: item.amountPaid ? (
        item.amountPaid
      ) : (
        <TextInput
          control={control}
          name={`users.${index}.amountPaid`}
          disabled={item.inputDisable}
        />
      ),
      referenceNo: item.referenceNo ? (
        item.referenceNo
      ) : (
        <TextInput
          control={control}
          name={`users.${index}.referenceNo`}
          disabled={item.inputDisable}
        />
      ),
      dateOfPayment: Boolean(item.dateOfPayment) ? (
        moment(item.dateOfPayment).format("DD-MM-YYYY")
      ) : (
        <DateInput
          control={control}
          name={`users.${index}.dateOfPayment`}
          disabled={item.inputDisable}
          minDate={"2000-01-01"}
          popperPlacement="top"
        />
      ),
      numberOfUsers: item.numberOfUsers ? (
        item.numberOfUsers
      ) : (
        <TextInput
          control={control}
          name={`users.${index}.numberOfUsers`}
          disabled={item.inputDisable}
        />
      ),
      status: item?.status ?? "",
      type: item.type,
      inputDisable: item.inputDisable
    };
  });
};

const subscriptionSchema = yup.object({
  users: yup.array().of(
    yup.object().shape({
      startDate: yup.string().required(error_msg.required),
      endDate: yup.string().required(error_msg.required),
      amountPaid: yup.string().required(error_msg.required),
      referenceNo: yup.string().required(error_msg.required),
      dateOfPayment: yup.string().required(error_msg.required),
      numberOfUsers: yup.string().required(error_msg.required)
    })
  )
});

const SubscriptionTable = ({ clientId, companyName, refetchClient }) => {
  const { control, setValue, handleSubmit, reset, watch } = useForm({
    resolver: yupResolver(subscriptionSchema)
  });

  const { showDialog, hideDialog } = useCustomDialog();

  const {
    data: subscriptionData,
    isFetching: isFetchingSubscriptions,
    refetch: refetchSubscriptions
  } = useFetchAllSubscriptions(clientId);

  const {
    mutate: createSubscription,
    isError: isCreateError,
    error: createError
  } = useCreateSubscription();

  const {
    mutate: expireSubscription,
    isError: isExpireSubscriptionError,
    error: expireSubscriptionError
  } = useExpireSubscription();

  const { fields, append, remove } = useFieldArray({
    name: "users",
    control
  });

  const addExistingUsers = useCallback(
    (data) => {
      data.forEach((item, index) => {
        append({
          _id: item._id,
          code: item.code,
          startDate: new Date(item.startDate),
          endDate: new Date(item.endDate),
          amountPaid: item.amount,
          referenceNo: item.reference,
          dateOfPayment: new Date(item.paymentDate),
          numberOfUsers: item.userCount,
          status: item.status.name,
          type: "presentSubscription",
          inputDisable: true
        });
      });
    },
    [append]
  );

  const addNewUser = () => {
    append({
      subId: "",
      startDate: "",
      endDate: "",
      amountPaid: "",
      referenceNo: "",
      dateOfPayment: "",
      numberOfUsers: "",
      status: "",
      type: "newSubscription",
      inputDisable: false
    });
  };

  const startDateWatch = watch(
    `users.${fields.length ? fields.length - 1 : 0}.startDate`
  );

  useEffect(() => {
    if (!isFetchingSubscriptions) {
      addExistingUsers(subscriptionData?.subscriptions);
    }
  }, [
    addExistingUsers,
    subscriptionData?.subscriptions,
    isFetchingSubscriptions
  ]);

  const toggleEdit = (index) => {
    const updatedFields = [...fields];
    updatedFields[index].inputDisable = !updatedFields[index].inputDisable;
    setValue("users", updatedFields);
  };

  const removeNewRow = (index) => {
    remove(index);
  };

  const onExpire = useCallback(
    (data) => {
      expireSubscription(
        {
          clientId: clientId,
          subscriptionId: data
        },
        {
          onSuccess: (res) => {
            refetchClient();
            reset({
              users: []
            });
            hideDialog();
            refetchSubscriptions();
            enqueueSnackbar("Subscriptions Expired!", {
              variant: "success"
            });
          }
        }
      );
    },
    [
      clientId,
      expireSubscription,
      hideDialog,
      refetchSubscriptions,
      refetchClient,
      reset
    ]
  );

  const onStatusClick = useCallback(
    (row) => {
      showDialog({
        component: (
          <SuccessfulDialog
            text={`This Customer's subscription status is`}
            status={row.status}
            subText={`Do you want to Expire this ${row?.code} subscription ?`}
            btn1Variant={"soft"}
            btn1Text={"Yes,Expire"}
            btn2Text={"Go Back"}
            btn1Callback={() => onExpire(row?.subId)}
            btn2Callback={hideDialog}
          />
        ),
        backdropOff: true
      });
    },
    [onExpire, hideDialog, showDialog]
  );

  const onAddClick = (data) => {
    const newRowData = data.users[data.users.length - 1];
    const formData = {
      startDate: moment(newRowData.startDate).format("YYYY-MM-DD"),
      endDate: moment(newRowData.endDate).format("YYYY-MM-DD"),
      amount: +newRowData.amountPaid,
      reference: newRowData.referenceNo,
      paymentDate: moment(newRowData.dateOfPayment).format("YYYY-MM-DD"),
      userCount: +newRowData.numberOfUsers
    };
    createSubscription(
      {
        clientId: clientId,
        data: { ...formData }
      },
      {
        onSuccess: () => {
          refetchClient();
          reset({
            users: []
          });
          enqueueSnackbar("Subscription Added!", {
            variant: "success"
          });
          refetchSubscriptions();
        }
      }
    );
  };

  useEffect(() => {
    if (isCreateError) {
      enqueueSnackbar(createError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isExpireSubscriptionError) {
      enqueueSnackbar(expireSubscriptionError?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [
    createError?.response?.data?.message,
    expireSubscriptionError?.response?.data?.message,
    isExpireSubscriptionError,
    isCreateError
  ]);

  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <Grid container>
        <Grid item xs={5}>
          <TitleText>{"Subscription"}</TitleText>
        </Grid>
        <Grid item xs={7}>
          <Typography
            sx={{
              color: "#2773FF",
              fontSize: "24px",
              fontWeight: 700,
              lineHeight: "33px"
            }}
          >
            {companyName?.toUpperCase() ?? "COMPANY NAME"}
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ border: "0.7px solid #B7BEC7" }} />
      <Box display={"flex"} flexDirection={"column"} gap={2} pt={4}>
        <CustomTable
          columns={getColumns(
            removeNewRow,
            toggleEdit,
            onStatusClick,
            handleSubmit(onAddClick),
            Boolean(fields?.length > subscriptionData?.subscriptions?.length)
          )}
          data={
            isFetchingSubscriptions
              ? []
              : getRows(fields, control, startDateWatch)
          }
          isLoading={isFetchingSubscriptions}
        />
        {fields?.every((obj) => obj?.inputDisable !== false) &&
          subscriptionData?.subscriptions?.length === fields.length && (
            <Box>
              <Button variant="link" disableRipple onClick={addNewUser}>
                <AddOutlined />
                {"Add Subscription"}
              </Button>
            </Box>
          )}
      </Box>
    </Box>
  );
};

export default SubscriptionTable;
