import { deleteTransportVehicle } from "../../admin/action";
import { useMutation } from "@tanstack/react-query";

export const useDeleteTransportVehicleQuery = () => {
  const deleteVehicle = useMutation(
    ({ partnerId, vehicleId }) =>
      deleteTransportVehicle(partnerId, vehicleId)
  );

  return deleteVehicle;
};
