import { Box, Button, Divider, Grid } from "@mui/material";

import DropDown from "../../shared/formElements/DropDown";
import { ImageUpload } from "../../shared/formElements/ImageUpload";
import { StyledFormTitle } from "./AccordionWarehouseDetails";
import TextInput from "../../shared/formElements/TextInput";
import { TruckFormKeys } from "../../constants/formKeys";
import { transport_labels } from "../../constants";
import { useAddTransportVehicleQuery } from "../../query-hooks/EditTransport/useAddTransportVehicleQuery";
import { useFetchVehicleFormData } from "../../query-hooks/EditTransport/useFetchVehicleFormData";
import { useForm } from "react-hook-form";
import { vehicleFormSchema } from "./TransportFormValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";

const AddVehicleForm = ({ isFormDisabled, ...props }) => {
  const {
    control: detailControl,
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(vehicleFormSchema),
  });

  const truckMake = watch(TruckFormKeys.truckMake);

  const { data: vehicleFormData } = useFetchVehicleFormData(
    props?.partner?._id
  );

  const { mutate, isError, error, isLoading} =
    useAddTransportVehicleQuery();

  const handleAddTruck = (data) => {
    const formData = new FormData();
    formData.append("regNo", data[TruckFormKeys.truckNumber]);
    formData.append("model", data[TruckFormKeys.truckModel]);
    formData.append("rate", data[TruckFormKeys.pricePerKm]);
    formData.append("file", data[TruckFormKeys.truckImage]);
    mutate(
      {
        partnerId: props?.partner?._id,
        formData: formData,
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Vehicle created!", { variant: "success" });
          props?.refetch();
        },
      }
    );
  };

  useEffect(() => {
    if (isError)
      enqueueSnackbar(error?.response.data.message, { variant: "error" });
  }, [error?.response.data.message, isError]);

  return (
    <Box
      component={"form"}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      maxWidth={572}
    >
      <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
        <StyledFormTitle>
          {transport_labels.addTruckDetailLabelText}
        </StyledFormTitle>
        <Button
          variant="text"
          component={LoadingButton}
          disabled={isFormDisabled}
          onClick={handleSubmit(handleAddTruck)}
          loading={isLoading}
        >
          {transport_labels.addAnotherTruckButtonText}
        </Button>
      </Grid>
      <Grid item xs={12} marginBottom={1}>
        <Divider />
      </Grid>
      <Grid container spacing={"28px"}>
        <Grid item xs={6}>
          <TextInput
            control={detailControl}
            name={TruckFormKeys.truckNumber}
            label={transport_labels.truckNumberLabelText}
            placeholder={transport_labels.truckNumberPlaceholderText}
            disabled={isFormDisabled}
          />
        </Grid>
        <Grid item xs={6}>
          <DropDown
            control={detailControl}
            name={TruckFormKeys.truckMake}
            label={transport_labels.truckMakeLabelText}
            placeholder={transport_labels.truckMakePlaceholderText}
            options={vehicleFormData?.vehicles}
            setValue={setValue}
            disabled={isFormDisabled}
          />
        </Grid>
        <Grid item xs={6}>
          <DropDown
            control={detailControl}
            name={TruckFormKeys.truckModel}
            label={transport_labels.truckModelLabelText}
            placeholder={transport_labels.truckModelPlaceholderText}
            options={
              truckMake
                ? Object.values(
                    vehicleFormData?.vehicles.find(
                      (item) => item.name === truckMake
                    )?.models
                  )
                : []
            }
            setValue={setValue}
            disabled={isFormDisabled}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            control={detailControl}
            name={TruckFormKeys.pricePerKm}
            label={transport_labels.pricePerKmLabelText}
            placeholder={transport_labels.pricePerKmPlaceholderText}
            disabled={isFormDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <ImageUpload
            name={TruckFormKeys.truckImage}
            control={detailControl}
            setValue={setValue}
            compact={true}
            uploadButtonText={transport_labels.truckUploadButtonText}
            disabled={isFormDisabled}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddVehicleForm;
