export const queryKeys = {
  queryFetchFormData: "query-form-data",
  queryFetchPartners: "query-get-partners",
  queryFetchPartnerById: "query-get-partner-by-id",
  queryGetWarehouseFormData: "query-get-warehouse-form-data",
  queryGetTransportFormData: "query-get-transport-form-data",
  queryGetVehicleFormData: "query-get-vehicle-form-data",
  queryGetAllTransportVehicle: "query-get-all-transport-vehicles",
  queryFetchOrders: "query-get-orders",
  queryFetchInquiries: "query-get-inquiries",
  queryFetchInquiryById: "query-get-inquiry-by-Id",
  queryFetchOrderStatusFormData: "query-get-order-status-form-data",
  queryFetchSettlement: "query-get-settlement",
  queryFetchSettlementById: "query-get-settlement-by-Id",
  queryFetchCustomers: "query-get-customers",
  queryFetchCustomerById: "query-get-customer-by-Id",
  queryFetchSubscriptions: "query-get-subscriptions",
  queryFetchSubUsers: "query-get-SubUsers",
  queryGetCreateSubUserFormData: "query-get-create-subUser-form-data",
  queryGetUpdateSubUserFormData: "query-get-update-subUser-form-data",
};
