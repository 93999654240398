import { Auth } from "aws-amplify";
import { Box, Button} from "@mui/material";
import { ProfileFormKeys } from "../../constants/formKeys";
import TextInput from "../../shared/formElements/TextInput";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";

const ChangePassword = () => {
  const { control, handleSubmit } = useForm({});

  const handlePasswordChange = async (data) => {
    console.log(data);
    try {
      await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        data[ProfileFormKeys.OldPassword],
        data[ProfileFormKeys.NewPassword]
      );
      console.log("Password changed successfully.");
    } catch (error) {
      console.error("Error changing password:", error);
    }
  };

  return (
    <Box
      component={"form"}
      onSubmit={handleSubmit(handlePasswordChange)}
      display={"flex"}
      flexDirection={"column"}
      maxWidth="40%"
      gap={3}
    >
      <TextInput
        control={control}
        name={ProfileFormKeys.OldPassword}
        type="password"
        label="Old Password"
      />
      <TextInput
        control={control}
        name={ProfileFormKeys.NewPassword}
        type="password"
        label="New Password"
      />
      <Button
        variant="contained"
        component={LoadingButton}
        onClick={handleSubmit(handlePasswordChange)}
        sx={{height:"40px"}}
      >
        Change Password
      </Button>
    </Box>
  );
};

export default ChangePassword;
