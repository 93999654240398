import { queryKeys } from "../../constants/queryKeys";
import { fetchTransportFormData } from "../../admin/action";
import { useQuery } from "@tanstack/react-query";

export const useFetchTransportFormDataQueryHook = (partnerId) => {
  const transportFormDataQuery = useQuery(
    [queryKeys.queryGetTransportFormData],
    () => fetchTransportFormData(partnerId),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(partnerId)
    }
  );
  return transportFormDataQuery;
};
