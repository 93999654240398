import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import {
  Partner_labels,
  labels,
  regimeRadioGroup,
  transport_labels,
} from "../../constants";
import {
  StyledBoxContainer,
  StyledFormTitle,
  SuccessAlert,
} from "./AccordionWarehouseDetails";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import AddVehicleForm from "./AddVehicleForm";
import AutoCompleteMapField from "../../shared/formElements/AutoCompleteMapField";
import CheckBoxGroup from "../../shared/formElements/CheckBoxGroup";
import DateInput from "../../shared/formElements/DateInput";
import RadioButtonGroup from "../../shared/formElements/RadioButtonGroup";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import TextArea from "../../shared/formElements/TextArea";
import TextInput from "../../shared/formElements/TextInput";
import TruckDetailCard from "./TruckDetailCard";
import UpdateTruckDetailsForm from "./UpdateTruckDetailsForm";
import { transportFormKeys } from "../../constants/formKeys";
import { transportFormSchema } from "./TransportFormValidation";
import { useCustomDialog } from "../../shared/customDialog";
import { useApprovePartnerMutateQuery } from "../../query-hooks/Partners/useApprovePartnerMutateQuery";
import { useStatusPartnerMutateQuery } from "../../query-hooks/Partners/useStatusPartnerMutateQuery";
import { useDeleteTransportVehicleQuery } from "../../query-hooks/EditTransport/useDeleteTransportVehicleQuery";
import { useFetchTransportFormDataQueryHook } from "../../query-hooks/EditTransport/useFetchTransportFormDataQueryHook";
import { useMutateTransportPartnerQuery } from "../../query-hooks/EditTransport/useMutateTransportPartnerQuery";
import { yupResolver } from "@hookform/resolvers/yup";
import { enqueueSnackbar } from "notistack";

export const AccordionTransportDetails = (props) => {
  const [isFormDisabled, setIsFormDisabled] = useState(true);

  const [truckDetails, setTruckDetails] = useState([]);

  const { showDialog, hideDialog } = useCustomDialog();

  const allowStatusChangeButton = props?.partner?.status?.name !== "Active";
  const editAllowed = props?.partner?.status?.name === "Inactive";

  const { data: formData, isFetched: isFormDataFetched } =
    useFetchTransportFormDataQueryHook(props?.partner?._id);

  const {
    control: detailControl,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      [transportFormKeys.transportName]: props?.partner?.name,
      [transportFormKeys.establishedYear]: new Date(
        `${props?.partner?.establishedYear ?? 2023}-01-01T00:00:00.000Z`
      ),
      [transportFormKeys.lat]:
        props?.partner?.address?.location?.coordinates[0],
      [transportFormKeys.lng]:
        props?.partner?.address?.location?.coordinates[1],
      [transportFormKeys.transportCity]: props?.partner?.address?.city,
      [transportFormKeys.transportState]: props?.partner?.address?.state,
      [transportFormKeys.transportZipCode]: props?.partner?.address?.pincode,
      [transportFormKeys.useSameBillingAddress]: props?.partner?.billingAddress
        ?.sameAsAddress
        ? ["yes"]
        : [],
      [transportFormKeys.billingAddress]: props?.partner?.billingAddress
        ?.sameAsAddress
        ? props?.partner?.address?.area
        : props?.partner?.billingAddress?.area,
      [transportFormKeys.gstInNumber]: props?.partner?.gstNo,
      [transportFormKeys.gstRegime]: props?.partner?.gstRegime,
      [transportFormKeys.transportDescription]: props?.partner?.description,
      [transportFormKeys.transportLocation]: props?.partner?.address?.area,
      [transportFormKeys.additionalServices]: [
        ...props?.partner?.additionalServices?.map((item) => item.name),
      ],
    },
    resolver: yupResolver(transportFormSchema),
  });

  const {
    fields: additionalServicesFields,
    append: appendAdditionalService,
    remove: removeAdditionalService,
  } = useFieldArray({
    control: detailControl,
    name: transportFormKeys.additionalServicesFields,
  });

  const isSameBillingAddress = watch(transportFormKeys.useSameBillingAddress);

  const transportLocation = watch(transportFormKeys.transportLocation);

  const additionalServicesWatch = watch(transportFormKeys.additionalServices);

  useEffect(() => {
    if (isSameBillingAddress[0]) {
      setValue(transportFormKeys.billingAddress, transportLocation);
    }
  }, [isSameBillingAddress, setValue, transportLocation]);

  useEffect(() => {
    if (isFormDataFetched)
      additionalServicesWatch?.forEach((item) => {
        let storageFormData = formData?.additionalServices.find(
          (service) => service.name === item
        );
        let existingField = additionalServicesFields.find(
          (field) => field.name === item
        );
        if (!existingField) {
          appendAdditionalService({
            [item]: props?.partner?.additionalServices.find(
              (type) => type.name === item
            )?.rate,
            name: storageFormData.name,
            displayName: storageFormData.displayName,
            rateUnit: storageFormData.rateUnit,
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalServicesWatch, isFormDataFetched]);

  useEffect(() => {
    if (props.partner.vehicles) {
      props.partner.vehicles.forEach((truck) =>
        setTruckDetails((prevTrucks) => [
          ...prevTrucks,
          {
            truckNumber: truck.regNo,
            truckModel: truck.model.name,
            truckMake: truck.make.name,
            pricePerKm: truck.rate,
            truckImage: truck.heroImage?.thumbUrl,
            truckWeight: truck.weightCapacity,
            truckModelDisplayName: truck.model.displayName,
            truckMakeDisplayName: truck.make.displayName,
            vehicleId: truck._id,
          },
        ])
      );
    }
  }, [props?.partner?.vehicles]);

  const toggleForm = () => {
    setIsFormDisabled((prevState) => !prevState);
  };

  const {
    mutate: approvePartner,
    isError: isErrorApprove,
    error: approveError,
  } = useApprovePartnerMutateQuery();

  const {
    mutate: mutateStatus,
    isError: isErrorStatus,
    error: statusError,
  } = useStatusPartnerMutateQuery();

  const {
    mutate: mutatePartnerDetails,
    isError: isErrorPartnerDetails,
    error: partnerDetailsError,
  } = useMutateTransportPartnerQuery();

  const {
    mutate: mutateDeleteVehicle,
    isError: isErrorDeleteVehicle,
    error: deleteVehicleError,
  } = useDeleteTransportVehicleQuery();

  const approveButtonClick = () => {
    approvePartner(
      {
        partnerId: props?.partner?._id,
        formData: {},
      },
      {
        onSuccess: () => {
          hideDialog();
          enqueueSnackbar("The Partner is Now Listed and Live", {
            variant: "success",
          });
          props.refetch();
        },
      }
    );
  };

  const delistButtonClick = () => {
    mutateStatus(
      {
        partnerId: props?.partner?._id,
        formData: { status: "Inactive" },
      },
      {
        onSuccess: () => {
          hideDialog();
          enqueueSnackbar("The Partner is Now De Listed ", {
            variant: "success",
          });
          props.refetch();
        },
      }
    );
  };

  const onSubmit = (data) => {
    const TransportData = {
      name: data?.transportName,
      establishedYear: new Date(data?.establishedYear).getFullYear(),
      gstNo: data?.gstInNumber,
      gstRegime: data?.gstRegime,
      description: data?.transportDescription,
      address: {
        area: data?.transportLocation,
        city: data?.transportCity,
        state: data?.transportState,
        pincode: parseInt(data?.transportZipCode, 10),
        location: {
          coordinates: [data?.lat, data?.lng],
        },
      },
      billingAddress: {
        sameAsAddress: data?.useSameBillingAddress[0] === "yes",
      },
      additionalServices: data?.additionalServicesFields.map((item) => ({
        name: item.name,
        rate: item[item.name],
      })),
    };
    if (!TransportData?.billingAddress?.sameAsAddress) {
      TransportData.billingAddress.area = data?.billingAddress;
      TransportData.billingAddress.city = data?.transportCity;
      TransportData.billingAddress.state = data?.transportState;
      TransportData.billingAddress.pincode = parseInt(
        data?.transportZipCode,
        10
      );
    }
    mutatePartnerDetails(
      {
        partnerId: props?.partner?._id,
        formData: { ...TransportData },
      },
      {
        onSuccess: () => {
          setIsFormDisabled(true);
          enqueueSnackbar("The changes have been Updated for the Partner", {
            variant: "success",
          });
          props.refetch();
        },
      }
    );
  };

  const onDeleteVehicle = (vehicleId) => {
    mutateDeleteVehicle(
      {
        partnerId: props?.partner?._id,
        vehicleId: vehicleId,
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Vehicle Deleted!", { variant: "success" });
          props.refetch();
        },
      }
    );
  };

  useEffect(() => {
    if (
      isErrorApprove ||
      isErrorStatus ||
      isErrorPartnerDetails ||
      isErrorDeleteVehicle
    )
      enqueueSnackbar(
        approveError?.response.data.message ||
          statusError?.response.data.message ||
          partnerDetailsError?.response.data.message ||
          deleteVehicleError?.response.data.message,
        { variant: "error" }
      );
  }, [
    approveError?.response.data.message,
    statusError?.response.data.message,
    partnerDetailsError?.response.data.message,
    deleteVehicleError?.response.data.message,
    isErrorApprove,
    isErrorStatus,
    isErrorPartnerDetails,
    isErrorDeleteVehicle,
  ]);

  return (
    <Box
      component={"form"}
      onSubmit={handleSubmit(onSubmit)}
      display={"flex"}
      flexDirection={"column"}
      gap={"105px"}
      marginBottom={3}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box display={"flex"} gap={9}>
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 500,
              letterSpacing: "0.04px",
            }}
          >
            {labels.partnerListingDetails}
          </Typography>
          <StyledChip
            label={
              props?.partner.status?.name === "Inactive"
                ? props?.partner.status?.displayName
                : props?.partner.status?.name
            }
            onClick={() => {
              if (!editAllowed) {
                showDialog({
                  component: (
                    <SuccessAlert
                      approveButtonClick={approveButtonClick}
                      delistButtonClick={delistButtonClick}
                      disableDelist={allowStatusChangeButton}
                      disableApprove={!allowStatusChangeButton}
                    />
                  ),
                });
              }
            }}
          />
        </Box>
        <Box display={"flex"} gap={4}>
          <Button
            variant="soft"
            sx={{ minWidth: 140, height: 48 }}
            onClick={toggleForm}
            disabled={editAllowed}
          >
            {isFormDisabled
              ? Partner_labels.editButton
              : Partner_labels.cancelButton}
          </Button>
          <Button
            variant="contained"
            sx={{ minWidth: 140, height: 48 }}
            onClick={handleSubmit(onSubmit)}
            disabled={editAllowed}
          >
            {Partner_labels.saveChanges}
          </Button>
        </Box>
      </Box>

      <Box display={"flex"} flexDirection={"column"} gap={2} maxWidth={572}>
        <StyledFormTitle>
          {transport_labels.transportDetailsTitleText}
        </StyledFormTitle>
        <Divider sx={{ mb: "4px" }} />
        <Grid container spacing={"28px"}>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6}>
              <TextInput
                control={detailControl}
                name={transportFormKeys.transportName}
                label={transport_labels.transportNameLabelText}
                placeholder={transport_labels.transportNamePlaceholderText}
                disabled={isFormDisabled}
              />
            </Grid>
            <Grid item xs={6}>
              <DateInput
                control={detailControl}
                name={transportFormKeys.establishedYear}
                label={transport_labels.establishedYearLabelText}
                placeholder={transport_labels.establishedYearPlaceholderText}
                disabled={isFormDisabled}
                showYearPicker={true}
                minDate="1900-01-01"
                format="yyyy"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container spacing={"12px"}>
            <Grid item xs={12}>
              <AutoCompleteMapField
                control={detailControl}
                name={transportFormKeys.transportLocation}
                label={transport_labels.mapLocationLabelText}
                placeholder={transport_labels.mapLocationPlaceholderText}
                reset={(address, location) => {
                  setValue(transportFormKeys.transportLocation, address);
                  setValue(transportFormKeys.lat, location.lat);
                  setValue(transportFormKeys.lng, location.lng);
                }}
                disabled={isFormDisabled}
              />
            </Grid>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={4}>
                <TextInput
                  control={detailControl}
                  name={transportFormKeys.transportCity}
                  placeholder={transport_labels.cityPlaceholderText}
                  disabled={isFormDisabled}
                />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  control={detailControl}
                  name={transportFormKeys.transportState}
                  placeholder={transport_labels.statePlaceholderText}
                  disabled={isFormDisabled}
                />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  control={detailControl}
                  name={transportFormKeys.transportZipCode}
                  placeholder={transport_labels.zipCodePlaceholderText}
                  disabled={isFormDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    "& label > span > span, & label > span > span::before": {
                      height: {
                        md: 16,
                      },
                      width: { md: 16 },
                    },
                    "& label > span:last-child": {
                      fontSize: { md: 12 },
                      lineHeight: "16px",
                    },
                  }}
                >
                  <CheckBoxGroup
                    control={detailControl}
                    name={transportFormKeys.useSameBillingAddress}
                    options={[
                      {
                        name: "yes",
                        displayName:
                          transport_labels.sameBillingAddressLabelText,
                      },
                    ]}
                    setValue={setValue}
                    defaultValues={
                      props?.partner?.billingAddress?.sameAsAddress
                        ? ["yes"]
                        : []
                    }
                    disabled={isFormDisabled}
                    errors={errors}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              control={detailControl}
              name={transportFormKeys.billingAddress}
              label={transport_labels.billingAddressLabelText}
              placeholder={transport_labels.billingAddressPlaceholderText}
              disabled={isFormDisabled || isSameBillingAddress?.[0] === "yes"}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              control={detailControl}
              name={transportFormKeys.gstInNumber}
              label={transport_labels.gstinLabelText}
              placeholder={transport_labels.gstinPlaceholderText}
              disabled={isFormDisabled}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid
              container
              spacing={2}
              sx={{
                paddingLeft: {
                  xs: 0,
                  md: 1,
                },
              }}
            >
              <Grid item xs={12}>
                <Typography fontSize={14} lineHeight={"20px"}>
                  {transport_labels.gstRegimeLabelText}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  "& .MuiFormControlLabel-root": { marginLeft: "auto" },
                }}
              >
                <RadioButtonGroup
                  control={detailControl}
                  name={transportFormKeys.gstRegime}
                  options={regimeRadioGroup}
                  disabled={isFormDisabled}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextArea
              control={detailControl}
              name={transportFormKeys.transportDescription}
              label={transport_labels.transportDescriptionLabelText}
              placeholder={transport_labels.transportDescriptionPlaceholderText}
              disabled={isFormDisabled}
            />
          </Grid>
        </Grid>
      </Box>

      <AddVehicleForm {...props} isFormDisabled={isFormDisabled} />

      <Box display={"flex"} flexDirection={"column"} gap={2} maxWidth={572}>
        <Grid container spacing={"28px"}>
          {truckDetails.map((item, index) => (
            <Grid item xs={12} key={item.truckModel}>
              <TruckDetailCard
                imageSrc={item.truckImage}
                pricePerKm={item.pricePerKm}
                truckMake={item.truckMake}
                truckModel={`${item.truckMakeDisplayName} | ${item.truckModelDisplayName}`}
                truckNumber={item.truckNumber}
                truckWeight={`${item.truckWeight} Ton` ?? ""}
                disabled={isFormDisabled}
                vehicleId={item.vehicleId}
                onEdit={() => {
                  showDialog({
                    component: (
                      <UpdateTruckDetailsForm
                        {...item}
                        partnerId={props?.partner?._id}
                        refetch={props.refetch}
                      />
                    ),
                  });
                }}
                onDelete={() => {
                  onDeleteVehicle(item.vehicleId);
                }}
              />
            </Grid>
          ))}
          {truckDetails.length < 1 && (
            <Grid item xs={12}>
              <FormHelperText error>Please add at least 1 truck</FormHelperText>
            </Grid>
          )}
          <Grid item xs={12}>
            <StyledFormTitle>
              {transport_labels.additionalServicesTitleText}
            </StyledFormTitle>
          </Grid>
          <Grid item xs={12}>
            <CheckBoxGroup
              control={detailControl}
              name={transportFormKeys.additionalServices}
              errors={errors}
              options={formData?.additionalServices}
              setValue={setValue}
              onRemove={(value, index) => removeAdditionalService(index)}
              disabled={isFormDisabled}
              defaultValues={additionalServicesWatch}
            />
          </Grid>
          {additionalServicesFields.length > 0 && (
            <Grid item xs={12}>
              <StyledBoxContainer>
                <Grid container spacing={"12px"}>
                  {additionalServicesFields.map((item, index) => (
                    <Grid item xs={12} md={4} key={item._id}>
                      <TextInput
                        control={detailControl}
                        name={`${transportFormKeys.additionalServicesFields}.${index}.${item.name}`}
                        label={`${item.displayName} (per ${item.rateUnit})`}
                        placeholder={`Enter in per ${item.rateUnit}`}
                        type="number"
                        disabled={isFormDisabled}
                      />
                    </Grid>
                  ))}
                </Grid>
              </StyledBoxContainer>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
