import { queryKeys } from "../../constants/queryKeys";
import { fetchOrderStatusFormData } from "../../admin/action";
import { useQuery } from "@tanstack/react-query";

export const useFetchUpdateOrderStatusFormData = (orderId) => {
  const orderStatusFormData = useQuery(
    [queryKeys.queryGetWarehouseFormData, orderId],
    () => fetchOrderStatusFormData(orderId),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(orderId)
    }
  );
  return orderStatusFormData;
};
