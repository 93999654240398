import * as yup from "yup";

import { warehouseFormKeys } from "../../constants/formKeys";

import { warehouse_labels } from "../../constants";

export const dynamicFormShape = yup.lazy((value) => {
  const shapes = {};
  const DATA_OBJ_KEYS = Object.keys(value);
  DATA_OBJ_KEYS.forEach((parameter) => {
    shapes[parameter] = yup
      .string()
      .required(`${parameter} ${warehouse_labels.isRequired}`);
  });
  return yup.object().shape(shapes);
});

export const warehouseFormSchema = yup.object({
  [warehouseFormKeys.warehouseName]: yup
    .string()
    .required(
      `${warehouse_labels.warehouseNameLabelText} ${warehouse_labels.isRequired}`
    ),
  [warehouseFormKeys.establishedYear]: yup
    .string()
    .required(
      `${warehouse_labels.establishedYearLabelText} ${warehouse_labels.isRequired}`
    ),
  [warehouseFormKeys.gstInNumber]: yup
    .string()
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      "Enter a correct GST number"
    )
    .required(
      `${warehouse_labels.gstinLabelText} ${warehouse_labels.isRequired}`
    ),
  [warehouseFormKeys.warehouseDescription]: yup
    .string()
    .required(
      `${warehouse_labels.warehouseDescriptionLabelText} ${warehouse_labels.isRequired}`
    ),
  [warehouseFormKeys.warehouseLocation]: yup
    .string()
    .required(
      `${warehouse_labels.mapLocationLabelText} ${warehouse_labels.isRequired}`
    ),
  [warehouseFormKeys.warehouseCity]: yup
    .string()
    .required(
      `${warehouse_labels.cityPlaceholderText} ${warehouse_labels.isRequired}`
    ),
  [warehouseFormKeys.warehouseState]: yup
    .string()
    .required(
      `${warehouse_labels.statePlaceholderText} ${warehouse_labels.isRequired}`
    ),
  [warehouseFormKeys.warehouseZipCode]: yup
    .string()
    .required(
      `${warehouse_labels.zipCodePlaceholderText} ${warehouse_labels.isRequired}`
    ),
  [warehouseFormKeys.billingAddress]: yup
    .string()
    .required(
      `${warehouse_labels.billingAddressLabelText} ${warehouse_labels.isRequired}`
    ),
  [warehouseFormKeys.totalStorageSpace]: yup
    .string()
    .required(
      `${warehouse_labels.storageSpaceLabelText} ${warehouse_labels.isRequired}`
    ),
  [warehouseFormKeys.availableSpace]: yup
    .string()
    .required(
      `${warehouse_labels.availableSpaceLabelText} ${warehouse_labels.isRequired}`
    ),
  [warehouseFormKeys.storageTypeAllowed]: yup
    .array()
    .of(yup.string())
    .min(1, "Please select at least 1 storage type")
    .required(
      `${warehouse_labels.storageTypeAllowedLabelText} ${warehouse_labels.isRequired}`
    ),
  [warehouseFormKeys.materialDetails]: yup
    .array()
    .of(yup.string())
    .min(1, "Please select at least 1 material")
    .required(
      `${warehouse_labels.materialDetailsLabelText} ${warehouse_labels.isRequired}`
    ),
  [warehouseFormKeys.additionalServices]: yup
    .array()
    .of(yup.string())
    .min(1, "Please select at least 1 service")
    .required(
      `${warehouse_labels.additionalServicesTitleText} ${warehouse_labels.isRequired}`
    ),
  [warehouseFormKeys.additionalServicesFields]: yup
    .array()
    .of(dynamicFormShape),
  [warehouseFormKeys.storageTypes]: yup.array().of(dynamicFormShape),

  // [warehouseFormKeys.features]: yup
  //   .array()
  //   .of(dynamicFormShapeFeatures)
  //   .min(1),
});
