import { fetchVehicleFormData } from "../../admin/action";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../constants/queryKeys";

export const useFetchVehicleFormData = (partnerId) => {
  const vehicleFormDataQuery = useQuery(
    [queryKeys.queryGetVehicleFormData],
    ()=> fetchVehicleFormData(partnerId),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(partnerId)
    }
  );
  return vehicleFormDataQuery;
};
