import { queryKeys } from "../../constants/queryKeys";
import { getInquiriesByRfqId } from "../../admin/action";
import { useQuery } from "@tanstack/react-query";

export const useFetchInquiryByRfqIdQueryHook = (inquiryId) => {
  const partnersInquiriesByRfqId = useQuery(
    [queryKeys.queryFetchInquiryById, inquiryId],
    () => getInquiriesByRfqId(inquiryId),
    {
      keepPreviousData: true,
    }
  );
  return partnersInquiriesByRfqId;
};
