import { fetchAddPartnerFormData } from "../../admin/action";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../constants/queryKeys";
export const useFetchAddPartnerFormDataQuery = (isEnabled = true) => {
  const addPartnerFormDataQuery = useQuery(
    [queryKeys.queryFetchFormData],
    () => fetchAddPartnerFormData(),
    {
      initialData: {
        city: [],
        partnerTypes: [],
        accountTypes: [],
        splitEnabled: [],
      },
      refetchOnWindowFocus: false,
      enabled: Boolean(isEnabled),
    }
  );
  return addPartnerFormDataQuery;
};
