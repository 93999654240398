import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  Typography,
  useTheme
} from "@mui/material";
import { CloseOutlined, RoomOutlined } from "@mui/icons-material";
import { useEffect, useMemo, useRef, useState } from "react";

import { Controller } from "react-hook-form";
import { GMapLocationConfirm } from "./GMapLocationConfirm";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { debounce } from "@mui/material/utils";
import { isMobileDevice } from "../../App";
import parse from "autosuggest-highlight/parse";
import { useCustomDialog } from "../customDialog";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const AutoCompleteMapField = ({
  readOnly = false,
  label,
  placeholder,
  helperText,
  name,
  control,
  disabled = false,
  reset
}) => {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const loaded = useRef(false);
  const theme = useTheme();
  const { showDialog, hideDialog } = useCustomDialog();

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService?.current?.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService?.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService?.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [fetch, inputValue, value]);

  const onClickMapIcon = (optionValue) => {
    showDialog({
      component: (
        <GMapLocationConfirm
          resetParentAutoComplete={(value, center) => {
            reset(value, center);
            hideDialog();
          }}
          searchedValue={optionValue}
        />
      )
    });
  };

  const handleCustomOptionItem = (props, option) => {
    const optionItemLength = isMobileDevice ? 10 : 60;
    const matches =
      option?.structured_formatting?.main_text_matched_substrings || [];

    const parts = parse(
      option?.structured_formatting?.main_text,
      matches.map((match) => [match.offset, match.offset + match.length])
    );

    return (
      <li {...props}>
        <Grid container>
          <Grid item xs={10}>
            {parts.map((part, index) => (
              <Box
                key={index + option.place_id}
                component="span"
                sx={{
                  color: part.highlight
                    ? theme.palette.text.primary
                    : theme.palette.text.secondary
                }}
              >
                {`${part.text}`}
              </Box>
            ))}

            <Typography
              variant="caption"
              color="text.secondary"
              fontSize="14px"
            >
              {option?.structured_formatting?.secondary_text?.length >
              optionItemLength
                ? option?.structured_formatting?.secondary_text?.slice(
                    0,
                    optionItemLength
                  ) + "...."
                : option?.structured_formatting?.secondary_text}
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Divider
              orientation="vertical"
              sx={{
                maxHeight: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                alignSelf: "center"
              }}
            />
            <Typography
              variant="caption"
              color="primary"
              fontSize="14px"
              fontWeight={500}
              marginLeft={"12px"}
              onClick={() =>
                onClickMapIcon(
                  option &&
                    option?.structured_formatting?.main_text +
                      "," +
                      option?.structured_formatting?.secondary_text
                )
              }
            >
              map
            </Typography>
          </Grid>
        </Grid>
      </li>
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={""}
      render={({ field, fieldState: { error } }) => (
        <FormControl variant="standard" fullWidth disabled={disabled}>
          {label && (
            <InputLabel shrink htmlFor={name}>
              {label}
            </InputLabel>
          )}
          <Autocomplete
            id={name}
            readOnly={readOnly}
            options={options}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option?.description
            }
            filterOptions={(x) => x}
            autoComplete
            noOptionsText="No locations"
            filterSelectedOptions
            includeInputInList
            renderInput={(params) => {
              const { InputLabelProps, InputProps, ...rest } = params;
              return (
                <InputBase
                  {...params.InputProps}
                  {...rest}
                  placeholder={placeholder}
                  error={!!error}
                  startAdornment={
                    readOnly && (
                      <RoomOutlined sx={{ ml: 1, color: "#607088" }} />
                    )
                  }
                  endAdornment={
                    readOnly ? (
                      <IconButton
                        disableRipple
                        disabled={disabled}
                        onClick={() => onClickMapIcon()}
                      >
                        <KeyboardArrowDownOutlinedIcon />
                      </IconButton>
                    ) : field.value || params?.inputProps["aria-expanded"] ? (
                      <IconButton
                        disableRipple
                        disabled={disabled}
                        onClick={() => field.onChange("")}
                      >
                        <CloseOutlined />
                      </IconButton>
                    ) : (
                      <IconButton
                        disableRipple
                        disabled={disabled}
                        onClick={() => onClickMapIcon()}
                      >
                        <RoomOutlined />
                      </IconButton>
                    )
                  }
                />
              );
            }}
            onChange={(event, item) => {
              const geocoder = new window.google.maps.Geocoder();
              setOptions(item ? [item, ...options] : options);
              setValue(item);
              field.onChange(item.description);
              geocoder.geocode(
                { address: item.description },
                (results, status) => {
                  const location = results[0].geometry.location;
                  reset(item.description, {
                    lat: location.lat(),
                    lng: location.lng()
                  });
                }
              );
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            value={field.value}
            disableClearable
            renderOption={handleCustomOptionItem}
            disabled={disabled}
          />
          {helperText && (
            <FormHelperText sx={{ letterSpacing: "0.1em" }}>
              {helperText}
            </FormHelperText>
          )}
          {error && <FormHelperText error>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default AutoCompleteMapField;
