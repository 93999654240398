import { getCustomerById } from "../../admin/action";
import { queryKeys } from "../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchCustomerById = (customerId) => {
  const customerByIdQuery = useQuery(
    [queryKeys.queryFetchCustomerById, customerId],
    () => getCustomerById(customerId),
    {
      enabled: Boolean(customerId),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return customerByIdQuery;
};
