import * as yup from "yup";

import { TruckFormKeys, transportFormKeys } from "../../constants/formKeys";
import { transport_labels, warehouse_labels } from "../../constants";

import { dynamicFormShape } from "./WarehouseFormValidation";

export const transportFormSchema = yup.object({
  [transportFormKeys.transportName]: yup
    .string()
    .required(
      `${transport_labels.transportNameLabelText} ${warehouse_labels.isRequired}`
    ),
  [transportFormKeys.establishedYear]: yup
    .string()
    .required(
      `${transport_labels.establishedYearLabelText} ${warehouse_labels.isRequired}`
    ),
  [transportFormKeys.gstInNumber]: yup
    .string()
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      "Enter a correct GST number"
    )
    .required(
      `${transport_labels.gstinLabelText} ${warehouse_labels.isRequired}`
    ),
  [transportFormKeys.gstRegime]: yup
    .string()
    .required(
      `${transport_labels.gstRegimeLabelText} ${warehouse_labels.isRequired}`
    ),
  [transportFormKeys.transportDescription]: yup
    .string()
    .required(
      `${transport_labels.transportDescriptionLabelText} ${warehouse_labels.isRequired}`
    ),
  [transportFormKeys.transportLocation]: yup
    .string()
    .required(
      `${transport_labels.mapLocationLabelText} ${warehouse_labels.isRequired}`
    ),
  [transportFormKeys.transportCity]: yup
    .string()
    .required(
      `${transport_labels.cityPlaceholderText} ${warehouse_labels.isRequired}`
    ),
  [transportFormKeys.transportState]: yup
    .string()
    .required(
      `${transport_labels.statePlaceholderText} ${warehouse_labels.isRequired}`
    ),
  [transportFormKeys.transportZipCode]: yup
    .string()
    .required(
      `${transport_labels.zipCodePlaceholderText} ${warehouse_labels.isRequired}`
    ),
  [transportFormKeys.billingAddress]: yup
    .string()
    .required(
      `${transport_labels.billingAddressLabelText} ${warehouse_labels.isRequired}`
    ),

  [transportFormKeys.additionalServices]: yup
    .array()
    .of(yup.string())
    .min(1, "Please select at least 1 service")
    .required(
      `${transport_labels.additionalServicesTitleText} ${warehouse_labels.isRequired}`
    ),
  [transportFormKeys.additionalServicesFields]: yup
    .array()
    .of(dynamicFormShape),
});

const validFileExtensions = {
  image: ["jpg", "png", "jpeg"],
};

function isValidFileType(fileName, fileType) {
  return (
    fileName &&
    validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
  );
}

export const vehicleFormSchema = yup.object({
  [TruckFormKeys.truckNumber]: yup
    .string()
    .required(
      `${transport_labels.truckNumberLabelText} ${warehouse_labels.isRequired}`
    ),
  [TruckFormKeys.truckMake]: yup
    .string()
    .required(
      `${transport_labels.truckMakeLabelText} ${warehouse_labels.isRequired}`
    ),
  [TruckFormKeys.truckModel]: yup
    .string()
    .required(
      `${transport_labels.truckModelLabelText} ${warehouse_labels.isRequired}`
    ),
  [TruckFormKeys.pricePerKm]: yup
    .string()
    .required(
      `${transport_labels.pricePerKmLabelText} ${warehouse_labels.isRequired}`
    ),
  [TruckFormKeys.truckImage]: yup
  .mixed()
  .required("add a photo")
  .test("is-valid-type", "Please select a valid image", (value) =>
    isValidFileType(value && value.name.toLowerCase(), "image")
  )
});
export const updateVehicleFormSchema = yup.object({
  [TruckFormKeys.truckNumber]: yup
    .string()
    .required(
      `${transport_labels.truckNumberLabelText} ${warehouse_labels.isRequired}`
    ),
  [TruckFormKeys.truckMake]: yup
    .string()
    .required(
      `${transport_labels.truckMakeLabelText} ${warehouse_labels.isRequired}`
    ),
  [TruckFormKeys.truckModel]: yup
    .string()
    .required(
      `${transport_labels.truckModelLabelText} ${warehouse_labels.isRequired}`
    ),
  [TruckFormKeys.pricePerKm]: yup
    .string()
    .required(
      `${transport_labels.pricePerKmLabelText} ${warehouse_labels.isRequired}`
    )
});
