import { Box, Grid, Typography } from "@mui/material";
import { appConstants, labels } from "../../constants";
import { currencyFormatter, dateTimeFormatter } from "../../shared/utils";

import AppLogo from "../../assets/Warex.svg";
import { ToWords } from "to-words";
import styled from "@emotion/styled";

export const StyleBorderedGrid = styled((props) => (
  <Grid
    {...props}
    sx={{
      background: {
        md: "#FFFFFF",
        xs: props?.secondaryxsbg ? "#EFF2F6" : "#FFFFFF",
      },
      border: "0.4px solid #95A1B0",
      borderRadius: "8px",
    }}
  />
))(({ theme }) => ({}));

const address = {
  firstName: "Conhex Technologies",
  lastName: " Private Limited",
  firmsName: "101, Ankita Residency",
  gstin: "36AAKCC7816R1ZU",
  address: "Road 2, Shilpa Layout",
  city: "Kondapur",
  country: "Hyderabad",
  zipCode: "500084",
};

const clientAddres = {
  firstName: "Warehouse ",
  lastName: "Name",
  firmsName: "Razorpay India Pvt Ltd ",
  gstin: "29AAGCR4375J1ZU",
  address: "SJR Cyber Laskar, Hosur Rd",
  city: "Bengaluru",
  country: "Karnataka",
  zipCode: "560030",
};

const HeaderTypography = styled((props) => <Typography {...props} />)(
  ({ theme }) => ({
    color: theme.palette.primary.dark,
    fontSize: 9,
    fontWeight: 600,
    lineHeight: "11px",
    whiteSpace: "initial",
    [theme.breakpoints.up("lg")]: { fontSize: 16, lineHeight: "20px" },
  })
);

const TableHeaderTypography = styled((props) => (
  <Typography
    {...props}
    textAlign={props?.textAlign ? props.textAlign : "left"}
  />
))(({ theme }) => ({
  color: theme.palette.text.highlited,
  padding: 12,
  fontSize: 16,
  fontWeight: 600,
  lineHeight: "19px",
  whiteSpace: "inherit",
  paddingLeft: "22px",
}));

const TableDataTypography = styled((props) => (
  <Typography
    {...props}
    textAlign={props?.textAlign ? props.textAlign : "left"}
  />
))(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: 10,
  fontSize: 7,
  fontWeight: 400,
  lineHeight: "11px",
  whiteSpace: "initial",
  [theme.breakpoints.up("lg")]: { fontSize: 14, lineHeight: "20px" },
  "&:first-child": {
    [theme.breakpoints.up("xs")]: {
      paddingLeft: 16,
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 24,
    },
  },
}));

const TableDataBoldTypography = styled((props) => (
  <Typography
    {...props}
    textAlign={props?.textAlign ? props.textAlign : "left"}
  />
))(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: 10,
  fontSize: 13,
  fontWeight: 700,
  lineHeight: "18px",
  whiteSpace: "initial",
  [theme.breakpoints.up("lg")]: { fontSize: 13, lineHeight: "18px" },
  "&:first-child": {
    [theme.breakpoints.up("xs")]: {
      paddingLeft: 16,
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 24,
    },
  },
}));

const AddressDateCard = ({
  isDateCard = false,
  header = "",
  invoiceNo = " 123453 ",
  invoiceDate = "",
  RefOrderId = "ID 12345",
  firstName = "",
  lastName = "",
  firmsName = "",
  streetAddress = "",
  city = "",
  country = "",
  zipCode = "",
  gstin = "",
}) => {
  return (
    <Box>
      {isDateCard ? (
        <Typography
          component="div"
          color={"text.primary"}
          sx={{
            fontSize: 14,
            fontWeight: 600,
            lineHeight: "27px",
            whiteSpace: "initial",
          }}
        >
          <Box>{labels.invoiceNo + "" + invoiceNo}</Box>
          <Box>
            {invoiceDate ? (
              <>
                {labels.invoiceDate +
                  ": " +
                  dateTimeFormatter(
                    invoiceDate,
                    appConstants.invoiceDateFormat
                  )}
              </>
            ) : (
              ""
            )}
          </Box>
          <Box>{labels.RefOrderIdLabelText + " : " + RefOrderId}</Box>
        </Typography>
      ) : (
        <>
          <HeaderTypography component="div">{header}</HeaderTypography>
          <Typography
            component="div"
            color={"text.primary"}
            pt={1}
            sx={{
              fontSize: 14,
              fontWeight: 600,
              lineHeight: "20px",
              whiteSpace: "nowrap",
            }}
          >
            {firstName + " " + lastName}
          </Typography>
          <Typography
            component="div"
            color={"text.primary"}
            py={1}
            sx={{
              fontSize: 13,
              fontWeight: 400,
              lineHeight: "18px",
              whiteSpace: "initial",
            }}
          >
            {firmsName}
            <br />
            {streetAddress} <br />
            {city} {country} - {zipCode}
            <br />
            <br />
            {gstin && labels.gstinLabelText + ": " + gstin}
          </Typography>
        </>
      )}
    </Box>
  );
};

const DetailsTable = ({totalAmount}) => {
  const toWords = new ToWords();
  return (
    <Box>
      {/* header Grid */}
      <Grid container>
        <Grid item xs={2}>
          <TableHeaderTypography>Item No.</TableHeaderTypography>
        </Grid>
        <Grid item xs={8}>
          <TableHeaderTypography>Item Description.</TableHeaderTypography>
        </Grid>
        <Grid item xs={2}>
          <TableHeaderTypography>Amount</TableHeaderTypography>
        </Grid>
      </Grid>
      {/* Data Grid */}
      <Grid container>
        {/* Commission Fees */}
        <Grid container>
          <Grid item xs={2}>
            <TableDataTypography textAlign="start">{1}</TableDataTypography>
          </Grid>
          <Grid item xs={2.5}>
            <TableDataTypography>
              Fees under Marketing and Consulting Services HSN: 9965
              <br />
              <b>Commission Fees</b>
            </TableDataTypography>
          </Grid>
          <Grid item xs={5.5}></Grid>
          <Grid item xs={2} mt={7}>
            <TableDataBoldTypography>
              {currencyFormatter(totalAmount)}
            </TableDataBoldTypography>
          </Grid>
        </Grid>
        {/* SGST */}
        <Grid container mt={4}>
          <Grid item xs={2}>
            <TableDataTypography textAlign="start">{2}</TableDataTypography>
          </Grid>
          <Grid item xs={8}>
            <TableDataBoldTypography>
              {`${labels.sgst} @ ${appConstants.sgst}%`}
            </TableDataBoldTypography>
          </Grid>
          <Grid item xs={2}>
            <TableDataBoldTypography>
              {currencyFormatter(((+totalAmount / 100) * +appConstants.sgst).toFixed(2))}
            </TableDataBoldTypography>
          </Grid>
        </Grid>
        {/* CGST */}
        <Grid container>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <TableDataBoldTypography>
              {`${labels.cgst} @ ${appConstants.cgst}%`}
            </TableDataBoldTypography>
          </Grid>
          <Grid item xs={2}>
            <TableDataBoldTypography>
              {currencyFormatter(((+totalAmount / 100) * +appConstants.cgst).toFixed(2))}
            </TableDataBoldTypography>
          </Grid>
        </Grid>
        {/* Total Amount */}
        <Grid container mt={1.5}>
          <Grid item xs={2}>
            <TableDataTypography textAlign="start">{3}</TableDataTypography>
          </Grid>
          <Grid item xs={8}>
            <TableDataBoldTypography>
              {labels.totalAmountText}
            </TableDataBoldTypography>
          </Grid>
          <Grid item xs={2}>
            <TableDataBoldTypography>
              {currencyFormatter(
                ((+totalAmount / 100) * +appConstants.sgst +
                  (+totalAmount / 100) * +appConstants.cgst +
                  +totalAmount).toFixed(2)
              )}
            </TableDataBoldTypography>
          </Grid>
        </Grid>
        {/* Amount in Words */}
        <Grid container mt={2}>
          <Grid item xs={2}>
            <TableDataTypography textAlign="start">
              {`${labels.inWord} : `}
            </TableDataTypography>
          </Grid>
          <Grid item xs={6}>
            <TableDataTypography textAlign="start">
              {toWords.convert(
                (+totalAmount / 100) * +appConstants.sgst +
                  (+totalAmount / 100) * +appConstants.cgst +
                  +totalAmount,
                { currency: true }
              )}
            </TableDataTypography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const InvoiceDetailsPdf = ({ customerInfo }) => {
  const Invoice = (
    <Grid item xs={12}>
      <StyleBorderedGrid container>
        <Grid item xs={12}>
          <Grid
            container
            sx={{
              backgroundImage: `url(${AppLogo}) `,
              backgroundRepeat: "no-repeat ",
              backgroundPosition: "top",
              backgroundPositionY: "33px",
              backgroundSize: "150px",
              padding: "33px 47px 23px 75px",
            }}
          >
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: "20px",
                  lineHeight: "24px",
                  fontWeight: "600",
                  color: "#1B51B3",
                }}
              >
                Commission Invoice
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign={"end"} gap={10}>
              <AddressDateCard isDateCard={true} invoiceDate={"12/12/2023"} />
            </Grid>
            <Grid item xs={4} mt={4}>
              <AddressDateCard
                header={labels.buyer}
                firstName={clientAddres.firstName}
                lastName={clientAddres.lastName}
                firmsName={clientAddres.firmsName}
                streetAddress={clientAddres.address}
                city={clientAddres.city}
                country={clientAddres.country}
                zipCode={clientAddres.zipCode}
                gstin={clientAddres.gstin}
              />
            </Grid>
            <Grid item xs={5} mt={4}></Grid>
            <Grid item xs={3} mt={4} alignContent={"end"}>
              <AddressDateCard
                header={labels.serviceProvider}
                firstName={address.firstName}
                lastName={address.lastName}
                firmsName={address.firmsName}
                streetAddress={address.address}
                city={address.city}
                country={address.country}
                zipCode={address.zipCode}
                gstin={address.gstin}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={5} sx={{ padding: "0px 47px 23px 75px" }}>
          <DetailsTable totalAmount={13559.32}/>
        </Grid>
      </StyleBorderedGrid>
    </Grid>
  );
  return <Box>{Invoice}</Box>;
};

export default InvoiceDetailsPdf;
