export const labels = {
  appTitle: "Warex",
  warehouse: "Warehouse",
  transporter: "Transporter",
  transport: "Transport",
  proceed: "Proceed",
  continue: "Continue",
  clear: "Clear",
  notifications: "Notification",
  demo_string:
    "amet, consetetur sadipscing elitr, sed diam nonumy eirmod temporinvidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duodolores et ea rebum. Stet clita kasdgubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet",
  monthlyRentalText: "Monthly rental",
  addonFeesText: "Addon fees",
  totalAmountText: "Total amount",
  transportServiceNeededLabelText: "Do you need a transport service?",
  pickupLocationLabelText: "Pickup location",
  pickupLocationPlaceholderText: "Select Pickup location from maps",
  destinationLabelText: "Destination",
  destinationPlaceholderText:
    "Select desired area of the warehouse within the city",
  inboundDateLabelText: "Inbound date",
  datePlaceholderText: "Enter date",
  fromDate: "From Date",
  toDate: "To Date",
  outboundDateLabelText: "Outbound date",
  materialDetailsLabelText: "Material details",
  materialDetailsPlaceholderText: "Select material",
  sortByPlaceholderText: "Select sort",
  packagingTypeLabelText: "Packaging type",
  packagingPlaceholderText: "Select packaging",
  itemDescriptionLabelText: "Item description",
  itemDescriptionPlaceholderText: "Add item description",
  dimensionPlaceholderText: "Select dimension",
  dimensionLabelText: "Package dimensions",
  noOfPackagesLabelText: "No. of Packages",
  totalWeightOfMaterialLabelText: "Total Weight of material",
  totalWeightOfMaterialPlaceholderText: "Enter weight in Tons (1Ton=1000KGs)",
  noOfPackagesPlaceholderText: "Enter no of packages",
  spaceTypePlaceholderText: "Select storage type",
  spaceTypeLabelText: "Storage type",
  spaceOrPalletsAccommodated: "Space or Pallets to be accommodated?",
  spaceOrPalletsAccommodatedPlaceholderText:
    "Enter Area in Sft or No. of Pallets",
  additionalServicesLabelText: "Additional services",
  showListingsButtonText: "Show listings",
  findTrucksButtonText: "Find Trucks",
  sendRFQButtonPluralLabelText: "Send RFQs",
  sendRFQButtonSingleLabelText: "Send RFQ",
  bookOrderButtonLabelText: "Book Order",
  moveMapToAdjustLocation: "Move map to adjust location",
  confirm: "Confirm",
  enterMobileNumber: "+91 | Enter mobile number",
  verifyWithOTP: "Verify with OTP",
  sentTo: "Sent to",
  resentOtpIn: "Resend OTP",
  havingTroubleLoggingIn: " Having trouble logging in?",
  getHelp: "Get Help",
  byContinueIAgree: "By continuing, I agree to the",
  termsOfUse: "Terms of Use",
  privacyPolicy: "Privacy Policy",
  login: "Login",
  signup: "Signup",
  storageDetailsTitleText: "Storage details",
  yearEstablishedLabelText: "Year established",
  totalStorageSpaceLabelText: "Total storage space",
  availableSpaceLabelText: "Available space",
  storageTypesAndPricingTitleText: "Storage types & pricing",
  squareFeetLabelText: "Square feet",
  palletLabelText: "Pallet",
  boxesLabelText: "Boxes",
  containerLabelText: "Container",
  additionalDetailsTitleText: "Additional details",
  moreSearchTitleText: "More warehouse from your search",
  pricePerSqftLabelText: "Price / Sq ft",
  spaceRequiredLabelText: "Space required",
  handlingChargesLabelText: "Handling charges",
  transportedFeeLabelText: "Transporter fee",
  servicesAvailableLabelText: "Services available",
  approvedProductsLabelText: "Approved products",
  bookNowButtonText: "Book now",
  pickupDateLabelText: "Pickup date",
  requestForQuotes: "Request For Quotes",
  viewDetail: "View detail",
  setYourPriceExpectation: "Set your price expectation",
  pricePerKmLabelText: "Price /Km",
  addToCartButtonLabelText: "Add to cart",
  continueWithListing: "Continue with listing",
  goToInquiry: "Go to Inquiry",
  Cart: "Cart",
  add: "Add",
  continueWithoutTransporter: "Continue without Transporter",
  continueWithoutWarehouse: "Continue without Warehouse",
  sortBy: "Sort by",
  sendRFQ: "Send RFQ",
  back: "Back",
  next: "Next",
  quantityPallets: "Quantity (Pallets)",
  partnerDetails: "Partner details",
  dateRangePlaceholderText: "Select date range",
  apply: "Apply",
  materialTypeLabel: "Material Type",
  packagingLabelText: "Packaging",
  dimensionAndUnits: "Dimensions & Units",
  spaceType: "Space type",
  areaInSqft: "Area in Sqft",
  productDetails: "Product details",
  pickupTimeApproxLabelText: "Pickup time (Approx)",
  timePlaceholderText: "Select time",
  billingDetails: "Billing details",
  firstNameLabelText: "First Name",
  lastNameLabelText: "Last Name",
  firmsNameLabelText: "Firms Name",
  firmsEmailLabelText: "Firms Email",
  gstinLabelText: "GSTIN",
  contactLabelText: "Contact",
  billingAddressLabelText: "Billing Address",
  cityPlaceholderText: "City",
  countryPlaceholderText: "Country",
  zipCodePlaceholderText: "Zip Code",
  firstNamePlaceholderText: "First name",
  lastNamePlaceholderText: "Last name",
  firmsNamePlaceholderText: "Firms name",
  firmsEmailPlaceholderText: "Enter email ID",
  gstinPlaceholderText: "Enter GSTIN number",
  contactPlaceholderText: "+91 XXXX XX XXXX",
  billingAddressPlaceholderText: "Street Address",
  ratePerUnits: "Rate / per units",
  proceedToPay: "Proceed to pay",
  storageOrderForm: "Storage Order Form",
  nameLabelText: "First & Last name",
  numberLabelText: "Mobile number",
  emailLabelText: "Email ID",
  subjectLabelText: "Subject",
  descriptionLabelText: "Description",
  namePlaceholderText: "Enter your Name",
  numberPlaceholderText: "Enter your Number",
  emailPlaceholderText: "Enter your Email ID",
  subjectPlaceholderText: "Select your reason",
  descriptionPlaceholderText: "Enter the description",
  savedAddress: "Saved address",
  useThisAddress: "Use this address",
  orderNo: "Order no",
  invoiceNo: "Invoice No.",
  invoiceDate: "Invoice Date",
  RefOrderIdLabelText: "Ref Order ID",
  orderDate: "Order date",
  sgst: "SGST",
  cgst: "CGST",
  inWord: "Amount In Word",
  total: "Total",
  customerOrBuyer: "Customer / Buyer",
  buyer: "Buyer",
  storageFacility: "Storage Facility",
  serviceProvider: "Service provider",
  acceptAll: "Accept all ",
  tnc: "Terms & Conditions",
  completeYourOrder: "Complete Your Order",
  paymentMethod: "Payment method",
  orderSummary: "Order summary",
  gst: "GST",
  grandTotal: "Grand total",
  shippingDate: "Shipping date",
  notificationMsgCount: "You have $COUNT new notification",
  markAllAsRead: "Mark all as read",
  notificationMsgTemplate: " has responded on your $ACTION, dated $DATE. ",
  otherReasonPlaceholderText: "Mention the reason",
  chooseAppropriateStatus: "Choose Appropriate Status",
  partnerListingDetails: "Partner Listing Details"
};

export const error_msg = {
  required: "Field is required",
  onlyDigits: "Must be only digits",
  enterLeast10Digits: "Must be at least 10 digits",
  enterOnly10Digits: "Cannot exceed 10 digits",
  enterMobileNumber: "Mobile number is required",
  enterOtp:
    "Please enter the OTP sent to your registered mobile number to verify your account.",
  errorSigningMobile: "Error signing in mobile number",
  errorSigningOTP: "Error signing with OTP",
  acceptTnC: "Please accept the terms and conditions.",
  selectReason: "Please select a reason to cancel/close"
};

export const DUMMY_ITEMS = [
  {
    label: "Yes",
    value: "yes"
  },
  {
    label: "No",
    value: "no"
  }
];

export const DUMMY_HEROIMAGE = [
  {
    value: "yes"
  }
];

export const appConstants = {
  dateFormat: "D MMM, YY",
  orderDateFormat: "D/M/YYYY",
  invoiceDateFormat: "D-M-YYYY",
  fullFormat: "D MMMM YYYY",
  timeFormat: "h:mm A",
  sgst: 9,
  cgst: 9
};

export const home_labels = {
  titleText: "Storage that you can ",
  titleTextSecond: "‘Trust On’",
  titleSubtext:
    "Warex is a Marketplace for Warehouses and Transporters to be accessed by SMEs for their Short term Storage demand",
  findWarehouseButtonText: "Find Warehouse",
  findTruckButtonText: "Find Truck",
  whatAreYouLookingForText: "What are you looking for?",
  howWeSolveText: "How we Solve ?",
  howWeSolveSubText:
    "We increase your Distribution Efficiency. Reduce your Storage and Logistics Cost.",
  searchText: "Search",
  searchSubText: "Find matching spaces and trucks.",
  findText: "Find",
  findSubText: "Find matching spaces and trucks.",
  rfqText: "RFQs",
  rfqSubText: "Request Quotes from selected Partners",
  bookText: "Book",
  bookSubText:
    "Compare Quotes received and Book Order on the Partner you select",
  manageText: "Manage",
  manageSubText:
    "Create Packing Notes, Manage and Get updated with the Order Flow.",
  retrieveText: "Retrieve",
  retrieveSubText: "Retrieve Partial or Full Consignments as per your need.",
  industriesServedText: "Industries served",
  industriesServedSubText:
    "Warex is a Marketplace for Warehouses and Transporters to be accessed by SMEs for their Short term Storage demand",
  "apparel&TextilesText": "Apparel & Textiles",
  "food&FMGCsText": "Food & FMGCs",
  healthCare: "Healthcare",
  showAllButtonText: "Show All",
  aboutUsTitleText: "About us",
  aboutUsSubText:
    "Warex is a Marketplace for Warehouses and Transporters to be accessed by SMEs for their Short term Storage demand. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
  learnMoreButtonText: "Learn more",
  contactUsTitleText: "Contact us",
  contactUsSubText:
    "Warex is a Marketplace for Warehouses and Transporters to be accessed by SMEs for their Short term Storage demand",
  contactNumberText: "+91 - 905 958 8686",
  contactEmailText: "Email : info@warex.in",
  infoEmailText: "Write to : info@warex.in",
  warehousePartnerLabelText: "Warehouse partner",
  transporterPartnerLabelText: "Transporter partner",
  partnerWithUsTitleText: "Want to Partner with us ?",
  loginViaText: "Login via ",
  mobileOrEmailText: "mobile or email",
  emailOnly: "email ",
  adminOnly: "Only for Admin",
  loginFormCaptionText: "By continuing, I agree to the ",
  termOfUseText: "Terms of Use",
  privacyPolicyText: "Privacy Policy",
  forgotPasswordText: "Forgot password?"
};

export const partner_table_labels = {
  searchPartnerText: "Search by Partner ID",
  addPartner: "Add New Partner"
};

export const inquiry_table_labels = {
  manageInquiry: " Manage Inquiry",
  searchPlaceholderText: "Search by inquiry ID, etc",
  close: "Close",
  clear: "Clear",
  addNewInquiry: "Add new Inquiry",
  dated: "Dated",
  inquiryId: "Inquiry ID",
  view: "View",
  download: "Download",
  cancel: "Cancel",
  book: "Book",
  proposedPrice: "Proposed price",
  confirmCloseInquiry: "Are you sure you want to close this Inquiry?",
  confirmCloseInquirySubText:
    "This will delete the Inquiry permanently. You cannot undo this action later."
};

export const order_table_labels = {
  manageOrders: "Manage Orders",
  searchPlaceholderText: "Search by order ID partner name, etc",
  view: "View",
  cancelled: "Cancelled",
  retrieveOrders: "Retrieve Orders",
  createPackingNotes: "Create Packing Notes",
  modifyOrder: "Modify Order",
  cancelOrder: "Cancel Order",
  retrieveOrder: "Retrieve Order",
  trackOrder: "Track Order",
  viewDetails: "View details",
  orderDetails: "Order details",
  viewInvoicesAndDocs: "View Invoices & docs",
  orderId: "Order ID",
  orderDate: "Order date",
  cancel: "Cancel",
  orderEditModeWarningMsg:
    "Change in date and location is subjected to the change in overall amount. ",
  order: "Order",
  storageCosts: "Storage Costs",
  addnlCosts: "Addnl Costs",
  previousTotal: "Previous Total",
  updatedTotal: "Updated Total",
  pay: "Pay",
  toContinue: "to continue",
  packingNotes: "Packing notes",
  packageNameLabel: "Package name",
  packageSizeLabel: "Package size",
  addPackageNamePlaceholder: "Add package name",
  packingMaterialLabel: "Packing Material",
  orderNo: "Order No",
  qrCodePlaceholderLabel: "Save packing note to generate QR",
  itemNamePlaceholder: "Item name",
  categoryPlaceholder: "Category",
  quantityPlaceholder: "Quantity",
  addMoreItems: "Add more Items",
  print: "Print",
  editNote: "Edit note",
  savePackingNote: "Save packing note",
  addMorePackages: "Add more packages",
  id: "ID",
  retrievePackages: "Retrieve packages",
  selectPackages: "Select packages",
  retrieveAll: "Retrieve all",
  retrieveSelectedPackage: (count) => `Retrieve selected package (${count})`,
  modifyRetrieveOrderShippingDateWarningMsg:
    "Modify order for changing shipping date beyond outbound date",
  requestOutbound: "Request Outbound",
  checkRetrievalStatus: "Check retrieval status",
  bookATransporter: "Book a transporter",
  downloadGatepass: "Download Gatepass",
  amount: "Amount",
  status: "Status",
  retrieveDate: "Retrieve date"
};

export const invoice_and_docs_labels = {
  createdOn: "Created on",
  print: "Print",
  download: "Download",
  documents: "Documents",
  paymentLedger: "Payment Ledger",
  orderDetails: "Order Details",
  warehouseOrder: "Warehouse Order",
  transporterOrder: "Transporter Order",
  invoiceDetails: "Invoice Details",
  warehouseInvoice: "Warehouse Invoice",
  transporterInvoice: "Transporter Invoice",
  yourPayments: "Your Payments",
  paymentAmount: "Payment Amount",
  transactionID: "Transaction ID",
  toBePaid: "To be paid",
  payNow: "Pay now"
};

export const action_labels = {
  outboundRequestSuccess: "Outbound requested successfully!",
  yourInquiryCancelled: "Your inquiry is cancelled!",
  rfqSentToWarehousesSuccess: "RFQ sent to warehouses successfully!",
  warehousesRequestRespondMsgSuccess:
    "You can expect a response in 4-5 working hours from the warehouses.",
  customerRequestInquiryResponseTime:
    "You can expect a response in 4-5 working hours from the customer.",
  yesRegretButtonText: "Yes, regret"
};

export const statusKeys = {
  Completed: "Completed",
  Booked: "Booked",
  Quoted: "Quoted Sent",
  ToBePickedUp: "ToBePickedUp",
  splitNotEnabled: "SplitNotEnabled",
  Cancelled: "Cancelled",
  VehicleAssigned: "VehicleAssigned",
  InTransit: "InTransit",
  ReceivedAtFacility: "ReceivedAtFacility",
  Stored: "Stored",
  PartiallyRetrieved: "PartiallyRetrieved",
  Partial: "Partial",
  InvoicePending: "InvoicePending",
  Paid: "Paid",
  Responded: "Responded",
  Regret: "Regret",
  Regretted: "Regretted",
  regret: "regret",
  Pending: "Pending",
  QuoteSent: "QuoteSent",
  YetToBePicked: "YetToBePicked",
  Received: "Received",
  GatePassRequested: "GatePassRequested",
  GatePassPrepared: "GatePassPrepared",
  AdminPartial: "pending",
  PendingReview: "PendingReview",
  Active: "Active",
  InActive: "Inactive",
  Delisted: "DeListed",
  Created: "Created",
  Settled: "Settled",
  PaymentPending: "PaymentPending",
  SettlementPending: "SettlementPending",
  ToBePicked: "ToBePicked",
  Trial: "Trial",
  Expired: "Expired"
};

export const profile_labels = {
  personalDetails: "Personal details",
  businessAddress: "Business Address",
  address: "Address",
  otherSavedAddress: "Other saved Address",
  saveChanges: "Save changes",
  cancel: "Cancel",
  edit: "Edit",
  firstNameLabelText: "First Name",
  lastNameLabelText: "Last Name",
  firmsNameLabelText: "Firms Name",
  emailLabelText: "Email",
  gstinLabelText: "GSTIN",
  phoneLabelText: "Phone",
  brandsLabelText: "Brands",
  brandsPlaceholderText: "Add brands",
  productsLabelText: "Products",
  productsPlaceholderText: "Select products",
  cityPlaceholderText: "City",
  countryPlaceholderText: "Country",
  zipCodePlaceholderText: "Zip Code",
  firstNamePlaceholderText: "First name",
  lastNamePlaceholderText: "Last name",
  firmsNamePlaceholderText: "Firms name",
  emailPlaceholderText: "Enter email ID",
  gstinPlaceholderText: "Enter GSTIN number",
  phonePlaceholderText: "XXXX XX XXXX",
  verified: "Verified",
  natureOfBusinessLabelText: "Nature of business",
  natureOfBusinessPlaceholderText: "Select nature of business",
  streetAddressLabelText: "Street",
  streetAddressPlaceholderText: "Street address",
  stateLabelText: "State",
  setAsDefault: "Set as default"
};

export const warehouse_labels = {
  listYourText: "List your",
  warehouseText: "warehouse here",
  dashboardCaptionText:
    "Share with us details about your requirement. You an change them later through our filters.",
  addWarehouseButtonText: "+ Add warehouse",
  addTransportButtonText: "+ Add transport",
  warehouseDetailsTitleText: "Warehouse details",
  warehouseNameLabelText: "Warehouse name",
  warehouseNamePlaceholderText: "Enter warehouse name",
  establishedYearLabelText: "Established year",
  establishedYearPlaceholderText: "Enter date",
  mapLocationLabelText: "Warehouse address",
  mapLocationPlaceholderText: "Map your location",
  addressPlaceholderText: "Address",
  cityPlaceholderText: "City",
  statePlaceholderText: "State",
  zipCodePlaceholderText: "Zip Code",
  sameBillingAddressLabelText: "Use the same address for billing",
  billingAddressLabelText: "Billing address",
  billingAddressPlaceholderText: "Enter area name",
  gstinLabelText: "GSTIN",
  gstinPlaceholderText: "Enter GSTIN number",
  warehouseDescriptionLabelText: "Warehouse description",
  warehouseDescriptionPlaceholderText: "Add warehouse description",
  storageDetailsLabelText: "Storage details",
  storageSpaceLabelText: "Total storage space",
  storageSpacePlaceholderText: "Enter total space",
  availableSpaceLabelText: "Available space",
  availableSpacePlaceholderText: "Enter available space",
  storageTypeAllowedLabelText: "Storage type allowed",
  storageTypeAllowedPlaceholderText: "Select storage type",
  materialDetailsLabelText: "Material Details (Allowed Materials for Storage)",
  materialDetailsPlaceholderText: "Select products",
  availableFeaturesLabelText: "Features Available",
  additionalServicesTitleText: "Additional Services/ Material handling",
  temperatureControlLabelText: "Temperature controls",
  cctvAndSurveillanceLabelText: "CCTV & Surveillance",
  securityGuardsLabelText: "Security Guards",
  pestControlLabelText: "Pest Control",
  uploadPhotoLabelText: "Upload warehouse photos",
  uploadButtonText: "Browse photo",
  reviewButtonText: "Submit for review",
  previewButtonText: "Preview",
  listedOnText: "Listed on",
  viewDetailsButtonText: "View details",
  squareFeetLabelText: "sqft.",
  usedUpSpaceText: "Used up space :",
  submittedReviewStatusText: "Submitted for review",
  storageOptionsAndPriceText: "Storage options & price",
  storageTotalAndAvailableSpace: "Storage total & available space",
  perUnitText: "/ Unit",
  perKgText: "/ Kg",
  cancelButtonText: "Cancel",
  updatePriceButtonText: "Update Price",
  updateAreaButtonText: "Update Area",
  additionalPricesText: "Additional Prices",
  inquiryIdTitleText: "Inquiry ID",
  inquiryDateTitleText: "Inquiry date",
  regretButtonText: "Regret",
  resetChangesButtonText: "Reset changes",
  keepUpdatingButtonText: "Keep updating",
  goBackInquiryButtonText: "Go back to inquiry",
  sendQuoteButtonText: "Send quote",
  reviseQuotationTitleText: "Revise quotation",
  pendingReviewStatusText: "Pending",
  material: "Material",
  status: "Status",
  apparels: "Apparels",
  inbound: "Inbound",
  outbound: "Outbound",
  reviseQuotationComparison: "RFQ Comparison",
  otherReasonPlaceholderText: "Mention the reason",
  regretInquiryTitleText: "Are you sure you want to regret this Inquiry?",
  regretInquirySubText:
    "This will delete the Inquiry permanently. You cannot undo this action later.",
  yesRegretButtonText: "Yes, regret",
  isRequired: "is required",
  addMoreItemsButtonText: "Add more items",
  updatedAmountText: "Updated Amount",
  materialReceivedReportText: "Material Received Report",
  addStorageDetailsText: "Add Storage Details",
  customerNameText: "Customer name",
  transactionIdText: "Transaction ID",
  addressText: "Address",
  customerDetailsLabelText: "Customer details",
  customerPaymentsLabelText: "Customer payment",
  viewOrderFormButtonText: "View Order form",
  paymentDetailsLabelText: "Payment details",
  createInvoiceButtonText: "+ Create Invoice",
  amountPaidText: "Amount paid",
  paymentDateText: "Payment date",
  paymentMethodText: "Payment method",
  accountDetailsText: "Account details",
  paymentAmountText: "Payment amount",
  commissionDetailsText: "Commission details",
  commissionAmountText: "Commission amount",
  commissionClaimedText: "Commissioned claimed",
  partnerPayout: "Partner Payout",
  invoiceNo: "Invoice No.",
  invoiceDate: "Invoice Date",
  netPayout: "Net Payout",
  paymentId: "Payment ID",
  balanceText: "Balance",
  requote: "Reset",
  yesConfirm: "Yes, Confirm",
  goBack: "Go Back"
};

export const regimeRadioGroup = [
  {
    label: "5%",
    value: "5"
  },
  {
    label: "12%",
    value: "12"
  },
  {
    label: "18%",
    value: "18"
  }
];

export const transport_labels = {
  transportDetailsTitleText: "Transport details",
  transportNameLabelText: "Transport name",
  transportNamePlaceholderText: "Enter transport name",
  establishedYearLabelText: "Established year",
  establishedYearPlaceholderText: "Enter date",
  mapLocationLabelText: "Truck Depot Address",
  mapLocationPlaceholderText: "Map your location",
  addressPlaceholderText: "Address",
  cityPlaceholderText: "City",
  statePlaceholderText: "State",
  zipCodePlaceholderText: "Zip Code",
  sameBillingAddressLabelText: "Use the same address for billing",
  billingAddressLabelText: "Billing address",
  billingAddressPlaceholderText: "Enter area name",
  gstinLabelText: "GSTIN",
  gstRegimeLabelText: "GSTIN Regime",
  gstinPlaceholderText: "Enter GSTIN number",
  transportDescriptionLabelText: "Transport description",
  transportDescriptionPlaceholderText: "Add transport description",
  addTruckDetailLabelText: "Add truck details",
  addAnotherTruckButtonText: "+ Add another truck",
  additionalServicesTitleText: "Additional services",
  truckNumberLabelText: "Truck number",
  truckNumberPlaceholderText: "Enter truck number",
  truckMakeLabelText: "Truck Make",
  truckMakePlaceholderText: "Select Truck Make",
  truckModelLabelText: "Truck Model",
  truckModelPlaceholderText: "Select Truck Model",
  pricePerKmLabelText: "Price per KM",
  pricePerKmPlaceholderText: "Enter price",
  truckUploadButtonText: "Add truck photos",
  submitPreviewButtonText: "Submit for review",
  truckCardPriceText: "Price / km : ₹",
  loadingText: "Loading",
  packagingText: "Packaging",
  unloadingText: "Unloading",
  truckDetailsLabelText: "Truck details",
  transportText: "transport here",
  updatePriceButtonText: "Update Price",
  updateVehicle: "Update Vehicle"
};

export const AddPartner_labels = {
  yesConfirm: "Yes, Confirm",
  goBack: "Go Back",
  ok: "OK"
};

export const EditPartner_labels = {
  ApproveListing: "Approve the Listing",
  Delist: "Delist it",
  ok: "OK"
};

export const Partner_labels = {
  CreateNewPartner: "Create New Partner",
  partnerDetails: "Partner Details",
  createButton: "CREATE",
  editButton: "Edit",
  cancelButton: "Cancel",
  saveChanges: "Save Changes",
  partnerCode: "Partner Code",
  PartnerName: "Partner Legal Name",
  SignupDate: "Signup Date",
  PartnerCity: "City",
  PartnerCluster: "Cluster",
  PartnerType: "Partner Type",
  PartnerCommission: "Partner Commission",
  RegisterMobile: "Registered Mobile for Notifications",
  RegisterEmail: "Registered Email for Login",
  OwnerKdmName: "Owner/ KDM Name",
  OwnerKdmContact: "Owner/ KDM Contact",
  PocName: "POC Name",
  PocContact: "POC Contact",
  mobileNumber: "Enter mobile number",
  BdmOnboarded: "BDM Onboarded",
  BdmContact: "BDM Contact",
  BankAccountDetails: "KYC and Bank Account Details",
  AccountType: "Account Type",
  BankName: "Bank Name",
  AccountName: "Account Name",
  AccountNumber: "Account Number",
  IFSCCode: "IFSC Code",
  gstinNo: "GSTIN No.",
  panNo: "PAN No.",
  splitEnabled: "Split Payment Enabled ?",
  gstReg: "GSTIN Regime",
  SpecialNotes: "Any Special Notes"
};

export const partnerPayment_Labels = {
  paymentAmount: "Payment Amount",
  transactionId: "Transaction ID",
  transactionDate: "Date"
};
