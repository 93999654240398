import { queryKeys } from "../../constants/queryKeys";
import { getPartners } from "../../admin/action";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useFetchPartnerQuery = () => {
  const partners = useInfiniteQuery(
    [queryKeys.queryFetchPartners],
    ({ pageParam = 1 }) => getPartners(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage?.partners.length === lastPage?.pagination.limit
            ? allPages.length + 1
            : undefined;
        return nextPage;
      },
    }
  );
  return partners;
};
