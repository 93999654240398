import { getUpdateSubUserFormData } from "../../admin/action";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../constants/queryKeys";

export const useFetchUpdateSubUserFormData = (clientId, subUserId) => {
  const updateFormDataQuery = useQuery(
    [queryKeys.queryGetUpdateSubUserFormData, clientId, subUserId],
    () => getUpdateSubUserFormData(clientId, subUserId),
    {
      enabled: Boolean(subUserId) && Boolean(clientId),
      refetchOnWindowFocus: false
    }
  );
  return updateFormDataQuery;
};
