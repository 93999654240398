import { queryKeys } from "../../constants/queryKeys";
import { fetchWarehouseFormData } from "../../admin/action";
import { useQuery } from "@tanstack/react-query";

export const useFetchWarehouseFormDataQueryHook = (partnerId) => {
  const warehouseFormDataQuery = useQuery(
    [queryKeys.queryGetWarehouseFormData],
    () => fetchWarehouseFormData(partnerId),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(partnerId)
    }
  );
  return warehouseFormDataQuery;
};
