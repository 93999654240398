import { Grid, Typography, Box, Button } from "@mui/material";
import { useRef, forwardRef } from "react";
import CustomTable from "../../shared/customTable";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import { partner_table_labels } from "../../constants";
import InvoiceStatus from "../components/InvoiceStatus";
import { useDrawer } from "../../shared/customSideDrawer";
import DateInput from "../../shared/formElements/DateInput";
import { CalendarTodayOutlined } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { inquiry_table_labels } from "../../constants";
import { labels } from "../../constants";
import { SearchFormKeys } from "../../constants/formKeys";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import InvoiceDetailsPdf from "../components/InvoiceDetailsPdf";
import { useFetchSettlementQueryHook } from "../../query-hooks/Settlement/useFetchSettlementQueryHook";
import styled from "@emotion/styled";
import { currencyFormatter } from "../../shared/utils";

const StyledRowText = styled(Typography)(() => ({
  fontSize: 14,
  lineHeight: "20px",
  color: "#2773FF",
  fontWeight: 700,
}));


const InvoiceAndDocs = () => {
  const { control } = useForm();
  const { showDrawer, hideDrawer } = useDrawer();

  const { data, isFetched } = useFetchSettlementQueryHook();

  const ComponentToPrintDownload = forwardRef((props, ref) => {
    return (
      <div ref={ref}>
        <InvoiceDetailsPdf />
      </div>
    );
  });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const componentRef = useRef();

  const getColumns = () => {
    return [
      {
        Header: "Order ID",
        accessor: "orderCode",
        Cell: ({ value }) => {
          return <StyledRowText>{value}</StyledRowText>;
        },
      },
      {
        Header: "Partner ID",
        accessor: "partnerCode",
        Cell: ({ value }) => {
          return <StyledRowText>{value}</StyledRowText>;
        },
      },
      {
        Header: "Customer",
        accessor: "customer",
        Cell: ({ value }) => {
          return (
            <Box justifyContent={"center"} textAlign={"center"}>
              <Typography>{`{No Name}`}</Typography>
              <StyledRowText>{value}</StyledRowText>
            </Box>
          );
        },
      },
      {
        Header: "Order date",
        accessor: "orderDate",
      },
      {
        Header: "Order Amount",
        accessor: "orderAmount",
      },
      {
        Header: "Invoice Amount",
        accessor: "invoiceAmount",
      },
      {
        Header: "Partner Amount Paid",
        accessor: "partnerAmountPaid",
      },
      {
        Header: "Warex Comm Amount",
        accessor: "warexCommAmount",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell, value }) => {
          return (
            <StyledChip
              label={value}
              onClick={() => {
                showDrawer({
                  component: (
                    <InvoiceStatus
                      close={hideDrawer}
                      status={value}
                      settlementId={cell.row.original.orderId}
                    />
                  ),
                  closeIcon: false,
                });
              }}
            />
          );
        },
      },
      {
        Header: "Comm Invoice No.",
        accessor: "commInvoiceNo",
        Cell: ({ value }) => {
          return (
            <Button
              variant="text"
              disableRipple
              sx={{
                color: "#2773FF",
                fontWeight: "700",
                "&:hover": {
                  backgroundColor: "transparent",
                },
                display: value === "" ? "none" : "flex",
              }}
              onClick={handlePrint}
            >
              {value}
            </Button>
          );
        },
      },
    ];
  };

  const getRows = (rows = []) => {
    return rows.map((item, index) => {
      return {
        orderId: item._id,
        orderCode: item.orderCode,
        partnerCode: item.partnerCode,
        customer: item.customer.mobile,
        orderDate: moment(item.orderDate).format("DD.MM.YY"),
        orderAmount: currencyFormatter(parseFloat(item.orderAmount).toFixed(2)),
        invoiceAmount: currencyFormatter(
          parseFloat(item.invoiceAmount).toFixed(2)
        ),
        partnerAmountPaid: currencyFormatter(
          parseFloat(item.paidAmount).toFixed(2)
        ),
        warexCommAmount: currencyFormatter(
          parseFloat(item.commissionAmount).toFixed(2)
        ),
        status: item.status.name,
      };
    });
  };

  return (
    <Grid container spacing={4}>
      <Grid
        item
        xs={12}
        container
        // spacing={8}
        display={"flex"}
        alignItems={"flex-end"}
        justifyContent={"space-between"}
      >
        <Grid item>
          <DateInput
            control={control}
            name={SearchFormKeys.FromDate}
            label={labels.fromDate}
            placeholder={labels.datePlaceholderText}
            endIcon={CalendarTodayOutlined}
          />
        </Grid>
        <Grid item>
          <DateInput
            control={control}
            name={SearchFormKeys.ToDate}
            label={labels.toDate}
            placeholder={labels.datePlaceholderText}
            endIcon={CalendarTodayOutlined}
          />
        </Grid>
        <Grid item>
          <Button variant="contained" sx={{ height: "45px", width: "120px" }}>
            {inquiry_table_labels.view}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" sx={{ height: "45px", width: "120px" }}>
            {inquiry_table_labels.clear}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" sx={{ height: "45px", width: "120px" }}>
            {inquiry_table_labels.download}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          searchPlaceholderText={partner_table_labels.searchPartnerText}
          columns={getColumns()}
          data={
            isFetched
              ? data?.pages.flatMap((page) => getRows(page?.settlements))
              : []
          }
          isLoading={!isFetched}
        />
      </Grid>
      <Grid item xs={12} display={"none"}>
        <ComponentToPrintDownload ref={componentRef} />
      </Grid>
    </Grid>
  );
};

export default InvoiceAndDocs;
