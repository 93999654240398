import { Box, Button, Typography } from "@mui/material";
import { labels } from "../../constants";
import { changeStatusKeys } from "../../constants/formKeys";
import DropDown from "../formElements/DropDown";
import { CheckCircle } from "@mui/icons-material";
import { StyledChip } from "./StatusChip";
import { LoadingButton } from "@mui/lab";
import { useFetchUpdateOrderStatusFormData } from "../../query-hooks/Orders/useFetchUpdateOrderStatusFormData";

const StatusChangeDailog = ({
  control,
  orderId,
  subId,
  subText1 = "",
  subText2 = "",
  subText3 = "",
  label,
  btn1Text,
  btn2Text,
  btn1Callback,
  btn2Callback,
  isSubmitting
}) => {
  const { data: statusOptions, isFetching: isFetchingStatuses } =
    useFetchUpdateOrderStatusFormData(orderId);
  return (
    <Box
      padding={1}
      display={"flex"}
      flexDirection={"column"}
      gap={"12px"}
      textAlign={"center"}
      alignItems={"center"}
    >
      <CheckCircle
        color="success"
        sx={{
          height: 60,
          width: 60
        }}
      />
      {subText1 && (
        <Typography
          fontSize={14}
          lineHeight={"24px"}
          color={"text.secondary"}
          maxWidth={422}
        >
          {subText1}
        </Typography>
      )}
      <StyledChip label={label} />
      {subText2 && (
        <Typography
          fontSize={14}
          lineHeight={"24px"}
          color={"text.secondary"}
          maxWidth={422}
          marginY={2}
        >
          {subText2}
        </Typography>
      )}
      <Box sx={{ minWidth: "280px" }}>
        <DropDown
          control={control}
          name={changeStatusKeys.status}
          label={labels.chooseAppropriateStatus}
          options={statusOptions?.customerStatuses}
          isLoading={isFetchingStatuses}
        />
      </Box>
      {subText3 && (
        <Typography
          fontSize={14}
          lineHeight={"24px"}
          color={"text.secondary"}
          maxWidth={422}
          marginTop={3}
        >
          {subText3}
        </Typography>
      )}
      <Box display={{ xs: "block", lg: "block" }} gap={"10px"} width={"100%"}>
        {btn1Text && (
          <LoadingButton
            fullWidth
            variant="contained"
            sx={{
              height: { xs: 48, lg: 64 },
              fontWeight: 500,
              fontSize: 15,
              marginTop: "20px",
              whiteSpace: "nowrap"
            }}
            onClick={btn1Callback}
            loading={isSubmitting}
          >
            {btn1Text}
          </LoadingButton>
        )}
        {btn2Text && (
          <Button
            fullWidth
            variant="soft"
            sx={{
              height: { xs: 48, lg: 64 },
              fontWeight: 500,
              fontSize: 15,
              marginTop: "20px",
              whiteSpace: "nowrap"
            }}
            onClick={btn2Callback}
          >
            {btn2Text}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default StatusChangeDailog;
