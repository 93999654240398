import { Typography } from "@mui/material";
import {statusKeys} from "../../constants/index";
export const quotationForm = [
  {
    label: "Storage Cost",
    uom: "storage_cost_uom",
    duration: "storage_cost_duration",
    quantity: "storage_cost_quantity",
    placeHolder: "Sq.ft",
    durationPlaceHolder: "1.67 Months",
    quantityPlaceHolder: "400",
  },
  {
    label: "Labor Cost",
    uom: "labor_cost_uom",
    duration: "labor_cost_duration",
    quantity: "labor_cost_quantity",
    placeHolder: "Per/100 kgs",
    durationPlaceHolder: "-",
    quantityPlaceHolder: "200 kgs",
  },
  {
    label: "Equipments",
    uom: "equipments_uom",
    duration: "equipments_duration",
    quantity: "equipments_quantity",
    placeHolder: "Per/100 kgs",
    durationPlaceHolder: "-",
    quantityPlaceHolder: "200 kgs",
  },
  {
    label: "Other / Misc.",
    uom: "other_uom",
    duration: "other_duration",
    quantity: "other_quantity",
    placeHolder: "Lumpsum",
    durationPlaceHolder: "-",
    quantityPlaceHolder: "1",
  },
];

export const rateForm = [
  {
    rate: "storage_cost_rate",
    total: "storage_cost_total",
    ratePlaceHolder: "25",
    TotalPlaceHolder: "16700",
  },
  {
    rate: "labor_cost_rate",
    total: "labor_cost_total",
    ratePlaceHolder: "250",
    TotalPlaceHolder: "500",
  },
  {
    rate: "equipments_rate",
    total: "equipments_total",
    ratePlaceHolder: "250",
    TotalPlaceHolder: "500",
  },
  {
    rate: "other_rate",
    total: "other_total",
    ratePlaceHolder: "2500",
    TotalPlaceHolder: "2500",
  },
];


export const quotation_labels = ["UOM", "Duration", "Quantity"];
export const partner_quotation_labels = ["Rate", "Total"];
export const packageConditionColorCodes = {
  Damaged: "error.main",
  "Critical Damaged!": "error.dark",
  Healthy: "success.dark",
};

export const packageConditionOptions = [
  {
    name: "Critical Damaged!",
    displayName: (
      <Typography fontSize={14} color={"error.dark"}>
        Critical Damaged!
      </Typography>
    ),
  },
  {
    name: "Damaged",
    displayName: (
      <Typography fontSize={14} color={"error.main"}>
        Damaged
      </Typography>
    ),
  },
  {
    name: "Healthy",
    displayName: (
      <Typography fontSize={14} color={"success.dark"}>
        Healthy
      </Typography>
    ),
  },
];

export const mrr_dummy_data = [
  {
    packageName: "Cotton shirt_30gsm",
    packagingMaterial: "Cartoon",
    packageSize: "3’ X 3’",
    packageCondition: "Healthy",
    rackNumber: "",
  },
  {
    packageName: "Cotton shirt_30gsm",
    packagingMaterial: "Cartoon",
    packageSize: "3’ X 3’",
    packageCondition: "",
    rackNumber: "",
  },
  {
    packageName: "Cotton shirt_30gsm",
    packagingMaterial: "Cartoon",
    packageSize: "3’ X 3’",
    packageCondition: "",
    rackNumber: "",
  },
  {
    packageName: "Cotton shirt_30gsm",
    packagingMaterial: "Cartoon",
    packageSize: "3’ X 3’",
    packageCondition: "",
    rackNumber: "",
  },
  {
    packageName: "Cotton shirt_30gsm",
    packagingMaterial: "Cartoon",
    packageSize: "3’ X 3’",
    packageCondition: "",
    rackNumber: "",
  },
  {
    packageName: "Cotton shirt_30gsm",
    packagingMaterial: "Cartoon",
    packageSize: "3’ X 3’",
    packageCondition: "",
    rackNumber: "",
  },
];

export const Reqoute_Box = [
  { value: true, keyChip: statusKeys.AdminPartial, Buttontxt: "WHY123" },
  { value: false, keyChip: statusKeys.Quoted, Buttontxt: "WHY009" },
  { value: false, keyChip: statusKeys.regret, Buttontxt: "WHY256" },
];
