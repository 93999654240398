import { statusPartner } from "../../admin/action";
import { useMutation } from "@tanstack/react-query";

export const useStatusPartnerMutateQuery = () => {
  const statusPartnerMutate = useMutation(({ partnerId, formData }) =>
    statusPartner(partnerId, formData)
  );

  return statusPartnerMutate;
};
