import { updatePartner } from "../../admin/action";
import { useMutation } from "@tanstack/react-query";

export const useUpdatePartnerQuery = () => {
  const updatePartnerMutate = useMutation(({ partnerId, formData }) =>
    updatePartner(partnerId, formData)
  );

  return updatePartnerMutate;
};
