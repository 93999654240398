import { Box, Button, Divider, Grid, Typography, styled } from "@mui/material";
import {
  DUMMY_ITEMS,
  EditPartner_labels,
  Partner_labels,
  labels,
  warehouse_labels,
} from "../../constants";
import { Fragment, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import AutoCompleteMapField from "../../shared/formElements/AutoCompleteMapField";
import CheckBoxGroup from "../../shared/formElements/CheckBoxGroup";
import CheckBoxSelect from "../../shared/formElements/CheckboxSelect";
import DateInput from "../../shared/formElements/DateInput";
import DropDown from "../../shared/formElements/DropDown";
import ImageRadioGroup from "../../shared/formElements/ImageRadioGroup";
import RadioButtonGroup from "../../shared/formElements/RadioButtonGroup";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import { StyledResponsiveText } from "../../shared/theme/globalTheme";
import SuccessfulDialog from "../../shared/UiElements/SuccessfulDialog";
import TextArea from "../../shared/formElements/TextArea";
import TextInput from "../../shared/formElements/TextInput";
import { useCustomDialog } from "../../shared/customDialog";
import { useApprovePartnerMutateQuery } from "../../query-hooks/Partners/useApprovePartnerMutateQuery";
import { useStatusPartnerMutateQuery } from "../../query-hooks/Partners/useStatusPartnerMutateQuery";
import { useFetchWarehouseFormDataQueryHook } from "../../query-hooks/EditWarehouse/useFetchWarehouseFormDataQueryHook";
import { useMutateWarehousePartnerQuery } from "../../query-hooks/EditWarehouse/useMutateWarehousePartnerQuery";
import { useReplaceWarehouseFileQuery } from "../../query-hooks/EditWarehouse/useReplaceWarehouseFileQuery";
import { useUpdateWarehouseHeroImage } from "../../query-hooks/EditWarehouse/useUpdateWarehouseHeroImage";
import { warehouseFormKeys } from "../../constants/formKeys";
import { warehouseFormSchema } from "./WarehouseFormValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { enqueueSnackbar } from "notistack";

export const StyledBoxContainer = styled((props) => (
  <Box
    {...props}
    sx={{
      padding: {
        xs: "25px 12px",
        md: "25px 16px",
      },
      backgroundColor: "#F3F5F9",
      borderRadius: 2,
    }}
  />
))``;

export const StyledFormTitle = styled((props) => (
  <StyledResponsiveText
    {...props}
    desktop={{
      fontSize: 20,
      lineHeight: "24px",
      letterSpacing: "0.002em",
      fontWeight: 500,
    }}
    mobile={{
      fontSize: 18,
      lineHeight: "22px",
      fontWeight: 600,
    }}
  />
))``;

export const SuccessAlert = ({
  approveButtonClick,
  delistButtonClick,
  disableApprove,
  disableDelist,
}) => {
  return (
    <SuccessfulDialog
      subText={"Do you want to change the status of the Listing ? "}
      btn2Text={EditPartner_labels.ApproveListing}
      btn1Text={EditPartner_labels.Delist}
      btn1Callback={delistButtonClick}
      btn2Callback={approveButtonClick}
      disableApprove={disableApprove}
      disableDelist={disableDelist}
    />
  );
};

export const AccordionWarehouseDetails = (props) => {
  const [isFormDisabled, setIsFormDisabled] = useState(true);

  const { showDialog, hideDialog } = useCustomDialog();

  const allowStatusChangeButton = props?.partner?.status?.name !== "Active";
  const editAllowed = props?.partner?.status?.name === "Inactive";

  const { data: formData, isFetched: isFormDataFetched } =
    useFetchWarehouseFormDataQueryHook(props?.partner?._id);

  const {
    control: detailControl,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      [warehouseFormKeys.warehouseName]: props?.partner?.name,
      [warehouseFormKeys.establishedYear]: new Date(
        `${props?.partner?.establishedYear ?? 2023}-01-01T00:00:00.000Z`
      ),
      [warehouseFormKeys.lat]:
        props?.partner?.address?.location?.coordinates[0],
      [warehouseFormKeys.lng]:
        props?.partner?.address?.location?.coordinates[1],
      [warehouseFormKeys.warehouseCity]: props?.partner?.address?.city,
      [warehouseFormKeys.warehouseState]: props?.partner?.address?.state,
      [warehouseFormKeys.warehouseZipCode]: props?.partner?.address?.pincode,
      [warehouseFormKeys.useSameBillingAddress]: props?.partner?.billingAddress
        ?.sameAsAddress
        ? ["yes"]
        : [],
      [warehouseFormKeys.billingAddress]: props?.partner?.billingAddress
        ?.sameAsAddress
        ? props?.partner?.address?.area
        : props?.partner?.billingAddress?.area,
      [warehouseFormKeys.gstInNumber]: props?.partner?.gstNo,
      [warehouseFormKeys.warehouseDescription]: props?.partner?.description,
      [warehouseFormKeys.warehouseLocation]: props?.partner?.address?.area,
      [warehouseFormKeys.totalStorageSpace]: props?.partner?.storageArea?.total,
      [warehouseFormKeys.availableSpace]:
        props?.partner?.storageArea?.available,
      [warehouseFormKeys.storageTypeAllowed]: [
        ...props?.partner?.storageTypes?.map((item) => item.name),
      ],
      [warehouseFormKeys.materialDetails]: [
        ...props?.partner?.materialCategories?.map((item) => item.name),
      ],
      [warehouseFormKeys.additionalServices]: [
        ...props?.partner?.additionalServices?.map((item) => item.name),
      ],
      [warehouseFormKeys.heroImage]: props?.partner?.heroImage?._id,
    },
    resolver: yupResolver(warehouseFormSchema),
  });

  const {
    fields: storageTypesFields,
    append: appendStorageTypes,
    remove: removeStorageType,
  } = useFieldArray({
    control: detailControl,
    name: warehouseFormKeys.storageTypes,
  });

  const {
    fields: additionalServicesFields,
    append: appendAdditionalService,
    remove: removeAdditionalService,
  } = useFieldArray({
    control: detailControl,
    name: warehouseFormKeys.additionalServicesFields,
  });

  const { fields: featureFields, append: appendFeature } = useFieldArray({
    control: detailControl,
    name: warehouseFormKeys.features,
  });

  const isSameBillingAddress = watch(warehouseFormKeys.useSameBillingAddress);

  const warehouseLocation = watch(warehouseFormKeys.warehouseLocation);

  const storageTypesAllowedWatch = watch(warehouseFormKeys.storageTypeAllowed);

  const materialCategoriesWatch = watch(warehouseFormKeys.materialDetails);

  const additionalServicesWatch = watch(warehouseFormKeys.additionalServices);

  const heroImageWatch = watch(warehouseFormKeys.heroImage);

  const heroImageFileWatch = watch(warehouseFormKeys.heroImageFile);

  useEffect(() => {
    if (isSameBillingAddress[0]) {
      setValue(warehouseFormKeys.billingAddress, warehouseLocation);
    }
  }, [isSameBillingAddress, setValue, warehouseLocation]);

  useEffect(() => {
    if (isFormDataFetched)
      storageTypesAllowedWatch?.forEach((item) => {
        let storageFormData = formData?.storageTypes.find(
          (type) => type.name === item
        );
        let existingField = storageTypesFields.find(
          (field) => field.name === item
        );
        if (!existingField) {
          appendStorageTypes({
            [item]: props?.partner?.storageTypes.find(
              (type) => type.name === item
            )?.rate,
            ...storageFormData,
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageTypesAllowedWatch, isFormDataFetched]);

  useEffect(() => {
    if (isFormDataFetched)
      additionalServicesWatch?.forEach((item) => {
        let storageFormData = formData?.additionalServices.find(
          (service) => service.name === item
        );
        let existingField = additionalServicesFields.find(
          (field) => field.name === item
        );
        if (!existingField) {
          appendAdditionalService({
            [item]: props?.partner?.additionalServices.find(
              (type) => type.name === item
            )?.rate,
            name: storageFormData.name,
            displayName: storageFormData.displayName,
            rateUnit: storageFormData.rateUnit,
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalServicesWatch, isFormDataFetched]);

  useEffect(() => {
    if (isFormDataFetched) {
      setValue(warehouseFormKeys.features, []);
      formData?.features.forEach((item) =>
        appendFeature({
          [item.name]: props?.partner?.features.find(
            (feature) => feature.name === item.name
          )
            ? "yes"
            : "no",
          ...item,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appendFeature, formData?.features, isFormDataFetched, setValue]);

  const toggleForm = () => {
    setIsFormDisabled((prevState) => !prevState);
  };

  const {
    mutate: approvePartner,
    isError: isErrorApprove,
    error: approveError,
  } = useApprovePartnerMutateQuery();

  const {
    mutate: mutateStatus,
    isError: isErrorStatus,
    error: statusError,
  } = useStatusPartnerMutateQuery();

  const {
    mutate: mutatePartnerDetails,
    isError: isErrorPartnerDetails,
    error: partnerDetailsError,
  } = useMutateWarehousePartnerQuery();

  const {
    mutate: mutateFiles,
    isError: isErrorFile,
    error: fileError,
  } = useReplaceWarehouseFileQuery();

  const {
    mutate: mutateHeroImage,
    isError: isErrorHeroImage,
    error: heroImageError,
  } = useUpdateWarehouseHeroImage();

  const approveButtonClick = () => {
    approvePartner(
      {
        partnerId: props?.partner?._id,
        formData: {},
      },
      {
        onSuccess: () => {
          hideDialog();
          enqueueSnackbar("The Partner is Now Listed and Live", {
            variant: "success",
          });
          props.refetch();
        },
      }
    );
  };

  const delistButtonClick = () => {
    mutateStatus(
      {
        partnerId: props?.partner?._id,
        formData: { status: "Inactive" },
      },
      {
        onSuccess: () => {
          hideDialog();
          enqueueSnackbar("The Partner is Now De Listed ", {
            variant: "success",
          });
          props.refetch();
        },
      }
    );
  };

  const onSubmit = (data) => {
    const WarehouseData = {
      name: data?.warehouseName,
      establishedYear: new Date(data?.establishedYear).getFullYear(),
      gstNo: data?.gstInNumber,
      gstRegime: "18",
      description: data?.warehouseDescription,
      address: {
        area: data?.warehouseLocation,
        city: data?.warehouseCity,
        state: data?.warehouseState,
        pincode: parseInt(data?.warehouseZipCode, 10),
        location: {
          coordinates: [data?.lat, data?.lng],
        },
      },
      billingAddress: {
        sameAsAddress: data?.useSameBillingAddress[0] === "yes",
      },
      storageArea: {
        total: parseInt(data?.totalStorageSpace, 10),
        available: parseInt(data?.availableSpace, 10),
      },
      storageTypes: data?.storageTypes.map((item) => ({
        name: item.name,
        rate: item[item.name],
      })),
      additionalServices: data?.additionalServicesFields.map((item) => ({
        name: item.name,
        rate: item[item.name],
      })),
      materialCategories: data?.materialDetails.map((item) => ({
        name: item,
      })),
      features: data?.features
        .filter((item) => item[item.name] === "yes")
        .map((item) => ({
          name: item.name,
        })),
    };
    if (!WarehouseData?.billingAddress?.sameAsAddress) {
      WarehouseData.billingAddress.area = data?.billingAddress;
      WarehouseData.billingAddress.city = data?.warehouseCity;
      WarehouseData.billingAddress.state = data?.warehouseState;
      WarehouseData.billingAddress.pincode = parseInt(
        data?.warehouseZipCode,
        10
      );
    }
    mutatePartnerDetails(
      {
        partnerId: props?.partner?._id,
        formData: { ...WarehouseData },
      },
      {
        onSuccess: () => {
          setIsFormDisabled(true);
          enqueueSnackbar("The changes have been Updated for the Partner", {
            variant: "success",
          });
          props.refetch();
        },
      }
    );
  };

  useEffect(() => {
    if (heroImageWatch && heroImageWatch !== props?.partner?.heroImage?._id) {
      mutateHeroImage(
        {
          partnerId: props?.partner?._id,
          fileId: heroImageWatch,
        },
        {
          onSuccess: () => {
            enqueueSnackbar("HeroImage Updated", {
              variant: "success",
            });
          },
        }
      );
    }
    // eslint-disable-next-line
  }, [heroImageWatch]);

  useEffect(() => {
    if (heroImageFileWatch) {
      const formData = new FormData();
      formData.append("file", heroImageFileWatch?.file);
      mutateFiles(
        {
          partnerId: props?.partner?._id,
          fileId: heroImageFileWatch?.fileId,
          formData: formData,
        },
        {
          onSuccess: () => {
            props?.refetch();
            enqueueSnackbar("Image Updated", {
              variant: "success",
            });
          },
        }
      );
    }
    // eslint-disable-next-line
  }, [heroImageFileWatch]);

  useEffect(() => {
    if (
      isErrorApprove ||
      isErrorStatus ||
      isErrorPartnerDetails ||
      isErrorFile||
      isErrorHeroImage
    )
      enqueueSnackbar(
        approveError?.response.data.message ||
          statusError?.response.data.message ||
          partnerDetailsError?.response.data.message ||
          fileError?.response.data.message ||
          heroImageError?.response.data.message,
        { variant: "error" }
      );
  }, [
    approveError?.response.data.message,
    statusError?.response.data.message,
    partnerDetailsError?.response.data.message,
    fileError?.response.data.message,
    heroImageError?.response.data.message,
    isErrorApprove,
    isErrorStatus,
    isErrorPartnerDetails,
    isErrorFile,
    isErrorHeroImage
  ]);

  return (
    <Box
      component={"form"}
      onSubmit={handleSubmit(onSubmit)}
      display={"flex"}
      flexDirection={"column"}
      gap={"105px"}
      marginBottom={3}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box display={"flex"} gap={9}>
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 500,
              letterSpacing: "0.04px",
            }}
          >
            {labels.partnerListingDetails}
          </Typography>
          <StyledChip
            label={
              props?.partner.status?.name === "Inactive"
                ? props?.partner.status?.displayName
                : props?.partner.status?.name
            }
            onClick={() => {
              if (!editAllowed) {
                showDialog({
                  component: (
                    <SuccessAlert
                      approveButtonClick={approveButtonClick}
                      delistButtonClick={delistButtonClick}
                      disableDelist={allowStatusChangeButton}
                      disableApprove={!allowStatusChangeButton}
                    />
                  ),
                });
              }
            }}
          />
        </Box>
        <Box display={"flex"} gap={4}>
          <Button
            variant="soft"
            sx={{ minWidth: 140, height: 48 }}
            onClick={toggleForm}
            disabled={editAllowed}
          >
            {isFormDisabled
              ? Partner_labels.editButton
              : Partner_labels.cancelButton}
          </Button>
          <Button
            variant="contained"
            sx={{ minWidth: 140, height: 48 }}
            onClick={handleSubmit(onSubmit)}
            disabled={editAllowed}
          >
            {Partner_labels.saveChanges}
          </Button>
        </Box>
      </Box>

      <Box display={"flex"} flexDirection={"column"} gap={2} maxWidth={572}>
        <StyledFormTitle>
          {warehouse_labels.warehouseDetailsTitleText}
        </StyledFormTitle>
        <Divider sx={{ mb: "4px" }} />
        <Grid container spacing={"28px"}>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6}>
              <TextInput
                control={detailControl}
                name={warehouseFormKeys.warehouseName}
                label={warehouse_labels.warehouseNameLabelText}
                placeholder={warehouse_labels.warehouseNamePlaceholderText}
                disabled={isFormDisabled}
              />
            </Grid>
            <Grid item xs={6}>
              <DateInput
                control={detailControl}
                name={warehouseFormKeys.establishedYear}
                label={warehouse_labels.establishedYearLabelText}
                placeholder={warehouse_labels.establishedYearPlaceholderText}
                disabled={isFormDisabled}
                showYearPicker={true}
                minDate="1900-01-01"
                format="yyyy"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container spacing={"12px"}>
            <Grid item xs={12}>
              <AutoCompleteMapField
                control={detailControl}
                name={warehouseFormKeys.warehouseLocation}
                label={warehouse_labels.mapLocationLabelText}
                placeholder={warehouse_labels.mapLocationPlaceholderText}
                reset={(address, location) => {
                  setValue(warehouseFormKeys.warehouseLocation, address);
                  setValue(warehouseFormKeys.lat, location.lat);
                  setValue(warehouseFormKeys.lng, location.lng);
                }}
                disabled={isFormDisabled}
              />
            </Grid>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={4}>
                <TextInput
                  control={detailControl}
                  name={warehouseFormKeys.warehouseCity}
                  placeholder={warehouse_labels.cityPlaceholderText}
                  disabled={isFormDisabled}
                />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  control={detailControl}
                  name={warehouseFormKeys.warehouseState}
                  placeholder={warehouse_labels.statePlaceholderText}
                  disabled={isFormDisabled}
                />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  control={detailControl}
                  name={warehouseFormKeys.warehouseZipCode}
                  placeholder={warehouse_labels.zipCodePlaceholderText}
                  disabled={isFormDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    "& label > span > span, & label > span > span::before": {
                      height: {
                        md: 16,
                      },
                      width: { md: 16 },
                    },
                    "& label > span:last-child": {
                      fontSize: { md: 12 },
                      lineHeight: "16px",
                    },
                  }}
                >
                  <CheckBoxGroup
                    control={detailControl}
                    name={warehouseFormKeys.useSameBillingAddress}
                    options={[
                      {
                        name: "yes",
                        displayName:
                          warehouse_labels.sameBillingAddressLabelText,
                      },
                    ]}
                    setValue={setValue}
                    defaultValues={
                      props?.partner?.billingAddress?.sameAsAddress
                        ? ["yes"]
                        : []
                    }
                    disabled={isFormDisabled}
                    errors={errors}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              control={detailControl}
              name={warehouseFormKeys.billingAddress}
              label={warehouse_labels.billingAddressLabelText}
              placeholder={warehouse_labels.billingAddressPlaceholderText}
              disabled={isFormDisabled || isSameBillingAddress[0] === "yes"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              control={detailControl}
              name={warehouseFormKeys.gstInNumber}
              label={warehouse_labels.gstinLabelText}
              placeholder={warehouse_labels.gstinPlaceholderText}
              disabled={isFormDisabled}
            />
          </Grid>
          <Grid item xs={12}>
            <TextArea
              control={detailControl}
              name={warehouseFormKeys.warehouseDescription}
              label={warehouse_labels.warehouseDescriptionLabelText}
              placeholder={warehouse_labels.warehouseDescriptionPlaceholderText}
              disabled={isFormDisabled}
            />
          </Grid>
        </Grid>
      </Box>

      <Box display={"flex"} flexDirection={"column"} gap={2} maxWidth={572}>
        <StyledFormTitle>
          {warehouse_labels.storageDetailsLabelText}
        </StyledFormTitle>
        <Divider sx={{ mb: "4px" }} />
        <Grid container spacing={"28px"}>
          <Grid item xs={6}>
            <TextInput
              control={detailControl}
              name={warehouseFormKeys.totalStorageSpace}
              label={warehouse_labels.storageSpaceLabelText}
              placeholder={warehouse_labels.storageSpacePlaceholderText}
              disabled={isFormDisabled}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              control={detailControl}
              name={warehouseFormKeys.availableSpace}
              label={warehouse_labels.availableSpaceLabelText}
              placeholder={warehouse_labels.availableSpacePlaceholderText}
              disabled={isFormDisabled}
            />
          </Grid>
          <Grid item xs={12}>
            <DropDown
              name={warehouseFormKeys.storageTypeAllowed}
              control={detailControl}
              placeholder={warehouse_labels.storageTypeAllowedPlaceholderText}
              label={warehouse_labels.storageTypeAllowedLabelText}
              multiple={true}
              options={formData?.storageTypes}
              setValue={setValue}
              onRemove={(value, index) => removeStorageType(index)}
              disabled={isFormDisabled}
              defaultValue={storageTypesAllowedWatch}
            />
          </Grid>
          {storageTypesFields.length > 0 && (
            <Grid item xs={12}>
              <StyledBoxContainer>
                <Grid container spacing={2}>
                  {storageTypesFields.map((item, index) => (
                    <Grid item xs={6} key={item.id}>
                      <TextInput
                        control={detailControl}
                        name={`${warehouseFormKeys.storageTypes}.${index}.${item.name}`}
                        label={item.displayName}
                        placeholder={`Enter in ${item.rateUnit}`}
                        disabled={isFormDisabled}
                      />
                    </Grid>
                  ))}
                </Grid>
              </StyledBoxContainer>
            </Grid>
          )}
          <Grid item xs={12}>
            <CheckBoxSelect
              name={warehouseFormKeys.materialDetails}
              control={detailControl}
              placeholder={warehouse_labels.materialDetailsPlaceholderText}
              label={warehouse_labels.materialDetailsLabelText}
              options={formData?.materialCategories}
              disabled={isFormDisabled}
              defaultValue={materialCategoriesWatch}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckBoxGroup
              label={warehouse_labels.additionalServicesTitleText}
              control={detailControl}
              name={warehouseFormKeys.additionalServices}
              errors={errors}
              options={formData?.additionalServices}
              setValue={setValue}
              onRemove={(value, index) => removeAdditionalService(index)}
              disabled={isFormDisabled}
              defaultValues={additionalServicesWatch}
            />
          </Grid>
          {additionalServicesFields.length > 0 && (
            <Grid item xs={12}>
              <StyledBoxContainer>
                <Grid container spacing={2}>
                  {additionalServicesFields.map((item, index) => (
                    <Grid item xs={6} key={item.id}>
                      <TextInput
                        control={detailControl}
                        name={`${warehouseFormKeys.additionalServicesFields}.${index}.${item.name}`}
                        label={`${item.displayName} (per ${item.rateUnit})`}
                        placeholder={`Enter in per ${item.rateUnit}`}
                        type="number"
                        disabled={isFormDisabled}
                      />
                    </Grid>
                  ))}
                </Grid>
              </StyledBoxContainer>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="body1" fontSize={14}>
              {warehouse_labels.availableFeaturesLabelText}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledBoxContainer>
              <Grid container spacing={3}>
                {featureFields.map((item, index) => (
                  <Fragment key={item.id}>
                    <Grid item xs={8}>
                      <Typography fontSize={14} lineHeight={"20px"}>
                        {item.displayName}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} textAlign={"right"}>
                      <RadioButtonGroup
                        control={detailControl}
                        name={`${warehouseFormKeys.features}.${index}.${item.name}`}
                        options={DUMMY_ITEMS}
                        disabled={isFormDisabled}
                      />
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
            </StyledBoxContainer>
          </Grid>
          <Grid item xs={12}>
            <ImageRadioGroup
              control={detailControl}
              name={warehouseFormKeys.heroImage}
              filesName={warehouseFormKeys.heroImageFile}
              disabled={isFormDisabled}
              options={[
                ...props?.partner?.files?.map((item) => {
                  return {
                    ...item,
                    label: "Make this as Hero Pic",
                    value: item._id,
                  };
                }),
                {
                  ...props?.partner?.heroImage,
                  label: "Make this as Hero Pic",
                  value: props?.partner?.heroImage?._id,
                },
              ]}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
