import { queryKeys } from "../../constants/queryKeys";
import { getSettlement } from "../../admin/action";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useFetchSettlementQueryHook = () => {
  const partnersSettlement = useInfiniteQuery(
    [queryKeys.queryFetchSettlement],
    ({ pageParam = 1 }) => getSettlement(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage?.settlements.length === lastPage?.pagination.limit
            ? allPages.length + 1
            : undefined;
        return nextPage;
      },
    }
  );
  return partnersSettlement;
};
