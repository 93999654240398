import { addTransportVehicle } from "../../admin/action";
import { useMutation } from "@tanstack/react-query";

export const useAddTransportVehicleQuery = () => {
  const addTransportVehicleMutate = useMutation(({ partnerId, formData }) =>
    addTransportVehicle(partnerId, formData)
  );

  return addTransportVehicleMutate;
};
