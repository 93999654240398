import * as yup from "yup";

import {
  Backdrop,
  Box,
  Button,
  FormHelperText,
  Link,
  Typography,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom/dist";
import CheckBoxGroup from "../../shared/formElements/CheckBoxGroup";
import Storage from "../../shared/utils/Storage";
import { StorageKeys, UserRoles } from "../../constants/storage-keys";
import TextInput from "../../shared/formElements/TextInput";
import { error_msg, home_labels } from "../../constants";
import { labels } from "../../constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Auth } from "aws-amplify";
import CircularProgress from "@mui/material/CircularProgress";
import { fetchProfile } from "../../admin/action";

const formSchema = yup
  .object({
    userId: yup
      .string()
      .email("Enter a valid email")
      .required("Please enter email"),
    password: yup.string().min(6, "Please enter a valid 6 digit password"),
  })
  .required();

const StyledBox = styled((props) => (
  <Box
    {...props}
    sx={{
      padding: {
        xs: "25px 20px 16px 20px",
        md: 5,
      },
      boxShadow: {
        xs: "0px 8px 24px rgba(21, 21, 22, 0.1)",
        md: "0px 8px 24px rgba(21, 21, 22, 0.04)",
      },
      background: "#FFFFFF",
      width: {
        xs: "100%",
        md: 460,
      },
      height: {
        xs: 340,
        md: 480,
      },
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }}
    marginBottom={20}
    component={"form"}
  />
))(() => ({}));

const FormHeader = () => (
  <Box
    sx={{
      marginBottom: {
        xs: "20px",
        md: 3,
      },
    }}
  >
    <Typography
      sx={{
        fontSize: {
          xs: 16,
          md: 20,
        },
        lineHeight: {
          xs: "19px",
          md: "24px",
        },
      }}
      component={"span"}
      fontWeight={400}
      letterSpacing={"0.002em"}
    >
      {home_labels.loginViaText}
    </Typography>
    <Typography
      sx={{
        fontSize: {
          xs: 16,
          md: 20,
        },
      }}
      component={"span"}
      fontWeight={600}
    >
      {home_labels.emailOnly}
    </Typography>
    <Typography
      sx={{
        fontSize: {
          xs: 16,
          md: 20,
        },
        float: "right",
      }}
      component={"span"}
      fontWeight={600}
    >
      {home_labels.adminOnly}
    </Typography>
  </Box>
);

const GetHelp = () => (
  <Box
    textAlign={"center"}
    marginBottom={"20px"}
    sx={{
      display: {
        xs: "none",
        md: "block",
      },
    }}
  >
    <Typography component={"span"} fontSize={14} lineHeight={"20px"}>
      {labels.havingTroubleLoggingIn}
    </Typography>{" "}
    <Typography
      component={Link}
      color={"primary"}
      fontSize={14}
      lineHeight={"20px"}
      underline="none"
      href="#"
    >
      {labels.getHelp}
    </Typography>
  </Box>
);

const EmailSection = ({ control }) => (
  <Box marginBottom={3}>
    <TextInput control={control} name={"userId"} placeholder={"Enter email"} />
    <Box
      sx={{
        display: {
          xs: "none",
          md: "block",
        },
      }}
    ></Box>
  </Box>
);

const PasswordSection = ({ control, setValue, isPassword, error }) => (
  <Box>
    <TextInput
      control={control}
      name={"password"}
      placeholder={"Enter password"}
      type={isPassword ? "password" : "text"}
    />
    {error && <FormHelperText error>{error.message}</FormHelperText>}
    <Box
      marginTop={"11px"}
      display={"flex"}
      alignItems={"center"}
      flexDirection={"row"}
      justifyContent={"space-between"}
      sx={{
        "& .MuiTypography-root": {
          fontSize: 13,
        },
      }}
    >
      <CheckBoxGroup
        control={control}
        name={"showPassword"}
        options={[{ name: "showPassword", displayName: "Show password" }]}
        setValue={setValue}
      />
      <Typography
        color={"primary"}
        component={Link}
        sx={{ fontSize: 13, lineHeight: "18px" }}
        href="#"
        underline="none"
      >
        {home_labels.forgotPasswordText}
      </Typography>
    </Box>
  </Box>
);

const AdminForm = () => {
  const navigate = useNavigate();
  // const [showPassword, setShowPassword] = useState(false);
  const [backDrop, setBackDrop] = useState(false);
  const [error, setError] = useState("");
  const handleBackDrop = (action) => {
    setBackDrop(action);
  };
  const {
    control,
    setValue,
    watch,
    handleSubmit,
  } = //, register, unregister } =
    useForm({
      defaultValues: {
        userId: "",
        password: "",
        showPassword: [],
      },
      resolver: yupResolver(formSchema),
    });

  const togglePassword = watch("showPassword");

  // useEffect(() => {
  //   if (showPassword) {
  //     register("password");
  //   } else {
  //     unregister("password");
  //     register("userId");
  //   }
  // }, [register, showPassword, unregister]);

  const getUser = async () => {
    try {
      const { signInUserSession } = await Auth.currentAuthenticatedUser();
      await fetchProfile(signInUserSession?.idToken?.jwtToken).then((res) => {
        const validUser = res?.user?.role === UserRoles.admin;
        if (validUser) {
          navigate("/partner");
          Storage.setItem(StorageKeys.AdminUser, res?.user?.role);
          handleBackDrop(false);
        } else {
          handleBackDrop(false);
          setError({ message: "Not a Valid Admin" });
          Storage.clear();
        }
      });
    } catch (error) {
      console.error("Error while user authentication:", error);
      setError(error || { message: error_msg.errorAuthLogin });
      handleBackDrop(false);
      Storage.clear();
    }
  };

  const signInWithEmailPass = async (email, password) => {
    Storage.clear();
    try {
      const _email = email?.trim();
      const _password = password?.trim();
      if (email && password) {
        Storage.clear();
        handleBackDrop(true);
        await Auth.signIn(_email, _password);
        getUser();
      }
    } catch (error) {
      console.error("Error while logging in:", error);
      setError(error || { message: error_msg.errorLoggingIn });
      handleBackDrop(false);
      Storage.clear();
    }
  };

  const handleLoginSubmit = (data) => {
    signInWithEmailPass(data.userId, data.password);
  };

  useEffect(() => {
    if (Storage.getItem(StorageKeys.AdminUser) === UserRoles.admin) {
      getUser();
    }
    // eslint-disable-next-line
  }, [Storage.getItem(StorageKeys.AdminUser)]);

  return (
    <StyledBox onSubmit={handleSubmit(handleLoginSubmit)}>
      <Backdrop
        sx={{ color: "#FFFF", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box>
        <FormHeader />
        <EmailSection control={control} />
        <PasswordSection
          control={control}
          setValue={setValue}
          isPassword={!togglePassword?.includes("showPassword")}
          error={error}
        />
      </Box>
      <Box>
        <GetHelp />
        <Button
          variant="contained"
          fullWidth
          size="large"
          sx={{
            height: {
              xs: 48,
              md: 64,
            },
          }}
          type="submit"
          onClick={handleSubmit(handleLoginSubmit)}
        >
          {labels.login}
        </Button>
      </Box>
    </StyledBox>
  );
};

export default AdminForm;
