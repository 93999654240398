import { queryKeys } from "../../constants/queryKeys";
import { getSettlementById } from "../../admin/action";
import { useQuery } from "@tanstack/react-query";

export const useFetchSettlementByIdQueryHook = (settlementId) => {
  const partnersSettlementById = useQuery(
    [queryKeys.queryFetchSettlementById, settlementId],
    () => getSettlementById(settlementId),
    {
      keepPreviousData: true,
    }
  );
  return partnersSettlementById;
};
