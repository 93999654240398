import Storage from "../utils/Storage";
import { StorageKeys } from "../../constants/storage-keys";
import { useEffect, useState } from "react";

export const useCrumb = () => {
  const [crumbs, setCrumbs] = useState(new Map());

  const handleCrumbStack = (key, value) => {
    const localCrumbs = Storage.getItem(StorageKeys.LOCAL_CRUMBS);
    Storage.setItem(StorageKeys.LOCAL_CRUMBS, { ...localCrumbs, [key]: value });
    setCrumbs((map) => new Map(map.set(key, value)));
  };

  useEffect(() => {
    const localCrumbs = Storage.getItem(StorageKeys.LOCAL_CRUMBS);
    if (localCrumbs && localCrumbs[window.location.pathname]) {
      handleCrumbStack(
        window.location.pathname,
        localCrumbs[window.location.pathname]
      );
    }
  }, []);

  return { crumbs, handleCrumbStack };
};
