import { approvePartner } from "../../admin/action";
import { useMutation } from "@tanstack/react-query";

export const useApprovePartnerMutateQuery = () => {
  const approvePartnerMutate = useMutation(({ partnerId, formData }) =>
    approvePartner(partnerId, formData)
  );

  return approvePartnerMutate;
};
