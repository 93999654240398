import {
  Drawer,
  IconButton,
  ThemeProvider,
  Toolbar,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { draggableDrawerTheme } from "../theme/draggableDrawerTheme";
import { globalTheme } from "../theme/globalTheme";
import { isMobileDevice } from "../../App";
import { useDrawer } from "./index";
import { useLocation } from "react-router-dom";

export const CustomDrawer = () => {
  const location = useLocation();
  const { hideDrawer, store } = useDrawer();
  const [open, setOpen] = useState(false);
  const { drawerProps } = store || {};
  const {
    component,
    title,
    height = "100%",
    closeIcon = true,
    anchor = isMobileDevice ? "bottom" : "right",
    onExitCallback = () => {},
    hideBackdrop = false,
  } = drawerProps || {};

  useEffect(() => {
    setOpen(true);
  }, []);

  useEffect(() => {
    if (open) hideDrawer();
  }, [location]);

  const handleModalToggle = () => {
    setOpen(false);
    onExitCallback();
    hideDrawer();
  };

  const drawerWidth = anchor === "top" || anchor === "bottom" ? "auto" : 520;

  return (
    <ThemeProvider theme={{ ...draggableDrawerTheme, ...globalTheme }}>
      <Drawer
        sx={{
          width: drawerWidth,
          position: "relative",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            borderLeft: hideBackdrop ? "1px solid #B7BEC7" : "none",
            height: height,
            borderTopLeftRadius: {
              xs: "8px",
              md: 0,
            },
            borderTopRightRadius: { xs: "8px", md: 0 },
            boxShadow: "0px 8px 24px rgba(21, 21, 22, 0.1)",
          },
        }}
        hideBackdrop={hideBackdrop}
        anchor={anchor}
        open={open}
        onClose={handleModalToggle}
        elevation={0}
      >
        {closeIcon ? (
          <Toolbar sx={{ m: 0, p: 2 }}>
            <Typography
              sx={{
                fontFamily: "inherit",
                fontSize: "20px",
                fontWeight: "500",
                lineHeight: "24px",
                letterSpacing: "0.002em",
              }}
            >
              {title}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleModalToggle}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        ) : null}
        {component}
      </Drawer>
    </ThemeProvider>
  );
};
