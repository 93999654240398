import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
  styled,
  Button,
} from "@mui/material";
import { East, ExpandMore } from "@mui/icons-material";
import PriceBenchmarks from "./PriceBenchmarks";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import moment from "moment";
import { useState, useRef } from "react";
import { useCustomDialog } from "../../shared/customDialog";
import {
  GoogleMap,
  DirectionsService,
  DirectionsRenderer,
  useLoadScript,
} from "@react-google-maps/api";

const StyledDivider = () => (
  <Divider orientation="vertical" sx={{ borderColor: "#B7BEC7", height: 38 }} />
);

export const StyledAccordion = styled(Accordion)(() => ({
  border: "0.4px solid #95A1B0",
  borderRadius: "8px !important",
  padding: "8px 0px",
  marginBottom: "0 !important",
  "&:before": {
    display: "none",
  },
}));

const TextBox = ({
  value,
  label,
  direction = false,
  gap = "4px",
  showMore = true,
  toggleShowMore,
}) => {
  return (
    <Box
      display={"flex"}
      sx={{
        flexDirection: direction
          ? "column"
          : {
              xs: "column-reverse",
              md: "column",
            },
      }}
      gap={gap}
    >
      <Typography
        sx={{
          fontSize: 13,
          fontWeight: 500,
          lineHeight: "18px",
        }}
      >
        {showMore ? (
          value
        ) : (
          <div
            onClick={toggleShowMore}
            style={{ display: showMore ? "none" : "" }}
          >
            {value.slice(0, 15)}
            {"..."}
          </div>
        )}
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: direction ? 13 : 12,
            md: 13,
          },
          fontWeight: {
            xs: direction ? 400 : 500,
            md: 400,
          },
          lineHeight: "18px",
        }}
        color={"text.secondary"}
      >
        {label}
      </Typography>
    </Box>
  );
};

const DateRangeBox = (data) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={"12px"}>
      <Box display={"flex"} gap={"19.3px"} alignItems={"center"}>
        <TextBox
          label={"Inbound date"}
          value={`${moment(data.inboundDate).format("DD MMMM YYYY")}`}
        />
        <East />
        <TextBox
          label={"Outbound date"}
          value={moment(data.outboundDate).format("DD MMMM YYYY")}
        />
      </Box>
    </Box>
  );
};

const GoogleMapContainer = ({ pickup, destination }) => {
  const [response, setResponse] = useState(null);

  const center = {
    lat: (pickup[0] + destination[0]) / 2,
    lng: (pickup[1] + destination[1]) / 2,
  };
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });

  let count = useRef(0);
  const directionsCallback = (res) => {
    if (res !== null && count.current < 2) {
      if (res.status === "OK") {
        count.current += 1;
        setResponse(res);
      }
    }
  };

  const fromLocation = {
    lat: pickup[0],
    lng: pickup[1],
  };

  const toLocation = {
    lat: destination[0],
    lng: destination[1],
  };

  if (!isLoaded || loadError)
    return (
      <Box
        sx={{
          display: {
            xs: "block",
            md: "flex",
          },
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
          height: "calc(100vh - 167px)",
          py: {
            xs: 12,
            md: 0,
          },
        }}
      >
        <span>
          {!isLoaded && "Loading Maps..."}
          {loadError && "Error loading maps"}
        </span>
      </Box>
    );
  return (
    <GoogleMap
      mapContainerStyle={{
        height: "55vh",
        width: "55vh",
      }}
      center={center}
    >
      <DirectionsService
        options={{
          destination: toLocation,
          origin: fromLocation,
          travelMode: "DRIVING",
        }}
        callback={directionsCallback}
      />
      {response !== null && <DirectionsRenderer directions={response} />}
    </GoogleMap>
  );
};

const PickupDetails = ({
  pickupDate,
  pickupLocation,
  destination,
  showMore,
  toggleShowMore,
}) => {
  const { showDialog } = useCustomDialog();
  return (
    <>
      <TextBox value={pickupDate} label={"Pickup date"} />
      <TextBox
        value={pickupLocation.address}
        label={"Pickup location"}
        showMore={showMore}
        toggleShowMore={toggleShowMore}
      />
      <TextBox
        value={destination.address}
        label={"Destination"}
        showMore={showMore}
        toggleShowMore={toggleShowMore}
      />
      <Button
        variant="link"
        disableRipple
        onClick={() =>
          showDialog({
            component: (
              <GoogleMapContainer
                pickup={pickupLocation.location.coordinates}
                destination={destination.location.coordinates}
              />
            ),
          })
        }
      >
        See map
      </Button>
    </>
  );
};

const DetailsGrid = ({ checkPartner, ...data }) => {
  return (
    <Box
      display={"flex"}
      sx={{
        borderTop: "0.5px solid #B7BEC7",
        paddingTop: 3,
        flexWrap: "wrap",
        gap: {
          xs: 2,
          md: 3,
        },
      }}
    >
      {checkPartner && (
        <>
          <TextBox
            label={"Material type"}
            value={data?.materialCategory?.displayName}
            direction="true"
          />
          <StyledDivider />
        </>
      )}
      <TextBox
        label={"Packaging"}
        value={data?.packagingType?.displayName}
        direction="true"
      />
      <StyledDivider />
      <TextBox
        label={"Dimensions & Units"}
        value={`${data?.packageDimension?.displayName} | ${data?.packageQuantity}pcs`}
        direction="true"
      />
      <StyledDivider />
      {!checkPartner && (
        <>
          <TextBox
            label={"Weight"}
            value={`${data?.totalWeight} Ton`}
            direction="true"
          />
          <StyledDivider />
        </>
      )}
      <TextBox
        label={`Area in ${data?.storageType?.name ?? "-"}`}
        value={`${data?.storageQuantity ?? "-"} ${
          data?.storageType?.name ?? "-"
        }`}
        direction="true"
      />
      {checkPartner && (
        <>
          <StyledDivider />
          <TextBox
            label={"Space type"}
            value={data?.storageType?.displayName}
            direction="true"
          />
        </>
      )}
    </Box>
  );
};

const InquiryDetails = ({ checkPartner, ...props }) => {
  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <>
      {/**
       * for mobile view
       */}
      <Box
        sx={{
          display: {
            xs: "flex",
            md: "none",
          },
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <StyledAccordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMore color="primary" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {checkPartner ? (
              <DateRangeBox {...props} />
            ) : (
              <PickupDetails
                pickupDate={moment(props.inboundDate).format("DD MMMM YYYY")}
                pickupLocation={props.pickup}
                destination={props.destination}
                showMore={showMore}
                toggleShowMore={toggleShowMore}
              />
            )}
          </AccordionSummary>
          <AccordionDetails
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <DetailsGrid {...props} checkPartner={checkPartner} />
            <Box marginTop={3} marginBottom={3}>
              <TextBox
                direction={true}
                value={"Item Description"}
                label={props?.itemDescription}
                gap={"8px"}
              />
            </Box>
          </AccordionDetails>
        </StyledAccordion>
        <Box
          sx={{
            border: "0.4px solid #95A1B0",
            borderRadius: "8px",
          }}
        >
          <PriceBenchmarks
            partners={props.partners}
            expectedPrice={props.customerExpectedPrice}
          />
        </Box>
      </Box>
      {/**
       * for desktop view
       */}
      <Box
        sx={{
          display: {
            xs: "none",
            md: "flex",
          },
        }}
      ></Box>
      <Box
        sx={{
          display: {
            xs: "none",
            md: "flex",
          },
          borderTop: "0.5px solid #B7BEC7",
          borderBottom: "0.5px solid #B7BEC7",
        }}
      >
        <Box
          sx={{
            borderRight: "0.5px solid #B7BEC7",
            display: "flex",
            width: "60%",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              padding: "40px 40px 24px 0",
              flex: 1,
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            {checkPartner ? (
              <DateRangeBox {...props} />
            ) : (
              <PickupDetails
                pickupDate={moment(props.inboundDate).format("DD MMMM YYYY")}
                pickupLocation={props.pickup}
                destination={props.destination}
                showMore={showMore}
                toggleShowMore={toggleShowMore}
              />
            )}
            <StyledChip label={props.status.name} />
          </Box>
          <Box marginBottom={5}>
            <DetailsGrid {...props} checkPartner={checkPartner} />
          </Box>
          <Box marginBottom={5} maxWidth={594} marginRight={1}>
            <TextBox
              value={"Item Description"}
              label={props?.itemDescription}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "40%",
            flexDirection: "column",
          }}
        >
          <PriceBenchmarks
            partners={props.partners}
            expectedPrice={props.customerExpectedPrice}
          />
        </Box>
      </Box>
    </>
  );
};

export default InquiryDetails;
