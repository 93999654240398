import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  styled
} from "@mui/material";
import { otherControlsHover, otherControlsResting } from "../theme/globalTheme";

import { Controller } from "react-hook-form";
import ImageUploadButton from "./CustomImageUpload";

const CustomIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 24,
  height: 24,
  backgroundColor: otherControlsResting,
  "input:hover ~ &": {
    backgroundColor: otherControlsHover
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "#EEF1F4"
  }
}));

const CustomIconChecked = styled(CustomIcon)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  "&:before": {
    display: "block",
    width: 24,
    height: 24,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""'
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.primary.dark
  }
}));

const ImageRadioGroup = ({
  helperText,
  name,
  filesName,
  control,
  options,
  disabled = false
}) => {
  const generateRadioOptions = () => {
    return options.map((option, index) => (
      <Box
        key={option._id}
        padding={"9px 0 9px 9px"}
        display={"flex"}
        sx={{
          border: "0.6px solid #B7BEC7",
          borderRadius: "8px"
        }}
      >
        <Box display={"flex"}>
          <img
            src={option.thumbUrl}
            alt={option.originalName}
            width={173}
            height={113}
            style={{
              borderRadius: "8px",
              objectFit: "cover"
            }}
          />
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={3} width={"100%"}>
          <Box paddingLeft={4} paddingRight={4}>
            <FormControlLabel
              value={option.value}
              label={option.label}
              labelPlacement="start"
              control={
                <Radio
                  checkedIcon={<CustomIconChecked />}
                  icon={<CustomIcon />}
                />
              }
              sx={{
                "& > .MuiTypography-root": {
                  color: "text.primary",
                  fontSize: 14,
                  marginRight: 2
                },
                marginLeft: 0
              }}
            />
          </Box>
          <Divider />
          <Box paddingLeft={4} paddingRight={4}>
            <ImageUploadButton
              name={filesName}
              control={control}
              fileId={option.value}
              disabled={disabled}
            />
          </Box>
        </Box>
      </Box>
    ));
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={""}
      render={({ field, fieldState: { error } }) => (
        <FormControl disabled={disabled}>
          <RadioGroup
            {...field}
            aria-labelledby={name}
            name={name}
            sx={{
              gap: "11px"
            }}
          >
            {generateRadioOptions()}
          </RadioGroup>
          {helperText && (
            <FormHelperText sx={{ letterSpacing: "0.1em" }}>
              {helperText}
            </FormHelperText>
          )}
          {error && (
            <FormHelperText
              error
              sx={{
                marginLeft: 0
              }}
            >
              {error.message}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default ImageRadioGroup;
