import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";

import { CalendarTodayOutlined } from "@mui/icons-material";
import CustomTable from "../../shared/customTable";
import DateInput from "../../shared/formElements/DateInput";
import { SearchFormKeys } from "../../constants/formKeys";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import { inquiry_table_labels } from "../../constants";
import { labels } from "../../constants";
import moment from "moment";
import { stringCapitalization } from "../../shared/utils";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { useFetchInquiriesQueryHook } from "../../query-hooks/Inquiries/useFetchInquiriesQueryHook";

const Inquiry = () => {
  const { control } = useForm({});
  const { ref, inView } = useInView({ threshold: 0 });
  const navigate = useNavigate();

  const { data, isFetched, hasNextPage, fetchNextPage } =
    useFetchInquiriesQueryHook();

  const getColumns = () => {
    return [
      {
        Header: "Inquiry ID",
        accessor: "code"
      },
      {
        Header: "Inquired On",
        accessor: "inquiryDate",
        Cell: ({ value }) => {
          return (
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "20px"
              }}
            >
              <Box>{moment(value).format("DD MMMM YYYY")}</Box>
              <Box>{moment(value).format("HH:mm")}</Box>
            </Typography>
          );
        }
      },
      {
        Header: "Service type",
        accessor: "serviceType"
      },
      {
        Header: "Status",
        accessor: "responseStatus",
        Cell: ({ value, cell }) => {
          return (
            <StyledChip
              label={stringCapitalization(
                cell.row.original.status === "Cancelled"
                  ? cell.row.original.status
                  : value
              )}
              onClick={() =>
                navigate(
                  cell.row.original.serviceType.includes("Warehouse")
                    ? `/inquiry/warehouse/${cell.row.original.id}`
                    : `/inquiry/transport/${cell.row.original.id}`
                )
              }
            />
          );
        }
      },
      {
        Header: "Respond time",
        accessor: "lastRespondedAt",
        Cell: ({ value }) => {
          if (value)
            return (
              <Typography
                sx={{ fontSize: "14px", fontWeight: 400, lineHeight: "20px" }}
              >
                {moment(value).fromNow()}
              </Typography>
            );
        }
      },
      {
        Header: "Customer",
        accessor: "mobile",
        Cell: ({ value }) => {
          return (
            <Typography
              sx={{ color: "#2773FF", fontWeight: 600, fontSize: "13px" }}
            >
              {value}
            </Typography>
          );
        }
      }
    ];
  };

  const getRows = (rows = []) => {
    let hasTransportRfq = rows.flatMap((key) =>
      key.hasOwnProperty("transportRfq")
    );
    return rows.map((item, index) => {
      const isOnlyTransport = !Boolean(item.warehouseRfq);
      let mainRow;
      if (isOnlyTransport) {
        mainRow = {
          serviceType: `Transporter (${item.transportRfq?.partners.length})`,
          status: item.transportRfq?.status.displayName,
          responseStatus: item.transportRfq?.responseStatus.displayName,
          transportRfq: item.transportRfq?._id,
          lastRespondedAt: item.transportRfq?.lastRespondedAt
        };
      } else {
        mainRow = {
          serviceType: `Warehouse (${item.warehouseRfq.partners.length})`,
          status: item.warehouseRfq.status.displayName,
          responseStatus: item.warehouseRfq.responseStatus.displayName,
          lastRespondedAt: item.warehouseRfq.lastRespondedAt,
          warehouseRfq: item.warehouseRfq._id
        };
      }
      return {
        id: item._id,
        code: item.code,
        inquiryDate: item.createdAt,
        ...mainRow,
        mobile: item.user.mobile,
        ...{
          ...(hasTransportRfq[index] && !isOnlyTransport
            ? {
                subRow: [
                  {
                    serviceType: `Transporter (${item.transportRfq?.partners.length})`,
                    status: item.transportRfq?.status.displayName,
                    responseStatus: (
                      <StyledChip
                        label={
                          item.transportRfq.status.displayName === "Cancelled"
                            ? item.transportRfq.status.displayName
                            : item.transportRfq.responseStatus.displayName
                        }
                        onClick={() =>
                          navigate(`/inquiry/transport/${item._id}`)
                        }
                      />
                    ),
                    transportRfq: item.transportRfq?._id,
                    lastRespondedAt: item.transportRfq?.lastRespondedAt
                      ? moment(item.transportRfq?.lastRespondedAt).fromNow()
                      : ""
                  }
                ]
              }
            : {})
        }
      };
    });
  };

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          container
          // spacing={8}
          display={"flex"}
          alignItems={"flex-end"}
          justifyContent={"space-between"}
        >
          <Grid item>
            <DateInput
              control={control}
              name={SearchFormKeys.FromDate}
              label={labels.fromDate}
              placeholder={labels.datePlaceholderText}
              endIcon={CalendarTodayOutlined}
            />
          </Grid>
          <Grid item>
            <DateInput
              control={control}
              name={SearchFormKeys.ToDate}
              label={labels.toDate}
              placeholder={labels.datePlaceholderText}
              endIcon={CalendarTodayOutlined}
            />
          </Grid>
          <Grid item>
            <Button variant="contained" sx={{ height: "45px", width: "120px" }}>
              {inquiry_table_labels.view}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" sx={{ height: "45px", width: "120px" }}>
              {inquiry_table_labels.clear}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" sx={{ height: "45px", width: "120px" }}>
              {inquiry_table_labels.download}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CustomTable
            searchPlaceholderText={inquiry_table_labels.searchPlaceholderText}
            columns={getColumns()}
            data={
              isFetched
                ? data?.pages.flatMap((item) => getRows(item.inquiries))
                : []
            }
            isLoading={!isFetched}
          />
        </Grid>
        {hasNextPage && (
          <Grid
            item
            xs={12}
            ref={ref}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Inquiry;
