import { globalTheme, otherControlsResting } from "./globalTheme";

import { createTheme } from "@mui/material";

export const bottomAppBarTheme = createTheme({
  ...globalTheme,
  components: {
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
        sx: {
          top: "auto",
          bottom: 0,
          display: {
            sm: "none",
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "70px",
        },
      },
      defaultProps: {
        sx: {
          display: {
            sm: "none",
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        sx: {
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "16px",
          color: otherControlsResting,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.secondary.light,
          fontSize: 20,
          marginBottom: 3,
        }),
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: "3px 6px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          "&.Mui-selected": {
            "& > .MuiSvgIcon-root": {
              color: "white",
            },
            "& > .MuiTypography-root": {
              color: "white",
              fontWeight: 500,
            },
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          "& > .MuiBox-root": {
            textDecoration: "none",
          },
        },
      },
    },
  },
});
