import * as yup from "yup";
import { Button, Grid, Typography, CircularProgress } from "@mui/material";
import { useCallback, useEffect } from "react";
import CustomTable from "../../shared/customTable";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import { SearchFormKeys, changeStatusKeys } from "../../constants/formKeys";
import DateInput from "../../shared/formElements/DateInput";
import { CalendarTodayOutlined } from "@mui/icons-material";
import StatusChangeDailog from "../../shared/UiElements/StatusChangeDailog";
import {
  labels,
  AddPartner_labels,
  partner_table_labels,
  inquiry_table_labels,
  statusKeys,
  warehouse_labels
} from "../../constants";
import { useForm } from "react-hook-form";
import { useCustomDialog } from "../../shared/customDialog";
import moment from "moment";
import { useFetchOrdersQueryHook } from "../../query-hooks/Orders/useFetchOrdersQueryHook";
import styled from "@emotion/styled";
import { useInView } from "react-intersection-observer";
import { currencyFormatter } from "../../shared/utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { enqueueSnackbar } from "notistack";
import { useUpdateCustomerStatus } from "../../query-hooks/Orders/useUpdateCustomerStatus";

export const StyledTableText = styled(Typography)(({ theme }) => ({
  color: "#2773FF",
  fontWeight: "700"
}));

export const statusFormSchema = yup.object({
  [changeStatusKeys.status]: yup
    .string()
    .required(`Status ${warehouse_labels.isRequired}`)
});

const SuccessAlert = ({
  orderId,
  control,
  hideDialog,
  label,
  onSubmit,
  options,
  isSubmitting
}) => {
  return (
    <StatusChangeDailog
      orderId={orderId}
      control={control}
      subText1={"Customer is able to see the status of this order as"}
      subText2={"Do you want to manually over-ride this status into ?"}
      subText3={
        "This will send notifications and make other changes at Partner."
      }
      label={label}
      btn1Text={AddPartner_labels.yesConfirm}
      btn2Text={AddPartner_labels.goBack}
      btn1Callback={onSubmit}
      btn2Callback={hideDialog}
      isSubmitting={isSubmitting}
      options={options}
    />
  );
};

const getColumns = (onAction) => {
  return [
    {
      Header: "Order ID",
      accessor: "OrderCode",
      Cell: ({ value }) => {
        return <StyledTableText>{value}</StyledTableText>;
      }
    },
    {
      Header: "Partner ID",
      accessor: "partnerCode",
      Cell: ({ value }) => {
        return <StyledTableText>{value}</StyledTableText>;
      }
    },
    {
      Header: "Customer",
      accessor: "mobile",
      Cell: ({ value }) => {
        return <StyledTableText>{value}</StyledTableText>;
      }
    },
    {
      Header: "Order date",
      accessor: "createdAt"
    },
    {
      Header: "Inbound/Pickup Date",
      accessor: "inboundDate"
    },
    {
      Header: "Outbound Date",
      accessor: "outboundDate"
    },
    {
      Header: "Material",
      accessor: "material"
    },
    {
      Header: "Order Amount",
      accessor: "orderAmount"
    },
    {
      Header: "Status",
      accessor: "orderStatus",
      Cell: ({ row, value }) => {
        return (
          <StyledChip
            label={value}
            onClick={() => {
              value !== statusKeys.Stored && onAction(row.original);
            }}
          />
        );
      }
    }
  ];
};

const getRows = (rows = [], onAction) => {
  let hasTransportOrder = rows.flatMap((key) =>
    key.hasOwnProperty("transportOrder")
  );
  return rows.map((item, index) => {
    const isOnlyTransport = !Boolean(item.warehouseOrder);
    let mainRow;
    if (isOnlyTransport) {
      mainRow = {
        OrderCode: item.transportOrder.code,
        partnerCode: item.transportOrder.partner.partnerId.code,
        orderStatus: item.transportOrder.transportStatus.name
      };
    } else {
      mainRow = {
        OrderCode: item.warehouseOrder.code,
        partnerCode: item.warehouseOrder.partner.partnerId.code,
        orderStatus: item.warehouseOrder.warehouseStatus.name
      };
    }
    return {
      orderId: item._id,
      mobile: item.user.mobile,
      createdAt: moment(item.createdAt).format("DD.MM.YY"),
      inboundDate: moment(item.recentOrderItem.inboundDate).format("DD.MM.YY"),
      outboundDate:
        moment(item.recentOrderItem.outboundDate).format("DD.MM.YY") ?? "--",
      material: item.searchForm?.materialCategory?.displayName || "--",
      orderAmount: currencyFormatter(
        parseFloat(item.recentOrderItem.totalAmount).toFixed(2)
      ),
      ...mainRow,
      ...{
        ...(hasTransportOrder[index] && !isOnlyTransport
          ? {
              subRow: [
                {
                  OrderCode: (
                    <StyledTableText>
                      {item.transportOrder.code}
                    </StyledTableText>
                  ),
                  partnerCode: (
                    <StyledTableText>
                      {item.transportOrder.partner.partnerId.code}
                    </StyledTableText>
                  ),
                  orderStatus: (
                    <StyledChip
                      label={item.transportOrder.transportStatus.name}
                      onClick={() =>
                        onAction({
                          orderId: item._id,
                          orderStatus: item.transportOrder.transportStatus.name
                        })
                      }
                    />
                  )
                }
              ]
            }
          : {})
      }
    };
  });
};

const MyOrders = () => {
  const { control, setValue, handleSubmit } = useForm({
    resolver: yupResolver(statusFormSchema)
  });
  const { showDialog, hideDialog } = useCustomDialog();
  const { ref, inView } = useInView({ threshold: 0 });

  const { data, isFetched, hasNextPage, fetchNextPage, refetch } =
    useFetchOrdersQueryHook();

  const { mutate, isError, error, isLoading } = useUpdateCustomerStatus();

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(error?.response.data.message, { variant: "error" });
    }
  }, [error?.response.data.message, isError]);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  const submitData = useCallback(
    (data, orderId) => {
      mutate(
        {
          orderId: orderId,
          formData: {
            customerStatus: data[changeStatusKeys.status]
          }
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Order Status Changed", {
              variant: "success"
            });
            refetch();
            hideDialog();
          }
        }
      );
    },
    [mutate, hideDialog, refetch]
  );

  const onSubmit = useCallback(
    (orderId) => {
      handleSubmit(submitData)(orderId);
    },
    [handleSubmit, submitData]
  );

  const onAction = useCallback(
    (row) => {
      showDialog({
        component: (
          <SuccessAlert
            orderId={row.orderId}
            control={control}
            hideDialog={() => {
              setValue(changeStatusKeys.status, "");
              hideDialog();
            }}
            label={row.orderStatus}
            isSubmitting={isLoading}
            onSubmit={() => onSubmit(row.orderId)}
          />
        ),
        size: "xs",
        backdropOff: true
      });
    },
    [control, showDialog, hideDialog, setValue, onSubmit, isLoading]
  );

  return (
    <Grid container spacing={4}>
      <Grid
        item
        xs={12}
        container
        display={"flex"}
        alignItems={"flex-end"}
        justifyContent={"space-between"}
      >
        <Grid item>
          <DateInput
            control={control}
            name={SearchFormKeys.FromDate}
            label={labels.fromDate}
            placeholder={labels.datePlaceholderText}
            endIcon={CalendarTodayOutlined}
          />
        </Grid>
        <Grid item>
          <DateInput
            control={control}
            name={SearchFormKeys.ToDate}
            label={labels.toDate}
            placeholder={labels.datePlaceholderText}
            endIcon={CalendarTodayOutlined}
          />
        </Grid>
        <Grid item>
          <Button variant="contained" sx={{ height: "45px", width: "120px" }}>
            {inquiry_table_labels.view}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" sx={{ height: "45px", width: "120px" }}>
            {inquiry_table_labels.clear}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" sx={{ height: "45px", width: "120px" }}>
            {inquiry_table_labels.download}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          searchPlaceholderText={partner_table_labels.searchPartnerText}
          columns={getColumns(onAction)}
          data={
            isFetched
              ? data?.pages.flatMap((page) => getRows(page?.orders, onAction))
              : []
          }
          isLoading={!isFetched}
        />
      </Grid>
      {hasNextPage && (
        <Grid
          item
          xs={12}
          ref={ref}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Grid>
      )}
    </Grid>
  );
};

export default MyOrders;
