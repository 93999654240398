import "./App.css";

import {
  Box,
  CircularProgress,
  CssBaseline,
  ThemeProvider,
} from "@mui/material";
import { Routes, useLocation } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import { publicRoutes, privateRoutes } from "./shared/navigation/routes";
import { ErrorBoundary } from "react-error-boundary";
import { AuthContext } from "./shared/context/auth-context";
import BackNavigation from "./shared/navigation/BackNavigation";
import BottomMobileAppBar from "./shared/layouts/BottomMobileAppBar";
import { CustomDialog } from "./shared/customDialog";
import { CustomSideDrawer } from "./shared/customSideDrawer";
import Header from "./shared/layouts/Header";
import SideDrawer from "./shared/layouts/SideDrawer";
import { drawerWidth, drawerWidthProfile } from "./shared/theme/drawerTheme";
import { globalTheme } from "./shared/theme/globalTheme";
import { useAuth } from "./shared/hooks/useAuth";
import { useCrumb } from "./shared/hooks/useCrumb";
import SomethingWentWrong from "./shared/UiElements/SomethingWentWrong";
import { Suspense } from "react";
import { SnackbarProvider } from "notistack";

export const isMobileDevice = isMobile || isTablet;

function App() {
  const { logout, currentUser } =
    useAuth();
  const { crumbs, handleCrumbStack } = useCrumb();
  const location = useLocation();

  const isRoot = ["/"].includes(location.pathname);

  const logError = (error, info) => {
    console.error(error);
    console.info(info);
  };

  return (
    <ErrorBoundary
      FallbackComponent={SomethingWentWrong}
      onReset={(details) => console.log(details)}
      onError={logError}
    >
      <ThemeProvider theme={globalTheme}>
        <AuthContext.Provider
          value={{
            logout,
            crumbsStack: crumbs,
            setCrumbStack: handleCrumbStack,
            currentUser,
          }}
        >
          <Suspense
            fallback={
              <Box
                padding={2}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CircularProgress />
              </Box>
            }
          >
            <SnackbarProvider>
              <CustomDialog>
                <CustomSideDrawer>
                  <Box>
                    <CssBaseline />
                    <Header />
                    {currentUser && !isRoot && <SideDrawer />}
                    <Box
                      component="main"
                      sx={{
                        width: {
                          sm:
                            !isRoot && currentUser
                              ? `calc(100% - ${
                                  window.location.pathname.includes("/profile")
                                    ? drawerWidthProfile
                                    : drawerWidth
                                }px)`
                              : "100%",
                          xs: "100%",
                        },
                        ml: {
                          sm:
                            !isRoot && currentUser
                              ? `${
                                  window.location.pathname.includes("/profile")
                                    ? drawerWidthProfile
                                    : drawerWidth
                                }px`
                              : "auto",
                          xs: "auto",
                        },
                        padding: {
                          sm: isRoot ? 0 : "24px 40px 0px 40px",
                          xs: "0",
                        },
                        mt: isRoot ? 0 : isMobile ? "60px" : 9,
                      }}
                    >
                      {currentUser && !isRoot && <BackNavigation />}
                      <Routes>
                        {currentUser ? privateRoutes : publicRoutes}
                      </Routes>
                    </Box>
                    {currentUser && !isRoot && <BottomMobileAppBar />}
                  </Box>
                </CustomSideDrawer>
              </CustomDialog>
            </SnackbarProvider>
          </Suspense>
        </AuthContext.Provider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
