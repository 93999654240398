import * as yup from "yup";
import { NewPartnerFormKeys } from "../../constants/formKeys";
import { error_msg } from "../../constants";

export const dynamicFormShape = yup.lazy((value) => {
  const shapes = {};
  const DATA_OBJ_KEYS = Object.keys(value);
  DATA_OBJ_KEYS.forEach((parameter) => {
    shapes[parameter] = yup
      .string()
      .required(`${parameter} ${NewPartnerFormKeys.isRequired}`);
  });
  return yup.object().shape(shapes);
});

export const formSchema = yup.object({
  [NewPartnerFormKeys.partnerCode]: yup.string().required(error_msg.required),
  [NewPartnerFormKeys.partnerName]: yup.string().required(error_msg.required),
  [NewPartnerFormKeys.signupDate]: yup.string().required(error_msg.required),
  [NewPartnerFormKeys.partnerCity]: yup.string().required(error_msg.required),
  [NewPartnerFormKeys.partnerCluster]: yup
    .string()
    .required(error_msg.required),
  [NewPartnerFormKeys.partnerType]: yup.string().required(error_msg.required),
  [NewPartnerFormKeys.partnerCommission]: yup
    .string()
    .required(error_msg.required)
    .matches(/^[0-9]\d*(\.\d+)?$/, error_msg.onlyDigits),
  [NewPartnerFormKeys.registerMobile]: yup
    .string()
    .required(error_msg.required)
    .matches(/^[0-9]+$/, error_msg.onlyDigits)
    .min(10, error_msg.enterLeast10Digits)
    .max(10, error_msg.enterOnly10Digits),
  [NewPartnerFormKeys.registerEmail]: yup.string().required(error_msg.required),
  [NewPartnerFormKeys.ownerKdmName]: yup.string().required(error_msg.required),
  [NewPartnerFormKeys.ownerKdmContact]: yup
    .string()
    .required(error_msg.required)
    .matches(/^[0-9]+$/, error_msg.onlyDigits)
    .min(10, error_msg.enterLeast10Digits)
    .max(10, error_msg.enterOnly10Digits),
  [NewPartnerFormKeys.pocName]: yup.string().required(error_msg.required),
  [NewPartnerFormKeys.pocContact]: yup
    .string()
    .required(error_msg.required)
    .matches(/^[0-9]+$/, error_msg.onlyDigits)
    .min(10, error_msg.enterLeast10Digits)
    .max(10, error_msg.enterOnly10Digits),
  [NewPartnerFormKeys.bdmOnboarded]: yup.string().required(error_msg.required),
  [NewPartnerFormKeys.bdmContact]: yup
    .string()
    .required(error_msg.required)
    .matches(/^[0-9]+$/, error_msg.onlyDigits)
    .min(10, error_msg.enterLeast10Digits)
    .max(10, error_msg.enterOnly10Digits),
  [NewPartnerFormKeys.accountType]: yup.string().required(error_msg.required),
  [NewPartnerFormKeys.bankName]: yup.string().required(error_msg.required),
  [NewPartnerFormKeys.accountName]: yup.string().required(error_msg.required),
  [NewPartnerFormKeys.accountNumber]: yup.string().required(error_msg.required),
  [NewPartnerFormKeys.ifscCode]: yup.string().required(error_msg.required),
  [NewPartnerFormKeys.gstinNo]: yup
    .string()
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      "Enter a correct GST number"
    )
    .required(error_msg.required),
  [NewPartnerFormKeys.panNo]: yup.string().required(error_msg.required),
  [NewPartnerFormKeys.gstReg]: yup.string().required(error_msg.required),
  [NewPartnerFormKeys.specialNotes]: yup.string(),
});
