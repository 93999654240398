import { ChevronRightOutlined, ExpandMoreOutlined } from "@mui/icons-material";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ThemeProvider,
} from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import { collapseMenuTheme } from "../theme/drawerTheme";

export function NavLinkItem({
  to,
  from,
  name,
  icon,
  isProfileScreen,
  children,
}) {
  const location = useLocation();
  const [subMenuList, setSubMenuList] = useState(new Map());
  const [selectedLinkOnSubMenuClick, setSelectedLinkOnSubMenuClick] =
    useState("");

  const Icon = icon;

  const handleCollapsibleMenu = () => {
    setSubMenuList((map) => new Map(map.set(from, !map.get(from))));
    /**
     * remove currentSelectedSubMenuLink from sessionStorage on click of nav items which don't have submenus,
     * to avoid setting active state of these elements
     */
    if (!from) sessionStorage.removeItem("currentSelectedSubMenuLink");
  };

  let activeSelectedLink;

  if (to === "/") {
    activeSelectedLink = to;
  } else {
    activeSelectedLink = `/${to}`;
  }

  const handleSubMenuActiveItem = (link) => {
    setSelectedLinkOnSubMenuClick(link);
    /**
     * setting the sub menu location pathname to sessionStorage to set the active style on submenu parent element
     * on page refresh when any submenu is already in selection state
     */
    sessionStorage.setItem("currentSelectedSubMenuLink", JSON.stringify(link));
  };

  /**
   * set active state for the parent of submenu on load or refresh from sessionStorage
   */
  useEffect(() => {
    const storedCurrentSelectedSubMenuLink = JSON.parse(
      sessionStorage.getItem("currentSelectedSubMenuLink")
    );
    if (from && storedCurrentSelectedSubMenuLink) {
      setSelectedLinkOnSubMenuClick(location.pathname);
      setSubMenuList((map) => new Map(map.set(from, true)));
    }
  }, [from, location.pathname]);

  return (
    <>
      <ListItemButton
        component={RouterLink}
        to={to}
        selected={
          isProfileScreen
            ? location.pathname === to
            : activeSelectedLink.includes(location.pathname.split("/")[1]) ||
              location.pathname === selectedLinkOnSubMenuClick
        }
        sx={{ margin: 1, borderRadius: 2, mx: isProfileScreen ? 6 : 1 }}
        onClick={handleCollapsibleMenu}
      >
        {!isProfileScreen && (
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
        )}
        <ListItemText primary={name} />
        {Boolean(children) &&
          (!subMenuList.get(from) ? (
            <ChevronRightOutlined
              sx={{ color: (theme) => theme.palette.text.secondary }}
            />
          ) : (
            <ExpandMoreOutlined
              sx={{ color: (theme) => theme.palette.text.secondary }}
            />
          ))}
      </ListItemButton>
      {children && (
        <ThemeProvider theme={collapseMenuTheme}>
          <Collapse
            in={subMenuList.get(from)}
            timeout="auto"
            unmountOnExit={true}
          >
            <List component="div" disablePadding>
              {children.map((item) => (
                <ListItemButton
                  key={item.to}
                  component={RouterLink}
                  to={`${from}/${item.to}`}
                  selected={`/${from}/${item.to}`.includes(
                    location.pathname.split("/")[2]
                  )}
                  onClick={() => handleSubMenuActiveItem(`/${from}/${item.to}`)}
                >
                  <ListItemText primary={item.name} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </ThemeProvider>
      )}
    </>
  );
}
