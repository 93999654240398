import { createContext } from "react";

export const AuthContext = createContext({
  // isLoggedIn: false,
  // token: null,
  // idToken: null,
  // login: () => {},
  logout: () => {},
  // setLoginToken: () => {},
  crumbsStack: new Map(),
  setCrumbStack: () => {},
  currentUser: null,
});
