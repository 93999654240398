import { updateWarehouseHeroImage } from "../../admin/action";
import { useMutation } from "@tanstack/react-query";

export const useUpdateWarehouseHeroImage = () => {
  const updateWarehouseHeroImageMutate = useMutation(({ partnerId, fileId }) =>
    updateWarehouseHeroImage(partnerId, fileId)
  );

  return updateWarehouseHeroImageMutate;
};
