import { Box, Button, styled } from "@mui/material";

import CloudIcon from "../../assets/icons/Upload.svg";
import { Controller } from "react-hook-form";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1
});

const ImageUploadButton = ({ name, control, fileId, disabled }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={""}
      render={({ field, fieldState: { error } }) => (
        <Button
          component="label"
          variant="text"
          sx={{
            whiteSpace: "nowrap",
            fontSize: 14,
            fontWeight: 600,
            display: "flex",
            gap: "12px",
            border: "1px dashed #4C4C4C",
            borderRadius: "8px",
            width: "fit-content"
          }}
          disabled={disabled}
        >
          <Box display={"flex"}>
            <img src={CloudIcon} alt="upload" />
          </Box>
          {"Add/ Replace photos"}
          <VisuallyHiddenInput
            type="file"
            accept=".jpeg,.png,.jpg"
            onChange={(e) => {
              const target = e.target;

              if (!target.files[0]) return;

              field.onChange({ fileId, file: target.files[0] });
            }}
          />
        </Button>
      )}
    />
  );
};

export default ImageUploadButton;
