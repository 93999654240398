import { Box, Container, Grid } from "@mui/material";

import AdminForm from "../pages/AdminForm";

const AdminSection = () => {
  return (
    <Box
      position={"relative"}
      sx={{
        mt: {
          xs: 0,
          md: "-80px",
        },
        maxHeight: {
          xs: 465,
          md: "100%",
        },
      }}
    >
      {" "}
      <Box
        sx={{
          position: "relative",
          bottom: {
            xs: 270,
            md: 0,
          },
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            padding: {
              xs: 0,
              md: "0 95px !important",
            },
          }}
        >
          <Grid container justifyContent={"center"}>
            <Grid item display={"flex"} alignItems={"center"}>
              <Box
                sx={{
                  padding: {
                    xs: "0 20px 0 10px",
                    md: 0,
                  },
                  mt: {
                    xs: "200px",
                    md: 0,
                  },
                }}
              >
                <AdminForm/>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default AdminSection;
