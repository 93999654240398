import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { ProfileFormKeys } from "../../constants/formKeys";
import TextInput from "../../shared/formElements/TextInput";
import { profile_labels } from "../../constants";
// import { useFetchProfileQueryHook } from "../../query-hooks/useFetchProfileQueryHook";
import { useForm } from "react-hook-form";

const VerifiedPhone = () => {
  return (
    <Box display={"flex"} alignItems={"center"} px={2}>
      <CheckCircleOutlineIcon
        fontSize="16"
        sx={{ mr: "4px", cursor: "pointer", color: "#0F8B6C" }}
      />
      <Typography
        color={"#0F8B6C"}
        fontWeight={400}
        fontSize={12}
        lineHeight={"16px"}
        whiteSpace={"nowrap"}
      >
        {profile_labels.verified}
      </Typography>
    </Box>
  );
};

const Header = ({ isEdit, onEdit }) => (
  <Box display={"flex"} flexDirection={"column"} gap={2}>
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Typography
        sx={{
          fontSize: 20,
          fontWeight: 500,
          letterSpacing: "0.04px"
        }}
      >
        {"Personal details"}
      </Typography>
      <Box display={"flex"} gap={1}>
        {isEdit ? (
          <>
            <Button
              variant="soft"
              sx={{ height: 48, width: 140 }}
              onClick={onEdit}
            >
              {profile_labels.cancel}
            </Button>
            <Button variant="contained" sx={{ height: 48, width: 140 }}>
              {profile_labels.saveChanges}
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            sx={{ height: 48, width: 140 }}
            onClick={onEdit}
          >
            {profile_labels.edit}
          </Button>
        )}
      </Box>
    </Box>
    <Divider sx={{ borderColor: "#B7BEC7" }} />
  </Box>
);

const ProfileForm = ({ control, setValue }) => {
  return (
    <Box maxWidth={573} display={"flex"} flexDirection={"column"} gap={"60px"}>
      <Grid container spacing={"20px"}>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextInput
              control={control}
              name={ProfileFormKeys.FirstName}
              label={profile_labels.firstNameLabelText}
              placeholder={profile_labels.firstNamePlaceholderText}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              control={control}
              name={ProfileFormKeys.LastName}
              label={profile_labels.lastNameLabelText}
              placeholder={profile_labels.lastNamePlaceholderText}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextInput
              control={control}
              name={ProfileFormKeys.Phone}
              label={profile_labels.phoneLabelText}
              placeholder={profile_labels.phonePlaceholderText}
              startIcon={<Box padding={"0 0 0 16px"}>+91</Box>}
              endIcon={VerifiedPhone}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              control={control}
              name={ProfileFormKeys.Email}
              label={profile_labels.emailLabelText}
              placeholder={profile_labels.emailPlaceholderText}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const Profile = () => {
  const [isEdit, setIsEdit] = useState(false);
  const { control, setValue, reset } = useForm({});

  // const { data: profileData , isFetching} = useFetchProfileQueryHook();

  // useEffect(() => {
  //   if (!isFetching) {
  //     reset({ [ProfileFormKeys.Phone]: profileData.user.mobile });
  //   }
  // }, [isFetching, profileData?.user.mobile, reset]);

  const handleFormEdit = () => {
    setIsEdit((prevEdit) => !prevEdit);
  };

  return (
    <Box
      maxWidth={875}
      display={"flex"}
      flexDirection={"column"}
      gap={5}
      sx={{
        padding: {
          xs: 2,
          md: "24px 0 24px 0"
        },
        margin: "auto"
      }}
    >
      <Header isEdit={isEdit} onEdit={handleFormEdit} />
      <ProfileForm control={control} setValue={setValue} />
    </Box>
  );
};

export default Profile;
