import { updateWarehousePartner } from "../../admin/action";
import { useMutation } from "@tanstack/react-query";

export const useMutateWarehousePartnerQuery = () => {
  const mutateWarehousePartner = useMutation(({ partnerId, formData }) =>
    updateWarehousePartner(partnerId, formData)
  );

  return mutateWarehousePartner;
};
