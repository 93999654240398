const appRoutes = {
  home: "/",
  pdf: "/invoiceDetails",
  partner: {
    main: "/partner",
    addPartner: "/partner/addPartner",
    editPartnerDetails: "/partner/editPartnerDetails/:partnerId"
  },
  customers: {
    main: "/customers"
  },
  profile: {
    main: "/profile",
    changePassword: "/profile/change-password",
    helpAndSupport: "/profile/help-and-support",
    faqs: "/profile/faqs",
    tnc: "/profile/terms-and-conditions"
  },
  inquiry: {
    inquiryListView: "/inquiry",
    inquiryDetails: "/inquiry/:partnerType/:inquiryId"
  },
  orders: {
    main: "/orders",
    myOrdersListView: "my-orders",
    invoicesDocsListView: "invoices-docs"
  }
};

export default appRoutes;
