import * as yup from "yup";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import CustomTable from "../../shared/customTable";
import { useFieldArray, useForm } from "react-hook-form";
import TextInput from "../../shared/formElements/TextInput";
import CheckBoxSelect from "../../shared/formElements/CheckboxSelect";
import DropDown from "../../shared/formElements/DropDown";
import {
  AddOutlined,
  Cancel,
  Delete,
  Save,
  BorderColorOutlined
} from "@mui/icons-material";
import { CustomTooltip } from "../../shared/theme/globalTheme";
import SuccessfulDialog from "../../shared/UiElements/SuccessfulDialog";
import { useCustomDialog } from "../../shared/customDialog";
import styled from "@emotion/styled";
import { useFetchCreateSubUserFormData } from "../../query-hooks/SubUsers/useFetchCreateSubUserFormData";
import { useFetchAllSubUsers } from "../../query-hooks/SubUsers/useFetchAllSubUsers";
import { useCreateSubUsers } from "../../query-hooks/SubUsers/useCreateSubUsers";
import { enqueueSnackbar } from "notistack";
import { useDeleteSubUser } from "../../query-hooks/SubUsers/useDeleteSubUser";
import { useFetchUpdateSubUserFormData } from "../../query-hooks/SubUsers/useFetchUpdateSubUserFormData";
import { error_msg } from "../../constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUpdateSubUser } from "../../query-hooks/SubUsers/useUpdateSubUser";

export const TitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 20,
  fontFamily: "Inter",
  lineHeight: "24.2px",
  fontWeight: 500
}));

export const ActionIcons = ({
  type = "default",
  status = "",
  isEditEnabled = false,
  newIndex = false,
  DeleteClick,
  removeNewRow,
  EditClick,
  SaveClick,
  AddClick,
  editEnabledforOne
}) => {
  const actionType = {
    delete: {
      icon: Delete,
      tooltip: "Delete",
      color: "#F05454",
      callback: DeleteClick
    },
    edit: {
      icon: BorderColorOutlined,
      tooltip: "Edit",
      color: "#F9A90E",
      callback: EditClick
    },
    cancel: {
      icon: Cancel,
      tooltip: "Cancel",
      color: "#F05454",
      callback: newIndex ? removeNewRow : EditClick
    },
    save: {
      icon: Save,
      tooltip: "Save",
      color: "#16c79a",
      callback: SaveClick
    },
    add: {
      label: "Add",
      callback: AddClick
    }
  };
  const iconObject = {
    default: {
      [false]: [actionType.add, actionType.cancel]
    },
    existingUser: newIndex
      ? { [false]: [] }
      : {
          [false]: [actionType.save, actionType.cancel],
          [true]: editEnabledforOne ? [] : [actionType.edit, actionType.delete]
        },
    newSubscription: { [false]: [actionType.add, actionType.cancel] },
    presentSubscription: newIndex
      ? { [false]: [] }
      : {
          [false]: [actionType.save, actionType.cancel],
          [true]: []
        }
  };
  return iconObject[type][isEditEnabled]?.map((item, index) => {
    const data =
      iconObject[type][isEditEnabled] && iconObject[type][isEditEnabled][index];
    let buttonComponent = "";
    const Icon = data?.icon;
    buttonComponent =
      status !== "Expired" &&
      (data?.label ? (
        <Button variant={"link"} onClick={data?.callback} key={index}>
          {data?.label}
        </Button>
      ) : (
        <IconButton onClick={data?.callback} key={index}>
          <CustomTooltip title={data?.tooltip} placement="top">
            <Icon sx={{ color: data.color }} />
          </CustomTooltip>
        </IconButton>
      ));
    return buttonComponent;
  });
};

const userRoleSchema = (isNewRow) => {
  const baseSchema = {
    users: yup.array().of(
      yup.object().shape({
        userName: yup.string().required(error_msg.required),
        mobileNo: yup.string().required(error_msg.required),
        userAccess: yup
          .array()
          .of(yup.string())
          .min(1, "Select atleast one")
          .required(error_msg.required),
        ...{
          ...(isNewRow
            ? {}
            : {
                status: yup.string().required(error_msg.required),
                userRole: yup.string().required(error_msg.required)
              })
        }
      })
    )
  };

  return yup.object(baseSchema);
};

const getColumns = (
  onDeleteClick,
  removeNewRow,
  onEditClick,
  onSaveClick,
  onAddClick,
  newRowAdded,
  editEnabledforOne
) => {
  return [
    {
      Header: "S.No",
      accessor: "sNo",
      width: 60,
      disableSortBy: true,
      Cell: ({ value }) => {
        return (
          value && (
            <Typography fontSize={14} fontWeight={600} lineHeight={"17px"}>
              {`${value}.`}
            </Typography>
          )
        );
      }
    },
    {
      Header: "User Name",
      accessor: "userName",
      width: 160
    },
    {
      Header: "Mobile No.",
      accessor: "mobileNo",
      width: 160
    },
    {
      Header: "User Access",
      accessor: "userAccess",
      width: 160
    },
    {
      Header: "User Role",
      accessor: "userRole",
      width: 160
    },
    {
      Header: "Status",
      accessor: "status",
      disableSortBy: true,
      width: 120
    },
    {
      Header: "Action",
      accessor: "actions",
      disableSortBy: true,
      width: 130,
      Cell: ({ row, value }) => {
        return (
          <Box display={"flex"} gap={"12px"}>
            <ActionIcons
              type={row?.original?.type}
              isEditEnabled={row?.original.inputDisable}
              newIndex={newRowAdded}
              DeleteClick={() => onDeleteClick(row?.original)}
              removeNewRow={() => removeNewRow(row?.original.sNo - 1)}
              EditClick={() => onEditClick(row?.original.sNo - 1)}
              SaveClick={onSaveClick}
              AddClick={onAddClick}
              editEnabledforOne={editEnabledforOne}
            />
          </Box>
        );
      }
    }
  ];
};

const getRows = (
  rows = [],
  control,
  ScModuleOptions,
  newRowAdded,
  isFetchingDropDown,
  updateFormOptions
) => {
  return rows.map((item, index) => {
    return {
      _id: item?._id,
      sNo: item.sNo,
      userName:
        item.inputDisable && item.userName ? (
          item.userName
        ) : (
          <TextInput
            control={control}
            name={`users.${index}.userName`}
            disabled={item.inputDisable}
          />
        ),
      mobileNo: item.mobileNo ? (
        item.mobileNo
      ) : (
        <TextInput
          control={control}
          name={`users.${index}.mobileNo`}
          disabled={item.inputDisable}
        />
      ),
      userAccess:
        item.inputDisable && item.userAccess ? (
          <ul>
            {item.userAccess?.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        ) : (
          <CheckBoxSelect
            control={control}
            placeholder={"Select Modules"}
            name={`users.${index}.userAccess`}
            defaultValue={item?.userAccess}
            disabled={item.inputDisable}
            options={ScModuleOptions ?? updateFormOptions?.scModules}
          />
        ),
      userRole:
        item.inputDisable && item.displayUserRole ? (
          item.displayUserRole
        ) : (
          <DropDown
            control={control}
            name={`users.${index}.userRole`}
            options={updateFormOptions?.roles ?? []}
            disabled={newRowAdded ? newRowAdded : item.inputDisable}
            placeholder={"User"}
            isLoading={isFetchingDropDown}
          />
        ),
      status:
        item.inputDisable && item.displayStatus ? (
          item.displayStatus
        ) : (
          <DropDown
            control={control}
            name={`users.${index}.status`}
            options={updateFormOptions?.statuses ?? []}
            disabled={newRowAdded ? newRowAdded : item.inputDisable}
            placeholder={"Active"}
            isLoading={isFetchingDropDown}
          />
        ),
      type: item.type,
      inputDisable: item.inputDisable
    };
  });
};

const UserRolesTable = ({
  clientId,
  userCount,
  refetchClient,
  allowCreateFormData
}) => {
  const [subUserId, setSubUserId] = useState();
  const { control, setValue, handleSubmit, reset } = useForm({
    resolver: yupResolver(userRoleSchema(!Boolean(subUserId)))
  });
  const { showDialog, hideDialog } = useCustomDialog();

  const {
    data: allSubUsersData,
    isFetching: isFetchingSubUsers,
    refetch: refetchUsers
  } = useFetchAllSubUsers(clientId);

  const { data: createFromData, isFetching: isFetchingCreateFormData } =
    useFetchCreateSubUserFormData(clientId, allowCreateFormData);

  const { data: updateFromData, isFetching: isFetchingUpdateFormData } =
    useFetchUpdateSubUserFormData(clientId, subUserId);

  const {
    mutate: createUser,
    isError: isCreateError,
    error: createError
  } = useCreateSubUsers();

  const {
    mutate: updateUser,
    isError: isUpdateError,
    error: updateError
  } = useUpdateSubUser();

  const {
    mutate: deleteSubUser,
    isError: isDeleteUserError,
    error: deleteUserError
  } = useDeleteSubUser();

  const { fields, append, remove } = useFieldArray({
    name: "users",
    control
  });

  const addExistingUsers = useCallback(
    (data) => {
      data.forEach((item, index) => {
        append({
          sNo: index + 1,
          _id: item._id,
          userName: item.firstName,
          mobileNo: item.mobile,
          userAccess: item.allowedScModules.map((item) => item.name),
          status: item.status.name,
          displayStatus: item.status.displayName,
          displayUserRole: item.role === "ClientSubUser" ? "User" : "Admin",
          userRole: item.role,
          type: "existingUser",
          inputDisable: true
        });
      });
    },
    [append]
  );

  const addNewUser = () => {
    append({
      sNo: fields.length + 1,
      userName: "",
      mobileNo: "",
      userAccess: [],
      status: "",
      type: "default",
      inputDisable: false
    });
  };

  useEffect(() => {
    if (!isFetchingSubUsers) {
      addExistingUsers(allSubUsersData?.users);
    }
  }, [addExistingUsers, isFetchingSubUsers, allSubUsersData?.users]);

  const toggleEdit = (index) => {
    const updatedFields = [...fields];
    updatedFields[index].inputDisable === true
      ? setSubUserId(updatedFields[index]._id)
      : setSubUserId(null);
    updatedFields[index].inputDisable = !updatedFields[index].inputDisable;
    setValue("users", updatedFields);
  };

  const deleteUser = useCallback(
    (subUserId) => {
      deleteSubUser(
        {
          clientId: clientId,
          subUserId: subUserId
        },
        {
          onSuccess: (res) => {
            refetchClient();
            hideDialog();
            reset({
              users: []
            });
            enqueueSnackbar("User Deleted!", {
              variant: "success"
            });
            refetchUsers();
          }
        }
      );
    },
    [clientId, hideDialog, refetchUsers, reset, deleteSubUser, refetchClient]
  );

  const onDeleteClick = useCallback(
    (row) => {
      showDialog({
        component: (
          <SuccessfulDialog
            text={`Are you sure you want to delete the User - ${row?.userName}. This will permanently remove his access.`}
            btn1Text={"Confirm"}
            btn2Text={"Cancel"}
            btn1Callback={() => deleteUser(row?._id)}
            btn2Callback={() => hideDialog()}
            btn1Variant={"contained"}
            btn2Variant={"softError"}
          />
        )
      });
    },
    [deleteUser, hideDialog, showDialog]
  );

  const removeNewRow = (index) => {
    remove(index);
  };

  const onSaveClick = (data) => {
    const editRowData = data.users.find((item) => item._id === subUserId);
    const formData = {
      firstName: editRowData.userName,
      status: editRowData.status,
      role: editRowData.userRole,
      allowedScModules: editRowData.userAccess
    };
    updateUser(
      {
        clientId: clientId,
        subUserId: subUserId,
        data: { ...formData }
      },
      {
        onSuccess: () => {
          refetchClient();
          reset({
            users: []
          });
          enqueueSnackbar("User Updated!", {
            variant: "success"
          });
          refetchUsers();
        }
      }
    );
  };

  const onAddClick = (data) => {
    const newRowData = data.users[data.users.length - 1];
    const formData = {
      firstName: newRowData.userName,
      mobile: newRowData.mobileNo,
      allowedScModules: newRowData.userAccess
    };
    createUser(
      {
        clientId: clientId,
        data: { ...formData }
      },
      {
        onSuccess: () => {
          refetchClient();
          reset({
            users: []
          });
          enqueueSnackbar("User Added!", {
            variant: "success"
          });
          refetchUsers();
        }
      }
    );
  };

  useEffect(() => {
    if (isCreateError) {
      enqueueSnackbar(createError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isUpdateError) {
      enqueueSnackbar(updateError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isDeleteUserError) {
      enqueueSnackbar(deleteUserError?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [
    createError?.response?.data?.message,
    updateError?.response?.data?.message,
    deleteUserError?.response?.data?.message,
    isCreateError,
    isUpdateError,
    isDeleteUserError
  ]);

  return (
    <Box display={"flex"} flexDirection={"column"} gap={4} pb={"40px"}>
      <Divider sx={{ border: "0.7px solid #B7BEC7" }} />
      <Box
        display={"flex"}
        flexDirection={"column"}
        border={"1px solid #00000030"}
        py={"20px"}
        width={"95%"}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          pb={0.1}
        >
          <Box
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            gap={1}
          >
            <TitleText>{"User Role Settings"}</TitleText>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "17px",
                color: "text.title"
              }}
            >{`(${
              allSubUsersData?.users?.length ?? 0
            }/${userCount})`}</Typography>
          </Box>
        </Box>
        <Divider sx={{ border: "0.7px solid #B7BEC7" }} />
        <Box display={"flex"} flexDirection={"column"} gap={2} pt={4}>
          <CustomTable
            columns={getColumns(
              onDeleteClick,
              removeNewRow,
              toggleEdit,
              handleSubmit(onSaveClick),
              handleSubmit(onAddClick),
              Boolean(fields?.length > allSubUsersData?.users?.length),
              fields.some((item) => !item.inputDisable)
            )}
            data={
              isFetchingSubUsers
                ? []
                : getRows(
                    fields,
                    control,
                    !isFetchingCreateFormData ? createFromData?.scModules : [],
                    Boolean(fields?.length > allSubUsersData?.users?.length),
                    isFetchingUpdateFormData,
                    updateFromData
                  )
            }
            isLoading={isFetchingSubUsers}
          />
          {fields?.every((obj) => obj?.inputDisable !== false) &&
            allSubUsersData?.users?.length === fields.length &&
            fields.length !== userCount && (
              <Box>
                <Button variant="link" disableRipple onClick={addNewUser}>
                  <AddOutlined />
                  {"Add User"}
                </Button>
              </Box>
            )}
        </Box>
      </Box>
    </Box>
  );
};

export default UserRolesTable;
