import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  styled
} from "@mui/material";
import { error_msg, statusKeys, warehouse_labels } from "../../constants";

import { useEffect, useState } from "react";
import { CalendarTodayOutlined } from "@mui/icons-material";
import { Close } from "@mui/icons-material";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import { currencyFormatter, stringCapitalization } from "../../shared/utils";
import TextInput from "../../shared/formElements/TextInput";
import DateInput from "../../shared/formElements/DateInput";
import { useForm } from "react-hook-form";
import { partnerPaymentFormKeys } from "../../constants/formKeys";
import { partnerPayment_Labels } from "../../constants";
import { useFetchSettlementByIdQueryHook } from "../../query-hooks/Settlement/useFetchSettlementByIdQueryHook";
import { useAddTransactionMutateQuery } from "../../query-hooks/Settlement/useAddTransactionMutateQuery";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { LoadingButton } from "@mui/lab";
import { useUpdateCommissionMutateQuery } from "../../query-hooks/Settlement/useUpdateCommissionMutateQuery";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const TransactionSchema = yup.object({
  [partnerPaymentFormKeys.transactionId]: yup
    .string()
    .required(`${error_msg.required}`),
  [partnerPaymentFormKeys.transactionDate]: yup
    .string()
    .required(` ${error_msg.required}`),
  [partnerPaymentFormKeys.paymentAmount]: yup
    .string()
    .required(`${error_msg.required}`)
});

const CommissionSchema = yup.object({
  [partnerPaymentFormKeys.commissionAmount]: yup
    .string()
    .required(`${error_msg.required}`)
});

export const StyledButton = styled((props) => (
  <Button
    {...props}
    variant={props.variant}
    sx={{
      fontSize: "14px",
      lineHeight: "20px",
      width: "74px",
      height: "30px"
    }}
    disabled={props.disabled}
  />
))(({ theme }) => ({
  fontWeight: 600,
  borderRadius: "8px"
}));

export const StyledHeaderLabels = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: 16,
  lineHeight: "19px"
}));

export const StyledNormalLabels = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: 14,
  lineHeight: "17px"
}));

export const StyledDisabledLabels = styled(Typography)(() => ({
  fontSize: "14px",
  color: "#607088",
  whiteSpace: "nowrap"
}));

export const StyledAmount = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 16,
  lineHeight: "17px"
}));

const InvoiceStatus = ({ close, settlementId }) => {
  const [enableTransactions, setEnableTransactions] = useState(true);
  const [enableCommission, setEnableCommission] = useState(true);
  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(TransactionSchema)
  });
  const {
    control: commissionControl,
    handleSubmit: handleCommission,
    setValue: setCommision
  } = useForm({
    resolver: yupResolver(CommissionSchema)
  });
  const { data, isFetching, refetch } =
    useFetchSettlementByIdQueryHook(settlementId);

  const disableForm = Boolean(
    data?.settlement?.status?.name !== statusKeys.Pending
  );

  useEffect(() => {
    if (!isFetching) {
      setCommision(
        partnerPaymentFormKeys.commissionAmount,
        data?.settlement?.commissionAmount
      );
    }
    // eslint-disable-next-line
  }, [isFetching]);

  const toggleTransactions = () => {
    setEnableTransactions((prevState) => !prevState);
    setValue(partnerPaymentFormKeys.transactionId, "");
    setValue(partnerPaymentFormKeys.transactionDate, "");
    setValue(partnerPaymentFormKeys.paymentAmount, "");
  };

  const toggleCommission = () => {
    setEnableCommission((prevState) => !prevState);
    setCommision(
      partnerPaymentFormKeys.commissionAmount,
      data?.settlement?.commissionAmount
    );
  };

  const {
    mutate: addTransaction,
    isLoading: isTransactionLoading,
    error: transactionError,
    isError: isTransactionError
  } = useAddTransactionMutateQuery();

  const {
    mutate: updateCommission,
    isLoading: isCommissionLoading,
    error: commissionError,
    isError: isCommissionError
  } = useUpdateCommissionMutateQuery();

  const onAddTransaction = (data) => {
    addTransaction(
      {
        settlementId: settlementId,
        formData: {
          transactionId: data[partnerPaymentFormKeys.transactionId],
          transactionDate: moment(
            data[partnerPaymentFormKeys.transactionDate]
          ).format("YYYY-MM-DD"),
          amount: +data[partnerPaymentFormKeys.paymentAmount]
        }
      },
      {
        onSuccess: () => {
          toggleTransactions();
          enqueueSnackbar("Transaction created!", { variant: "success" });
          refetch();
        }
      }
    );
  };

  const onUpdateCommission = (data) => {
    updateCommission(
      {
        settlementId: settlementId,
        formData: {
          amount: +data[partnerPaymentFormKeys.commissionAmount]
        }
      },
      {
        onSuccess: () => {
          toggleCommission();
          enqueueSnackbar("Commission Amount Updated!", { variant: "success" });
          refetch();
        }
      }
    );
  };

  useEffect(() => {
    if (isTransactionError || isCommissionError)
      enqueueSnackbar(
        transactionError?.response.data.message ||
          commissionError?.response.data.message,
        {
          variant: "error"
        }
      );
  }, [
    transactionError?.response.data.message,
    commissionError?.response.data.message,
    isTransactionError,
    isCommissionError
  ]);

  return isFetching ? (
    <Grid
      item
      xs={12}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <Box display={"flex"} flexDirection={"column"} gap={1}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{
          boxShadow: "0px 8px 24px 0px #1515161A",
          background: "#fff",
          padding: "28px 40px 30px 40px"
        }}
        gap={3}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <StyledChip label={data?.settlement?.status?.name} />
          <StyledChip label={stringCapitalization("splitNotEnabled")} />
          <Box height={"fit-content"} onClick={close}>
            <Close
              sx={{
                color: "#607088",
                cursor: "pointer"
              }}
            />
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <StyledHeaderLabels>Invoice Amount</StyledHeaderLabels>
          <StyledAmount>{`{No Data}`}</StyledAmount>
          <Box display={"flex"} flexDirection={"column"}>
            <StyledNormalLabels>
              <Box>Inv No.</Box>
              <Box>{`{No Data}`}</Box>
            </StyledNormalLabels>
          </Box>
          <Box display={"flex"} flexDirection={"column"}>
            <StyledNormalLabels>
              <Box>Inv Date.</Box>
              <Box>{`{No Data}`}</Box>
            </StyledNormalLabels>
          </Box>
        </Box>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{
          boxShadow: "0px 8px 24px 0px #1515161A",
          background: "#fff",
          padding: "28px 40px 30px 40px"
        }}
        gap={3}
      >
        <Box display={"flex"} flexDirection={"row"} gap={4.5}>
          <StyledHeaderLabels>
            {warehouse_labels.customerPaymentsLabelText}
          </StyledHeaderLabels>
          <StyledAmount>
            {currencyFormatter(data?.settlement?.orderAmount)}
          </StyledAmount>
        </Box>
        {data?.customerPayments?.map((item, index) => (
          <Box display={"flex"} flexDirection={"column"} gap={1} key={index}>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <StyledDisabledLabels>
                {warehouse_labels.paymentAmountText}
              </StyledDisabledLabels>
              <StyledAmount>
                {/* {item._id} */}
                {currencyFormatter(
                  parseFloat(
                    item.transportDetail?.orderAmount ??
                      item.warehouseDetail?.orderAmount
                  ).toFixed(2)
                )}
              </StyledAmount>
              <StyledNormalLabels>{`{No Data}`}</StyledNormalLabels>
            </Box>
            <Box display={"flex"} flexDirection={"row"} gap={12}>
              <StyledDisabledLabels>
                {warehouse_labels.transactionIdText}
              </StyledDisabledLabels>
              <StyledNormalLabels>{`{No Data}`}</StyledNormalLabels>
            </Box>
          </Box>
        ))}
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{
          boxShadow: "0px 8px 24px 0px #1515161A",
          padding: "28px 18px 40px 40px",
          background: "#fff"
        }}
        gap={3}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <StyledHeaderLabels>
            {warehouse_labels.partnerPayout}
          </StyledHeaderLabels>
          <StyledAmount>{`{No Data}`}</StyledAmount>
          <Box display={"flex"} flexDirection={"row"} gap={2}>
            <StyledButton
              variant="soft"
              disabled={disableForm}
              onClick={toggleTransactions}
            >
              {enableTransactions ? "Edit" : "Cancel"}
            </StyledButton>
            {!enableTransactions && (
              <StyledButton
                variant="contained"
                disabled={disableForm}
                component={LoadingButton}
                loading={isTransactionLoading}
                onClick={handleSubmit(onAddTransaction)}
              >
                Save
              </StyledButton>
            )}
          </Box>
        </Box>
        {data?.settlement?.transactions?.map((item, index) => (
          <Box display={"flex"} flexDirection={"column"} gap={1} key={index}>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <StyledDisabledLabels>
                {warehouse_labels.paymentAmountText}
              </StyledDisabledLabels>
              <StyledAmount>
                {currencyFormatter(parseFloat(item?.amount).toFixed(2))}
              </StyledAmount>
              <StyledNormalLabels>
                {moment(item?.transactionDate).format("DD MMM, YYYY")}
              </StyledNormalLabels>
            </Box>
            <Box display={"flex"} flexDirection={"row"} gap={12}>
              <StyledDisabledLabels>
                {warehouse_labels.transactionIdText}
              </StyledDisabledLabels>
              <StyledNormalLabels>{item?.transactionId}</StyledNormalLabels>
            </Box>
          </Box>
        ))}
        <Box display={"flex"} flexDirection={"row"} gap={"8px"}>
          <TextInput
            control={control}
            name={partnerPaymentFormKeys.paymentAmount}
            label={partnerPayment_Labels.paymentAmount}
            disabled={enableTransactions}
            startIcon={<Box pl={1}>{`₹`}</Box>}
            type="number"
          />
          <TextInput
            control={control}
            name={partnerPaymentFormKeys.transactionId}
            label={partnerPayment_Labels.transactionId}
            disabled={enableTransactions}
          />
          <DateInput
            control={control}
            name={partnerPaymentFormKeys.transactionDate}
            label={partnerPayment_Labels.transactionDate}
            endIcon={CalendarTodayOutlined}
            placeholder={"Enter Date"}
            minDate={"1900-01-01"}
            disabled={enableTransactions}
          />
        </Box>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{
          background: "#fff",
          padding: "28px 18px 20px 40px"
        }}
        gap={3}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <StyledHeaderLabels>
            {warehouse_labels.commissionDetailsText}
          </StyledHeaderLabels>
          <Box display={"flex"} flexDirection={"row"} gap={2}>
            <StyledButton
              variant="soft"
              disabled={disableForm}
              onClick={toggleCommission}
            >
              {enableCommission ? "Edit" : "Cancel"}
            </StyledButton>
            {!enableCommission && (
              <StyledButton
                variant="contained"
                disabled={disableForm}
                component={LoadingButton}
                loading={isCommissionLoading}
                onClick={handleCommission(onUpdateCommission)}
              >
                Save
              </StyledButton>
            )}
          </Box>
        </Box>
        <Box display={"flex"} flexDirection={"row"} gap={4.5}>
          <StyledDisabledLabels>
            {warehouse_labels.commissionAmountText}
          </StyledDisabledLabels>
          <TextInput
            control={commissionControl}
            name={partnerPaymentFormKeys.commissionAmount}
            disabled={enableCommission}
            sx={{ width: "140px", height: "30px" }}
            startIcon={<Box pl={1}>{`₹`}</Box>}
            type="number"
          />
        </Box>
        <Box display={"flex"} flexDirection={"row"} gap={13}>
          <StyledDisabledLabels>
            {warehouse_labels.invoiceNo}
          </StyledDisabledLabels>
          <StyledNormalLabels>-</StyledNormalLabels>
        </Box>
        <Box display={"flex"} flexDirection={"row"} gap={12}>
          <StyledDisabledLabels>
            {warehouse_labels.invoiceDate}
          </StyledDisabledLabels>
          <StyledNormalLabels>-</StyledNormalLabels>
        </Box>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Button variant="contained" disabled={disableForm}>
            {!disableForm ? "Generate Invoice" : "Invoice Generated"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default InvoiceStatus;
