import { Box, Button, Grid, Typography } from "@mui/material";
import { transport_labels, warehouse_labels } from "../../constants";

import DropDown from "../../shared/formElements/DropDown";
import { ImageUpload } from "../../shared/formElements/ImageUpload";
import TextInput from "../../shared/formElements/TextInput";
import { TruckFormKeys } from "../../constants/formKeys";
import { useCustomDialog } from "../../shared/customDialog";
import { useFetchVehicleFormData } from "../../query-hooks/EditTransport/useFetchVehicleFormData";
import { useForm } from "react-hook-form";
import { useUpdateVehicleDataMutateQuery } from "../../query-hooks/EditTransport/useUpdateVehicleDataMutateQuery";
import { updateVehicleFormSchema } from "./TransportFormValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";

const UpdateTruckDetailsForm = (props) => {
  const { hideDialog } = useCustomDialog();
  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      [TruckFormKeys.truckMake]: props.truckMake,
      [TruckFormKeys.truckModel]: props.truckModel,
      [TruckFormKeys.pricePerKm]: props.pricePerKm,
      [TruckFormKeys.truckNumber]: props.truckNumber,
      // [TruckFormKeys.truckImage]: props.truckImage,
    },
    resolver: yupResolver(updateVehicleFormSchema)
  });

  const truckMake = watch(TruckFormKeys.truckMake);

  const { data: formData } = useFetchVehicleFormData(props?.partnerId);

  const { mutate , isError , error , isLoading} = useUpdateVehicleDataMutateQuery();

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("regNo", data[TruckFormKeys.truckNumber]);
    formData.append("model", data[TruckFormKeys.truckModel]);
    formData.append("rate", data[TruckFormKeys.pricePerKm]);
    if (data?.truckImage !== "") {
      formData.append("file", data[TruckFormKeys.truckImage]);
    }
    mutate(
      {
        partnerId: props?.partnerId,
        vehicleId: props?.vehicleId,
        formData: formData
      },
      {
        onSuccess: () => {
          props?.refetch();
          hideDialog();
        }
      }
    );
  };

  useEffect(() => {
    if (isError)
      enqueueSnackbar(
          error?.response.data.message,
        { variant: "error" }
      );
  }, [error?.response.data.message, isError]);


  return (
    <Box
      sx={{
        padding: "18px 16px"
      }}
    >
      <Typography
        color="text.secondary"
        fontWeight={600}
        fontSize={16}
        marginBottom={4}
      >
        {transport_labels.truckDetailsLabelText}
      </Typography>
      <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextInput
              control={control}
              name={TruckFormKeys.truckNumber}
              label={transport_labels.truckNumberLabelText}
              placeholder={transport_labels.truckNumberPlaceholderText}
            />
          </Grid>
          <Grid item xs={12}>
            <DropDown
              control={control}
              name={TruckFormKeys.truckMake}
              label={transport_labels.truckMakeLabelText}
              placeholder={transport_labels.truckMakePlaceholderText}
              options={formData?.vehicles}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={12}>
            <DropDown
              control={control}
              name={TruckFormKeys.truckModel}
              label={transport_labels.truckModelLabelText}
              placeholder={transport_labels.truckModelPlaceholderText}
              options={
                truckMake && formData?.vehicles
                  ? Object.values(
                      formData?.vehicles.find((item) => item.name === truckMake)
                        ?.models
                    )
                  : []
              }
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              control={control}
              name={TruckFormKeys.pricePerKm}
              label={transport_labels.pricePerKmLabelText}
              placeholder={transport_labels.pricePerKmPlaceholderText}
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <ImageUpload
              name={TruckFormKeys.truckImage}
              control={control}
              setValue={setValue}
              compact={true}
              uploadButtonText={transport_labels.truckUploadButtonText}
              defaultValue={props.truckImage}
            />
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="soft"
                sx={{
                  height: 64,
                  mt: "14px"
                }}
                onClick={hideDialog}
              >
                {warehouse_labels.cancelButtonText}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <LoadingButton
                fullWidth
                variant="contained"
                sx={{
                  height: 64,
                  mt: "14px"
                }}
                onClick={handleSubmit(onSubmit)}
                loading={isLoading}
              >
                {transport_labels.updateVehicle}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default UpdateTruckDetailsForm;
