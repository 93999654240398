import { Box, Typography } from "@mui/material";
import appRoutes from "../../constants/appRoutes";
import { NAV_LINK } from "../../shared/layouts/SideDrawer";
import MyOrders from "./MyOrders";
import InvoiceAndDocs from "./InvoiceAndDocs";
import { useState } from "react";
import { CustomTabs } from "../../shared/customTab/CustomTabs";
import { order_table_labels } from "../../constants";

const Orders = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const componentPath = {
    [appRoutes.orders.myOrdersListView]: <MyOrders />,
    [appRoutes.orders.invoicesDocsListView]: <InvoiceAndDocs />,
  };
  const MenuItems = NAV_LINK.find(
    (item) => item.name === "Orders"
  )?.children?.map((menu) => {
    return {
      label: menu.name,
      Component: componentPath[menu.to],
    };
  });
  return (
    <Box>
      <Typography
        sx={{
          padding: "37px 21px 18px 21px",
          background: "#EEF1F4",
        }}
      >
        {order_table_labels.manageOrders}
      </Typography>
      <CustomTabs
        tabs={MenuItems}
        activeTab={activeTab}
        onChange={handleTabChange}
        secondaryTab={true}
      />
    </Box>
  );
};

export default Orders;
