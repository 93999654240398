import {
  FormControl,
  FormHelperText,
  InputBase,
  InputLabel
} from "@mui/material";

import { Controller } from "react-hook-form";
import { useRef } from "react";

const TextInput = ({
  label,
  placeholder,
  helperText,
  name,
  control,
  disabled = false,
  type = "text",
  endIcon: EndIcon,
  startIcon,
  multiline = false,
  sx = {}
}) => {
  const inputRef = useRef(null);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={""}
      render={({ field, fieldState: { error } }) => (
        <FormControl variant="standard" fullWidth disabled={disabled}>
          {label && (
            <InputLabel shrink htmlFor={name}>
              {label}
            </InputLabel>
          )}
          <InputBase
            {...field}
            id={name}
            ref={inputRef}
            placeholder={placeholder}
            error={!!error}
            type={type}
            startAdornment={startIcon}
            multiline={multiline}
            rows={1}
            sx={sx}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            endAdornment={
              EndIcon && (
                <EndIcon
                  fontSize="15"
                  sx={{ mr: "12px", cursor: "pointer", color: "#607088" }}
                  onClick={(e) => {
                    inputRef.current.click();
                  }}
                />
              )
            }
          />
          {helperText && (
            <FormHelperText sx={{ letterSpacing: "0.1em" }}>
              {helperText}
            </FormHelperText>
          )}
          {error && <FormHelperText error>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default TextInput;
