import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import { inquiry_table_labels } from "../../constants";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import moment from "moment";
import {
  currencyOnlyFormatter,
  stringCapitalization
} from "../../shared/utils";
import CustomTable from "../../shared/customTable";
import { useNavigate } from "react-router-dom";
import appRoutes from "../../constants/appRoutes";
import { useInView } from "react-intersection-observer";
import { useFetchCustomers } from "../../query-hooks/Customers/useFetchCustomers";
import { useUpdateSupplyChainStatus } from "../../query-hooks/Subscription/useUpdateSupplyChainStatus";
import { enqueueSnackbar } from "notistack";
import { useCustomDialog } from "../../shared/customDialog";
import SuccessfulDialog from "../../shared/UiElements/SuccessfulDialog";

const getColumns = (onCustomerClick, onStatusClick) => {
  return [
    {
      Header: "Customer",
      accessor: "customer",
      width: 128,
      Cell: ({ row, value }) => {
        return (
          <Button
            variant="link"
            onClick={() =>
              row?.original?.clientId && onCustomerClick(row?.original)
            }
            sx={{
              fontWeight: 600
            }}
          >
            {value}
          </Button>
        );
      }
    },
    {
      Header: "Signup On",
      accessor: "signupOn",
      width: 120,
      Cell: ({ value }) => {
        return (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "20px"
            }}
          >
            <Box>{moment(value).format("DD MMMM YYYY")}</Box>
            <Box>{moment(value).format("HH:mm")}</Box>
          </Typography>
        );
      }
    },
    {
      Header: "Customer Name",
      accessor: "customerName",
      width: 164
    },
    {
      Header: "Company Name",
      accessor: "companyName",
      width: 164
    },
    {
      Header: "GSTIN",
      accessor: "gstIn",
      width: 149
    },
    {
      Header: "AmtPaid",
      accessor: "amtPaid",
      width: 111,
      Cell: ({ value }) => {
        return value ? currencyOnlyFormatter(value) : "--";
      }
    },
    {
      Header: "#Users",
      accessor: "users",
      width: 111,
      Cell: ({ row, value }) => {
        return (
          <>
            {`${value}/`}
            <span style={{ fontWeight: 500 }}>{row.original.allowedUsers}</span>
          </>
        );
      }
    },
    {
      Header: "End date",
      accessor: "endDate",
      width: 111
    },
    {
      Header: "Status",
      accessor: "status",
      width: 125,
      Cell: ({ row, value }) => {
        return (
          <StyledChip
            label={stringCapitalization(value)}
            onClick={() => onStatusClick(row?.original)}
          />
        );
      }
    }
  ];
};

const getRows = (rows = []) => {
  return rows.map((item, index) => {
    return {
      clientId: item?.admin?.client,
      customer: item?.admin?.mobile,
      signupOn: item.createdAt,
      customerName: `${item?.admin?.firstName ?? ""} ${
        item?.admin?.lastName ?? ""
      }`,
      companyName: item?.companyName ?? "",
      gstIn: item?.billing?.gstNo ?? "",
      users: item?.userCount,
      allowedUsers: item?.subscriptionDetails?.userCount ?? 0,
      amtPaid: item?.subscriptionDetails?.amount,
      endDate: item?.subscriptionDetails?.minEndDate
        ? moment(item?.subscriptionDetails?.minEndDate).format("DD-MM-YYYY")
        : "-",
      status: item?.scModuleStatus?.name
    };
  });
};

const Customers = () => {
  const naviagte = useNavigate();
  const { ref, inView } = useInView();
  const { showDialog, hideDialog } = useCustomDialog();

  const { data, isFetched, hasNextPage, fetchNextPage, refetch } =
    useFetchCustomers();

  const {
    mutate: updateStatus,
    isError: isUpdateStatusError,
    error: updateStatusError
  } = useUpdateSupplyChainStatus();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  const onCustomerClick = useCallback(
    (row) => {
      naviagte(`${appRoutes.customers.main}/${row?.clientId}`);
    },
    [naviagte]
  );

  const onUpdate = useCallback(
    (data) => {
      updateStatus(
        {
          clientId: data
        },
        {
          onSuccess: (res) => {
            hideDialog();
            refetch();
            enqueueSnackbar("Supply Chain Status Updated!", {
              variant: "success"
            });
          }
        }
      );
    },
    [updateStatus, hideDialog, refetch]
  );

  const onStatusClick = useCallback(
    (row) => {
      showDialog({
        component: (
          <SuccessfulDialog
            text={"This Customer's supply-chain modules status is"}
            status={row.status}
            subText={`Do you want to change the status to ${
              row.status === "Active" ? "Inactive" : "Active"
            }?`}
            btn1Variant={"soft"}
            btn1Text={"Yes,Confirm"}
            btn2Text={"Go Back"}
            btn1Callback={() => onUpdate(row?.clientId)}
            btn2Callback={hideDialog}
          />
        )
      });
    },
    [onUpdate, hideDialog, showDialog]
  );

  const customersTable = useMemo(
    () => (
      <CustomTable
        columns={getColumns(onCustomerClick, onStatusClick)}
        data={
          isFetched
            ? data?.pages?.flatMap((page) => getRows(page?.clients)) ?? []
            : []
        }
        isLoading={!isFetched}
      />
    ),
    [data?.pages, isFetched, onCustomerClick, onStatusClick]
  );

  useEffect(() => {
    if (isUpdateStatusError) {
      enqueueSnackbar(updateStatusError?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [updateStatusError?.response?.data?.message, isUpdateStatusError]);

  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <Box
        display={"flex"}
        gap={3}
        justifyContent={"flex-start"}
        alignItems={"end"}
      >
        <Button variant="contained" sx={{ height: "45px", width: "120px" }}>
          {inquiry_table_labels.view}
        </Button>
        <Button variant="contained" sx={{ height: "45px", width: "120px" }}>
          {inquiry_table_labels.clear}
        </Button>
        <Button variant="outlined" sx={{ height: "45px", width: "120px" }}>
          {inquiry_table_labels.download}
        </Button>
      </Box>
      <Grid item xs={12}>
        {customersTable}
        {hasNextPage && (
          <Grid
            item
            xs={12}
            ref={ref}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Customers;
