import { client } from "../../shared/api/client";

export const fetchProfile = async () => {
  return await client.get("/profile").then((res) => res.data.data);
};

export const fetchAddPartnerFormData = async () => {
  return await client
    .get("/partners/form-data")
    .then((response) => response.data.data);
};

export const fetchVehicleFormData = async (partnerId) => {
  return await client
    .get(`/transports/${partnerId}/vehicles/form-data`)
    .then((res) => res.data.data);
};

export const fetchWarehouseFormData = async (partnerId) => {
  return await client
    .get(`/partners/warehouses/${partnerId}/form-data`)
    .then((res) => res.data.data);
};
export const fetchTransportFormData = async (partnerId) => {
  return await client
    .get(`/partners/transports/${partnerId}/form-data`)
    .then((res) => res.data.data);
};

export const addPartner = async (data) => {
  return await client.post(`/partners`, { ...data });
};

export const getPartners = async (page) => {
  return await client
    .get(`/partners?page=${page}`)
    .then((res) => res.data.data);
};

export const getPartnerById = async (partnerId) => {
  return await client
    .get(`/partners/${partnerId}`)
    .then((res) => res.data.data);
};

export const updatePartner = async (partnerId, formData) => {
  return await client.put(`/partners/${partnerId}`, formData);
};

export const approvePartner = async (partnerId, formData) => {
  return await client.put(`/partners/${partnerId}/approve`, formData);
};

export const statusPartner = async (partnerId, formData) => {
  return await client.put(`/partners/${partnerId}/status`, formData);
};

export const updateWarehousePartner = async (partnerId, formData) => {
  return await client.put(`/partners/warehouses/${partnerId}`, formData);
};

export const updateWarehouseFiles = async (partnerId, fileId, formData) => {
  return await client.put(`/warehouses/${partnerId}/files/${fileId}`, formData);
};

export const updateWarehouseHeroImage = async (partnerId, fileId) => {
  return await client.put(`/warehouses/${partnerId}/hero-image`, { fileId });
};

export const updateTransportPartner = async (partnerId, formData) => {
  return await client.put(`/partners/transports/${partnerId}`, formData);
};

export const addTransportVehicle = async (partnerId, formData) => {
  return await client.post(`/transports/${partnerId}/vehicles`, formData);
};

export const updateTransportVehicle = async (
  partnerId,
  vehicleId,
  formData
) => {
  return await client.put(
    `transports/${partnerId}/vehicles/${vehicleId}`,
    formData
  );
};

export const deleteTransportVehicle = async (partnerId, vehicleId) => {
  return await client.delete(`/transports/${partnerId}/vehicles/${vehicleId}`);
};

export const getOrders = async (page) => {
  return await client.get(`/orders?page=${page}`).then((res) => res.data.data);
};

export const getInquiries = async (page) => {
  return await client
    .get(`/inquiries?page=${page}`)
    .then((res) => res.data.data);
};

export const getInquiriesByRfqId = async (inquiryId) => {
  return await client
    .get(`/inquiries/${inquiryId}`)
    .then((res) => res.data.data);
};

export const resetQuote = async (inquiryId, partnerId) => {
  return await client.put(
    `/inquiries/${inquiryId}/partners/${partnerId}/quote/reset`,
    {}
  );
};

export const fetchOrderStatusFormData = async (orderId) => {
  return await client
    .get(`/orders/${orderId}/customer-status/form-data`)
    .then((res) => res.data.data);
};

export const updateCustomerStatus = async (orderId, formData) => {
  return await client.put(`/orders/${orderId}/customer-status`, formData);
};

export const getSettlement = async (page) => {
  return await client
    .get(`/settlements?page=${page}`)
    .then((res) => res.data.data);
};

export const getSettlementById = async (settlementId) => {
  return await client
    .get(`/settlements/${settlementId}`)
    .then((res) => res.data.data);
};

export const addTransaction = async (settlementId, formData) => {
  return await client.post(`/settlements/${settlementId}/transactions`, {
    ...formData
  });
};

export const updateCommission = async (settlementId, formData) => {
  return await client.put(`/settlements/${settlementId}/commission`, {
    ...formData
  });
};

export const getCustomers = async (page) => {
  return await client
    .get(`/clients?page=${page}`)
    .then((res) => res.data.data);
};

export const getCustomerById = async (customerId) => {
  return await client
    .get(`/clients/${customerId}`)
    .then((res) => res.data.data);
};

export const getSubscriptions = async (clientId) => {
  return await client
    .get(`/clients/${clientId}/subscriptions`)
    .then((res) => res.data.data);
};

export const createSubscription = async (clientId, data) => {
  return await client.post(`/clients/${clientId}/subscriptions`, data);
};

export const updateSupplyChainStatus = async (clientId) => {
  return await client.put(
    `/clients/${clientId}/modules/supply-chain/status`,
    {}
  );
};

export const expireSubscription = async (clientId, subscriptionId) => {
  return await client.put(
    `/clients/${clientId}/subscriptions/${subscriptionId}/expire`,
    {}
  );
};

export const getSubUsers = async (clientId) => {
  return await client
    .get(`/clients/${clientId}/sub-users`)
    .then((res) => res.data.data);
};

export const getCreateSubUserFormData = async (clientId) => {
  return await client
    .get(`/clients/${clientId}/sub-users/form-data`)
    .then((res) => res.data.data);
};

export const getUpdateSubUserFormData = async (clientId, subUserId) => {
  return await client
    .get(`/clients/${clientId}/sub-users/${subUserId}/form-data`)
    .then((res) => res.data.data);
};

export const createSubUser = async (clientId, data) => {
  return await client.post(`/clients/${clientId}/sub-users`, data);
};

export const updateSubUser = async (clientId, subUserId, data) => {
  return await client.put(`/clients/${clientId}/sub-users/${subUserId}`, data);
};

export const deleteSubUser = async (clientId, subUserId) => {
  return await client.delete(`/clients/${clientId}/sub-users/${subUserId}`);
};
