import { Backdrop, Box, CircularProgress } from "@mui/material";
import UserRolesTable from "../components/UserRolesTable";
import SubscriptionTable from "../components/SubscriptionTable";
import { useParams } from "react-router-dom";
import { useFetchCustomerById } from "../../query-hooks/Customers/useFetchCustomerById";

const Subscription = () => {
  const { clientId } = useParams();
  const { data, isFetching, refetch } = useFetchCustomerById(clientId);
  return isFetching ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isFetching}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <Box display={"flex"} flexDirection={"column"} gap={10}>
      <SubscriptionTable
        clientId={clientId}
        companyName={data?.client?.companyName}
        refetchClient={refetch}
      />
      <UserRolesTable
        clientId={clientId}
        userCount={data?.client?.subscriptionDetails?.userCount}
        allowCreateFormData={Boolean(
          data?.client?.subscriptionDetails?.userCount >
            data?.client?.subscriptionDetails?.count
        )}
        refetchClient={refetch}
      />
    </Box>
  );
};

export default Subscription;
