import {
  Avatar,
  Badge,
  Box,
  List,
  ThemeProvider,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  PeopleOutlineOutlined,
  Search,
  ShoppingCartOutlined,
} from "@mui/icons-material";
import { StyledDrawer, drawerTheme } from "../theme/drawerTheme";
import { isMobile, isTablet } from "react-device-detect";
import { useLocation, useNavigate } from "react-router-dom";

import { CustomTooltip } from "../theme/globalTheme";
import IMAGE from "../../assets/Warex-white.png";
import InfoIcon from "@mui/icons-material/Info";
import { NavLinkList } from "./NavLinkList";
import PasswordIcon from "@mui/icons-material/Password";
import PersonIcon from "@mui/icons-material/Person";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import ReceiptIcon from "@mui/icons-material/Receipt";
import WhiteColorEdit from "../../assets/icons/WhiteColorEdit.svg";
import appRoutes from "../../constants/appRoutes";
import warexLogo from "../../assets/Warex-Menu-logo.png";

const isMobileDevice = isMobile || isTablet;

export const NAV_LINK = [
  { to: appRoutes.customers.main, name: "Customers", icon: Search },
  { to: appRoutes.partner.main, name: "Partners", icon: Search },
  {
    name: "Orders",
    icon: ShoppingCartOutlined,
    from: appRoutes.orders.main,
    children: [
      {
        to: appRoutes.orders.myOrdersListView,
        name: "My orders",
      },
      {
        to: appRoutes.orders.invoicesDocsListView,
        name: "Payments & Invoices",
      },
    ],
  },
  {
    to: appRoutes.inquiry.inquiryListView,
    name: "Inquiry",
    icon: PeopleOutlineOutlined,
  },
];

export const PROFILE_NAV_LINK = [
  {
    to: appRoutes.profile.main,
    name: "Profile",
    icon: PersonIcon,
  },
  {
    to: appRoutes.profile.changePassword,
    name: isMobileDevice ? "Password" : "Change password",
    icon: PasswordIcon,
  },
  {
    to: appRoutes.profile.helpAndSupport,
    name: isMobileDevice ? "Help" : "Help & Support",
    icon: InfoIcon,
  },
  {
    to: appRoutes.profile.faqs,
    name: "FAQs",
    icon: QuestionMarkIcon,
  },
  {
    to: appRoutes.profile.tnc,
    name: isMobileDevice ? "T&C" : "Terms & Conditions",
    icon: ReceiptIcon,
  },
];

const ProfileImageAndLabel = () => {
  return (
    <>
      <Box sx={{ alignSelf: "center", mt: 7, mb: 2 }}>
        <Badge
          sx={{ border: "5px solid #FFFFFF", borderRadius: "50%" }}
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            <CustomTooltip title={"Edit profile image"} placement="top">
              <img
                src={WhiteColorEdit}
                style={{ cursor: "pointer" }}
                className="pull-right cursor-field"
                alt={"Edit profile"}
              />
            </CustomTooltip>
          }
        >
          <Avatar
            alt="Profile Image"
            src={IMAGE}
            sx={{ width: 80, height: 80 }}
          />
        </Badge>
      </Box>
      <Box sx={{ display: "block", alignSelf: "center", mb: 2 }}>
        <Typography
          sx={{
            fontSize: 20,
            fontWeight: 500,
            lineHeight: "24.2px",
          }}
        >
          {"Raunak Agarwal"}
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 400,
            lineHeight: "20px",
            mt: 1,
          }}
        >
          {"Member since 2023"}
        </Typography>
      </Box>
    </>
  );
};

const SideDrawer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isRoot = location.pathname === "/";
  const isProfileScreen =
    location.pathname.includes(appRoutes.profile.main) || false;
  return (
    !isRoot && (
      <ThemeProvider theme={drawerTheme}>
        <StyledDrawer variant="permanent" anchor="left" role="navigation">
          <Toolbar>
            <Box
              component="div"
              sx={{ flexGrow: 1, cursor: "pointer" }}
              onClick={() => navigate("/partner")}
            >
              <img src={warexLogo} width={140} height={35} alt="Warex" />
            </Box>
          </Toolbar>
          {isProfileScreen && <ProfileImageAndLabel />}
          <List component="nav">
            <NavLinkList
              links={isProfileScreen ? PROFILE_NAV_LINK : NAV_LINK}
              isProfileScreen={isProfileScreen}
            />
          </List>
        </StyledDrawer>
      </ThemeProvider>
    )
  );
};

export default SideDrawer;
