import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect } from "react";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CustomTable from "../../shared/customTable";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import appRoutes from "../../constants/appRoutes";
import { inquiry_table_labels, partner_table_labels } from "../../constants";
import moment from "moment";
import { useFetchPartnerQuery } from "../../query-hooks/Partners/useFetchPartnerQuery";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";

const PartnerTab = () => {
  const { ref, inView } = useInView({ threshold: 0 });
  const { data, isFetched, hasNextPage, fetchNextPage } =
    useFetchPartnerQuery();

  const navigate = useNavigate();

  const getColumns = [
    {
      Header: "Partner ID",
      accessor: "code",
      Cell: ({ value }) => {
        return (
          <Typography
            sx={{ color: "#2773FF", fontWeight: "700", fontSize: 14 }}
          >
            {value}
          </Typography>
        );
      },
    },
    {
      Header: "Listed On",
      accessor: "listedAt",
      width: 200,
      Cell: ({ value }) => {
        return value === undefined || value === "--" ? (
          "--"
        ) : (
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: "400",
              lineHeight: "20px",
            }}
          >
            <Box>{moment(value).format("DD MMMM YYYY")}</Box>
            <Box>{moment(value).format("HH:mm")}</Box>
          </Typography>
        );
      },
    },
    {
      Header: "RFQs Received",
      accessor: "rfqReceived",
      width: 200,
    },
    {
      Header: "RFQs Responded",
      accessor: "rfqResponded",
      width: 200,
    },
    {
      Header: "Orders Received",
      accessor: "orderReceived",
      width: 200,
    },
    {
      Header: "Total Order Value",
      accessor: "totalOrderAmount",
      width: 250,
    },
    {
      Header: "Total Commission Value",
      accessor: "totalOrderCommissionAmount",
      width: 250,
    },
  ];

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  return (
    <Box>
      <Grid container gap={4} justifyContent={"start"}>
        <Grid item>
          <Button
            variant="contained"
            sx={{ height: "45px" }}
            onClick={() => {
              navigate(appRoutes.partner.addPartner);
            }}
          >
            <AddOutlinedIcon /> {partner_table_labels.addPartner}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" sx={{ height: "45px", width: "120px" }}>
            {inquiry_table_labels.download}
          </Button>
        </Grid>
      </Grid>
      <CustomTable
        searchPlaceholderText={partner_table_labels.searchPartnerText}
        columns={[
          ...getColumns,
          {
            Header: "Average Commission",
            accessor: "averageCommission",
            width: 250,
            Cell: ({ value, cell }) => {
              return cell.row.original.totalOrderAmount === undefined ||
                cell.row.original.totalOrderAmount === 0
                ? "0%"
                : `${
                    (cell.row.original.totalOrderCommissionAmount /
                      cell.row.original.totalOrderAmount) *
                    100
                  }%`;
            },
          },
          {
            Header: "Status",
            accessor: "status.name",
            Cell: ({ value, cell }) => {
              return (
                <StyledChip
                  label={value === "Inactive" ? "DeListed" : value}
                  onClick={() => {
                    navigate(
                      `/partner/editPartnerDetails/${cell.row.original._id}`
                    );
                  }}
                />
              );
            },
          },
        ]}
        data={isFetched ? data.pages.flatMap((page) => page?.partners) : []}
        isLoading={!isFetched}
      />
      {hasNextPage && (
        <Box
          ref={ref}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default PartnerTab;
